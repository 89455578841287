import {useDispatch, useSelector} from "react-redux";
import {setCarDetailArticles, setDamageLevels} from "../../slices/commonDataSlice.js";
import {fetchCarDetailArticles} from "../../slices/articleListSlice.js";
import {fetchDamageLevels} from "../../slices/damageLevelListSlice.js";
import {useEffect} from "react";

const useFetchDamageLevelsAndCarDetails  = (
    {
        dependence = null
    }
) => {
    const dispatch = useDispatch();
    const {damageLevels, carDetailArticles} = useSelector((state) => state.commonData);

    useEffect(() => {
        if (!damageLevels.length) {
            dispatch(fetchDamageLevels()).then((result) => {
                dispatch(setDamageLevels(result.payload.data));
            });
        }
        if (!carDetailArticles.length) {
            dispatch(fetchCarDetailArticles()).then((result) => {
                dispatch(setCarDetailArticles(result.payload.data));
            });
        }
    }, [dispatch, dependence]);
};

export default useFetchDamageLevelsAndCarDetails;