import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SelectInput from "../../../../components/Elements/SelectInput.js";
import {Button} from "react-bootstrap";
import {
    addComment,
    changeAttribute, deleteComment,
    getActiveLines,
    getActiveOrders,
    updateRepairJobLines as updateRepairJobLinesReducer, updateRepairJobOrder as updateRepairJobOrderReducer
} from "../../../../slices/repairJobSlice.js";
import BorderedPageSection from "../../../../components/BorderedPageSection.js";
import ButtonCreate from "../../../../components/Elements/ButtonCreate.js";
import ButtonRemove from "../../../../components/Elements/ButtonRemove.js";
import RepairJobLineEditRow from "./Edit/RepairJobLineEditRow.js";
import RepairJobLineAddModal from "./Edit/RepairJobLineAddModal.js";
import RepairJobOrderDeleteModal from "./Edit/RepairJobOrderDeleteModal.js";
import React, { useState} from "react";
import {useDispatch} from "react-redux";
import {v4 as uuidv4} from "uuid";
import CommentModal from "../../../../components/CommentModal.js";
import RepairJobEditEmployeeOccupations from "./Edit/RepairJobEditEmployeeOccupations.js";
import RepairJobTotalView from "./View/RepairJobTotalView.js";

const RepairJobForm = (
    {
        repairJob,
        employeeOccupations,
        repairJobStatuses
    }) => {
    const dispatch = useDispatch();
    const [orderToAddRepairJobLine, setOrderToAddRepairJobLine] = useState(null);
    const [orderToDelete, setOrderToDelete] = useState(null);
    const [showAddRepairJobLineModal, setShowAddRepairJobLineModal] = useState(false);
    const [showRepairJobOrderDeleteModal, setShowRepairJobOrderDeleteModal] = useState(false);
    const [showCommentModal, setShowCommentModal] = useState(false);
    const [commentModalData, setCommentModalData] = useState({});

    const addRepairJobLine = (repairJobOrder, repairJobLine) => {
        dispatch(updateRepairJobLinesReducer(
            {
                repairJobOrderId: repairJobOrder.id,
                repairJobLineToUpdate: repairJobLine
            }
        ));
        setShowAddRepairJobLineModal(false);
    };

    const updateRepairJobLine = (repairJobOrder, repairJobLine) => {
        dispatch(updateRepairJobLinesReducer(
            {repairJobOrderId: repairJobOrder.id, repairJobLineToUpdate: repairJobLine}
        ));
    };

    const updateRepairJobOrders = (repairJobOrder) => {
        dispatch(updateRepairJobOrderReducer({
            repairJobOrder: repairJobOrder
        }));
    }

    const getEmptyRepairJobLine = () => {
        return {
            article: null,
            damageLevel: null,
            id: null,
            repairJobLineCosts: [],
            childLines: [],
            isActive: true,
            orderLineId: null
        };
    }

    const handleAttributeChange = (attribute, value) => {
        dispatch(changeAttribute({attribute, value}));
    };

    const handleRepairJobStatusChange = (name, repairJobStatus) => {
        if (repairJobStatus.settings?.needsComment) {
            setCommentModalData({
                title: 'Сигурен ли си, че искаш да смениш на статус ' + repairJobStatus.name + '?',
                content: '',
                action: 'repair_job_status_change',
                helpText: 'Ако не добавиш коментар промяната няма да бъде отразена',
                onSubmit: (comment) => {
                    handleAttributeChange(name, repairJobStatus);
                    dispatch(addComment({
                        id: uuidv4(),
                        action: 'repair_job_status_change',
                        comment: comment,
                        actionDetail: repairJobStatus.name,
                    }))
                    setShowCommentModal(false);
                }
            })
            setShowCommentModal(true);
        } else {
            handleAttributeChange(name, repairJobStatus);
        }
    }

    const editComment = (existingComment) => {
        setCommentModalData({
            title: 'Промени коментар',
            content: existingComment.comment,
            action: existingComment.action,
            onSubmit: (comment) => {
                dispatch(addComment({
                    id: uuidv4(),
                    action: 'repair_job_status_change',
                    comment: comment,
                    actionDetail: existingComment.actionDetail
                }))
                setShowCommentModal(false);
            }
        })
        setShowCommentModal(true);
    }

    return (
        <>
            <BorderedPageSection>
                <Row>
                    <Col md={3}>
                        <SelectInput
                            name={'repairJobStatus'}
                            label={'Статус'}
                            value={repairJob.repairJobStatus?.id}
                            selectOptions={repairJobStatuses}
                            onChange={handleRepairJobStatusChange}
                        />
                        {repairJob.newComments.map((comment, index) =>
                            comment.action === 'repair_job_status_change' ?
                                <div className={'mt-1 mb-2'} key={index}>
                                    <div><i>{comment.comment}</i></div>
                                    <Button className={'small-button me-1'}
                                            onClick={() => editComment(comment)}>
                                        Редактирай коментар
                                    </Button>
                                    {!repairJob.repairJobStatus?.settings.needsComment && (
                                        <Button className={'small-button btn-danger'}
                                                onClick={() => dispatch(deleteComment(comment))}>Изтрий
                                            коментар</Button>
                                    )}
                                </div> :
                                null
                        )}
                    </Col>
                </Row>
            </BorderedPageSection>
            <Row>
                <Col md={12}>
                    <RepairJobTotalView repairJob={repairJob} />
                </Col>
            </Row>
            {getActiveOrders(repairJob.repairJobOrders).map((repairJobOrder) => (
                <BorderedPageSection key={`list-repair-job-order-${repairJobOrder.id}`}>
                    <Row className={'mb-3'}>
                        <Col md={4}>
                            <h4>Поръчка № {repairJobOrder.id}</h4>
                        </Col>
                        <Col md={4}>
                            <ButtonCreate
                                onClick={() => {
                                    setShowAddRepairJobLineModal(true);
                                    setOrderToAddRepairJobLine(repairJobOrder);
                                }}
                                permission={'update-repair-jobs'}
                            >
                                Добави позиция
                            </ButtonCreate>
                        </Col>
                        <Col md={4}>
                            <div className={'float-end'}>
                                <ButtonRemove
                                    permission={'delete-repair-jobs'}
                                    onClick={() => {
                                        setShowRepairJobOrderDeleteModal(true);
                                        setOrderToDelete(repairJobOrder);
                                    }}
                                >
                                    Премахни поръчка
                                </ButtonRemove>
                            </div>
                        </Col>
                    </Row>
                    {getActiveLines(repairJobOrder.repairJobLines).map((repairJobLine) => (
                        <div key={`repair-job-line-row-${repairJobLine.id}`}>
                            <RepairJobLineEditRow
                                repairJobLine={repairJobLine}
                                repairJobOrder={repairJobOrder}
                                emptyRepairJobLine={getEmptyRepairJobLine()}
                                onUpdate={updateRepairJobLine}
                            />
                        </div>
                    ))}

                </BorderedPageSection>
            ))}
            {showAddRepairJobLineModal &&
                <RepairJobLineAddModal
                    show={showAddRepairJobLineModal}
                    onClose={() => setShowAddRepairJobLineModal(false)}
                    repairJobOrder={orderToAddRepairJobLine}
                    emptyRepairJobLine={getEmptyRepairJobLine()}
                    onSubmit={addRepairJobLine}
                />
            }
            {showRepairJobOrderDeleteModal &&
                <RepairJobOrderDeleteModal
                    onSubmit={(repairJobOrder) => updateRepairJobOrders(repairJobOrder)}
                    onClose={() => {
                        setShowRepairJobOrderDeleteModal(false);
                        setOrderToDelete(null);
                    }}
                    repairJobOrderToDelete={orderToDelete}
                    show={showRepairJobOrderDeleteModal}
                />
            }
            {showCommentModal && (
                <CommentModal
                    show={showCommentModal}
                    onClose={() => setShowCommentModal(false)}
                    data={commentModalData}
                />
            )}
            <BorderedPageSection>
                <Row>
                    <Col md={12}>
                        <RepairJobEditEmployeeOccupations
                            repairJob={repairJob}
                            onUpdate={handleAttributeChange}
                            employeeOccupations={employeeOccupations}
                        />
                    </Col>
                </Row>
            </BorderedPageSection>
        </>
    )
}

export default RepairJobForm;