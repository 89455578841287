import Modal from "react-bootstrap/Modal";
import React, {useEffect, useState} from "react";
import parse from "html-react-parser";
import {numberFormatSpaceThousands, toFloatBgn} from "../../../../utils/functions.js";
import apiClient from "../../../../axios.js";
import CommentsTable from "../../../../components/CommentsTable.js";
import LoadingComponent from "../../../../components/LoadingComponent.js";
import {formatDateToBeautifulString} from "../../../../utils/dateFunctions.js";

const OrderDetailsTableModal = (
    {
        show,
        onClose,
        order
    }
) => {
    const [comments, setComments] = useState([]);
    const [commentsFetched, setCommentsFetched] = useState(false);
    const endPoint = `/orders/${order.id}/comments?perPage=10`;

    const fetchComments = async () => {
        const commentsResponse = await apiClient.get(endPoint);
        setComments(commentsResponse.data.data);
        setCommentsFetched(true);
    }

    useEffect(() => {
        if (order.id) {
            fetchComments();
        }
    }, [order.id]);

    return (
        <Modal show={show} onHide={onClose} animation={false} size={'xl'}>
            <Modal.Header closeButton>
                <Modal.Title>Поръчка № {order.id} - {order.client.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className={'mb-3'}>
                    {order.insurer ? (
                        <>
                            <h5><b>Застраховател:</b> {order.insurer?.name}</h5>
                            <h5><b>Номер щета:</b> {order.insurerClaimId ? order.insurerClaimId : 'частен ремонт'}</h5>
                        </>
                    ) : (
                        <h5>Частен ремонт</h5>
                    )}
                </div>
                <div className={'mb-3'}>
                    <h5><b>Клиент фактура:</b> {order.invoiceClient?.name}</h5>
                    <h5><b>Номер фактура:</b> {order.invoiceNumber}</h5>
                </div>
                {order.subcontractor &&
                    <div className={'mb-3'}>
                        <h5><b>Подизпълнител: </b>{order.subcontractor.name}</h5>
                        <h6>С фактура: {order.subcontractorOrder.hasInvoice ? 'Да' : 'Не'}</h6>
                        <h6>За заплащане: {order.subcontractorOrder.invoiceAmount ? toFloatBgn(order.subcontractorOrder.invoiceAmount) : ''}</h6>
                        <h6>Номер фактура: {order.subcontractorOrder.invoiceNumber}</h6>
                        <h6>Дата
                            фактура: {order.subcontractorOrder.invoiceDate ? formatDateToBeautifulString(order.subcontractorOrder.invoiceDate) : ''}</h6>
                        <h6>Платена: {order.subcontractorOrder.isPaid ? 'Да' : 'Не'}</h6>
                    </div>
                }
                {order.mileage &&
                    <div className={'mb-3'}>
                        <h5 ><b>Пробег:</b> {numberFormatSpaceThousands(order.mileage)} км</h5>
                    </div>
                }
                {order.additionalInformation &&
                    <div className={'mb-3'}>
                        <h5><b>Бележка:</b> {parse(order.additionalInformation)}</h5>
                    </div>
                }

                <div>
                    <b>Коментари (последни 10):</b>
                    {commentsFetched ?
                        <CommentsTable data={comments}/>
                        :
                        <LoadingComponent/>
                    }
                </div>
            </Modal.Body>
            <Modal.Footer>
            </Modal.Footer>
        </Modal>
    )
};

export default OrderDetailsTableModal;