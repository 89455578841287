import {toFloatBgn} from "../../../../../utils/functions.js";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";


const RepairJobCostView = (
    {
        article,
        cost
    } ) => {

    return (
        <Row>
            <Col md={2}>
                Количество: {cost.quantity}{article.quantityType.abbreviation}
            </Col>
            <Col md={2}>
                Стойност: {toFloatBgn(cost.price)}
            </Col>

            <Col md={2}>
                Общо: {toFloatBgn(cost.price * cost.quantity)}
            </Col>
            <Col md={3}>
                Служител: {!cost.employee?.name && article.settings.defaultRepairJobEmployee ? 'Ще бъде поставен автоматично' : cost.employee?.name}
            </Col>
            <Col md={3}>
                Бележка: {cost.note}
            </Col>
        </Row>
    )
}

export default RepairJobCostView;