import { createSlice} from '@reduxjs/toolkit';
import {fetchItem, saveItem} from "../utils/sliceFunctions.js";
import {changeAttributeReducer, resetStateDataReducer, setStatusReducer} from "../utils/commonReducers.js";
import {getCommonExtraReducers} from "../utils/commonExtraReducers.js";

const initialState = {
    data: {
        id: null,
        name: '',
        email: '',
        identificationId: '',
        address: '',
        city: '',
        zip: '',
        country: 'България',
        phoneNumber: '',
        molField: '',
        additionalInformation: '',
        vatNumber: '',
        bic: '',
        iban: '',
        bankName: '',
        isSubcontractor: true
    },
    loading: false,
    errors: [],
    status: 'idle'
};

export const fetchPartner = fetchItem('partner/fetchPartner', '/partners')
export const savePartner = saveItem('partner/savePartner', '/partners');

const partnerItem = createSlice({
    name: 'partnerItem',
    initialState: initialState,
    reducers: {
        changeAttribute: changeAttributeReducer,
        resetStateData: resetStateDataReducer(initialState),
        setStatus: setStatusReducer
    },
    extraReducers: (builder) => {
        getCommonExtraReducers(builder, fetchPartner, savePartner)
    },
});

export default partnerItem.reducer;
export const {
    changeAttribute,
    resetStateData,
    setStatus
} = partnerItem.actions;