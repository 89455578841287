
export const changeAttributeReducer = (state, action) => {
    state.data[action.payload.attribute] = action.payload.value;
}

export const resetStateDataReducer = (initialState) => (state) => {
    return initialState;
};

export const setDataReducer = (state, action) => {
    state.data = action.payload;
}

export const setCurrentPageReducer = (state, action) => {
    state.pagination.currentPage = action.payload;
}

export const setStatusReducer = (state, action) => {
    state.status = action.payload;
}

export const setErrorsReducer = (state, action) => {
    state.errors = action.payload;
}