

const RoundedInfo = (
    {
        className = '',
        children
    }) => {
    return (
        <div className={`fs-5 bg-info bg-opacity-25 p-3 border border-1 rounded-2 ${className}`}>
            {children}
        </div>

    );
};

export default RoundedInfo;