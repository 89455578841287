import Modal from "react-bootstrap/Modal";
import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {
    addComment, changeAttribute,
    fetchRepairJob,
    hasMissingEmployee,
    resetStateData,
    saveRepairJob,
    setErrors as setErrorsReducer
} from "../../../../slices/repairJobSlice.js";
import LoadingComponent from "../../../../components/LoadingComponent.js";
import ErrorAlertComponent from "../../../../components/ErrorAlertComponent.js";
import FormInput from "../../../../components/Elements/FormInput.js";
import {v4 as uuidv4} from "uuid";
import {isEmptyObject, processSubmit} from "../../../../utils/functions.js";
import {getRepairJobSchema} from "../../../../utils/schemas.js";
import {setSuccessMessage} from "../../../../slices/informationMessagesSlice.js";
import {setStatus} from "../../../../slices/orderSlice.js";
import Button from "react-bootstrap/Button";
import {Link} from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const RepairJobStatusTableModal = (
    {
        show,
        onClose,
        newStatus,
        editRepairJob,
        onRepairJobSave
    }
) => {
    const dispatch = useDispatch();

    const {data: repairJob, loading: repairJobLoading, errors: stateErrors, status} = useSelector((state) => state.repairJob);
    const [errors, setErrors] = useState({});
    const [comment, setComment] = useState('');
    const [canSubmit, setCanSubmit] = useState(true);

    useEffect(() => {
        dispatch(fetchRepairJob({id: editRepairJob.id}));

        return () => {
            dispatch(resetStateData());
        }
    }, []);

    useEffect(() => {
        if (!repairJob.id) {
            return;
        }
        if (newStatus.settings.needsEmployee && hasMissingEmployee(repairJob)) {
            setCanSubmit(false);
            dispatch(setErrorsReducer(['За да запазиш този статус е нужно да попълниш навсякъде служител']));
        } else  {
            dispatch(changeAttribute({attribute: 'repairJobStatus', value: newStatus}));
        }
    }, [repairJob.id]);

    useEffect(() => {
        if (status === 'saved') {
            dispatch(setSuccessMessage(`Успешно запазена сервизна заявка - ${repairJob.id}`));
            dispatch(setStatus('idle'));
            onRepairJobSave();
            onClose();
        }
    }, [status]);

    useEffect(() => {
        if (comment && repairJob.newComments.length === 1) {
             processSubmit(setErrors, getRepairJobSchema(repairJob), saveRepairJob, dispatch, repairJob);
        }
    }, [repairJob.newComments]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (newStatus.settings.needsComment) {
            if (!comment.length) {
                setErrors({
                    repairJobCommentContent: 'Добави коментар!'
                })
            } else if (comment) {
                dispatch(addComment({
                    id: uuidv4(),
                    action: 'repair_job_status_change',
                    comment: comment,
                    actionDetail: newStatus.name,
                }));
            }
        } else  {
            await processSubmit(setErrors, getRepairJobSchema(repairJob), saveRepairJob, dispatch, repairJob);
        }
    }

    return (
        <Modal show={show} onHide={onClose} animation={false} size={"xl"}>
            <Modal.Header closeButton>
                <Modal.Title>Смяна на статус - {newStatus.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {repairJob.id ? (
                    <>
                        <ErrorAlertComponent error={stateErrors}/>

                        <Row>
                            <Col md={12}>
                                <h5 className={'mb-3'}>Заявка №: {repairJob.id}</h5>
                                <h5 className={'mb-3'}>Клиент: {repairJob.client.name}</h5>
                            </Col>
                            <Col md={12}>
                                {newStatus.settings.needsComment &&
                            <FormInput
                                name={'repairJobCommentContent'}
                                onChange={(name, value) => {
                                    setComment(value)
                                }}
                                value={comment}
                                as={'textarea'}
                                label={'Добави коментар'}
                                errors={errors}
                            />
                        }
                        {(!isEmptyObject(errors) || stateErrors.length > 0) &&
                            <p className={'small mt-3'}>
                                <span className={'text-danger'}><b>Има грешка. Ако не може да бъде поправена</b> - </span>
                                <Link to={`/repair-jobs/${repairJob.id}/edit`} target={"_blank"}>провери заявка</Link>.
                            </p>
                        }
                            </Col>
                        </Row>
                    </>
                ) : (
                    <LoadingComponent className={'position-relative'}/>
                )}
            </Modal.Body>
            <Modal.Footer>
                {(repairJob.id && !repairJobLoading) &&
                    <>
                        <Button variant="danger" onClick={onClose}>Откажи</Button>
                        <Button variant="success" disabled={!canSubmit} onClick={handleSubmit}>Запази</Button>
                    </>
                }
            </Modal.Footer>
        </Modal>
    );
};

export default RepairJobStatusTableModal;