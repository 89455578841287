import React, {useEffect, useState} from 'react';
import { useSelector} from 'react-redux';
import {Link, useParams} from "react-router-dom";
import {numberFormatSpaceThousands, toFloatBgn} from "../../../utils/functions.js";
import OrderNav from "./components/OrderNav.js";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {formatDateToBeautifulString} from "../../../utils/dateFunctions.js";
import OrderLinesTableComponent from "./components/OrderLinesTableComponent.js";
import SingleViewLayout from "../../../components/Layouts/SingleViewLayout.js";
import ViewField from "../../../components/Elements/ViewField.js";
import DocumentPdf from "../Invoice/DocumentPdf.js";
import HelpText from "../../../components/Elements/HelpText.js";
import CommentsComponent from "../../../components/CommentsComponent.js";
import StatusViewSection from "../../../components/StatusViewSection.js";
import BorderedPageSection from "../../../components/BorderedPageSection.js";

const OrderViewPage = () => {
    const {id} = useParams();
    const {data: order, loading} = useSelector((state) => state.order);
    const [title, setTitle] = useState('');

    useEffect(() => {
        setTitle(`Поръчка № ${id}`);
    }, [order.id]);

    const showPage = () => {
        return !loading;
    }

    return (
        <SingleViewLayout
            title={title}
            showPage={showPage()}
            navComponent={OrderNav}
            navProps={{id: id, activeKey: 'view'}}
        >
            <Row>
                <Col md={3}>
                    <StatusViewSection value={order.orderStatus?.name} />
                </Col>
                <Col md={3}>
                    <div className={'single-view-section-wrapper'}>
                        <ViewField label={'Създадена'} value={formatDateToBeautifulString(order.createdAt)}/>
                    </div>
                </Col>
            </Row>
            <Row>

            </Row>
            <Row>
                <Col md={3}>
                    <div className={'single-view-section-wrapper'}>
                        {order.repairJobId ? (
                            <ViewField label={'Сервизна заявка'}
                                       value={<Link to={`/repair-jobs/${order.repairJobId}`} target={'_blank'}>Виж</Link>}
                            />) : (
                            <ViewField label={'Сервизна заявка'}
                                       value={'Няма'} />
                        )
                        }
                        <ViewField label={'Дата приемане'}
                                   value={order.startDate ? formatDateToBeautifulString(order.startDate) : ''}/>
                        <ViewField label={'Дата издаване'}
                                   value={order.endDate ? formatDateToBeautifulString(order.endDate) : ''}/>

                        <ViewField label={'Клиент'} value={<Link to={`/clients/${order.client?.id}`}
                                                                 target={'_blank'}>{order.client?.name}</Link>}/>
                        <ViewField label={'Кола'} value={<Link to={`/cars/${order.car?.id}`}
                                                               target={'_blank'}>{order.car?.searchLabel}</Link>}/>
                        <ViewField label={'Пробег'} value={order.mileage ? `${numberFormatSpaceThousands(order.mileage)} км` : ''}/>
                        <ViewField label={'ЕИК/ЕГН'} value={order.client?.identificationId}/>
                        <ViewField label={'ДДС номер'} value={order.client?.vatNumber}/>
                        <ViewField label={'МОЛ'} value={order.client?.molField}/>
                        <ViewField label={'Телефон'} value={order.client?.phoneNumber}/>
                        <ViewField label={'Email'} value={order.client?.email}/>
                    </div>
                </Col>
                <Col md={3}>
                    <div className={'single-view-section-wrapper'}>
                        <ViewField label={'Изпълнител'} value={order.contractor?.name}/>
                        <ViewField label={'Застраховател'} value={<Link to={`/clients/${order.insurer?.clientId}`}
                                                                        target={'_blank'}>{order.insurer?.name}</Link>}/>
                        <ViewField label={'Номер щета'} value={order.insurerClaimId}/>
                    </div>

                    <div className={'single-view-section-wrapper'}>
                        <ViewField label={'Подизпълнител'} value={order.subcontractor?.name ?? 'Няма'}/>
                        {order.subcontractor &&
                            <>
                                <ViewField label={'За заплащане'} value={order.subcontractorOrder.invoiceAmount ? toFloatBgn(order.subcontractorOrder.invoiceAmount) : ''}/>
                                <ViewField label={'С фактура'} value={order.subcontractorOrder.hasInvoice ? 'Да' : 'Не'}/>
                                <ViewField label={'Номер фактура'} value={order.subcontractorOrder.invoiceNumber}/>
                                <ViewField label={'Дата фактура'} value={order.subcontractorOrder.invoiceDate ? formatDateToBeautifulString(order.subcontractorOrder.invoiceDate) : ''}/>
                                <ViewField label={'Платена'} value={order.subcontractorOrder.isPaid ? 'Да' : 'Не'}/>
                            </>
                        }
                    </div>
                </Col>
                <Col md={3}>
                    <div className={'single-view-section-wrapper'}>
                        <ViewField label={'С ФАКТУРА'}
                                   value={<b className={'text-uppercase'}>{order.hasInvoice ? 'ДА' : 'НЕ'}</b>}/>
                        <ViewField label={'Дата фактура'}
                                   value={order.invoiceDate ? formatDateToBeautifulString(order.invoiceDate) : ''}/>
                        <ViewField label={'Номер фактура'} value={order.invoiceNumber}/>
                        <ViewField label={'Дата падеж'} value={order.paymentDueDate}/>
                        <ViewField label={'Клиент фактура'} value={<Link to={`/clients/${order.invoiceClient?.id}`}
                                                                         target={'_blank'}>{order.invoiceClient?.name}</Link>}/>
                        <ViewField label={'Начин плащане'} value={order.paymentType?.name}/>
                    </div>
                </Col>
                <Col md={3}>
                    <div className={'single-view-section-wrapper'}>
                        <ViewField label={'Бележка'} value={order.additionalInformation} parseHtml={true}/>
                    </div>
                </Col>
            </Row>
            <Row>
                <div className={'d-flex'}>
                        <DocumentPdf type={'proforma'} id={id} details={true}/>
                        <DocumentPdf type={'proforma'} id={id} details={false}/>
                {order.invoiceNumber &&
                        <DocumentPdf
                            type={'invoice'}
                            id={id}
                            details={false}
                            helpText={<span>При фактурирана поръчка, за да се генерира нова<br/> фактура е нужно да се изтрие старата от "Файлове"</span>}
                        />
                }
                    </div>

            </Row>
            <Row className={'mt-3'}>
                <h5>Позиции</h5>
                <OrderLinesTableComponent lines={order.orderLines} showingActiveLines={true} viewOnly={true}/>

                <div>
                    {order.hasInvoice &&
                        <>
                            <div><b>Данъчна основа:</b> {toFloatBgn(order.totalWithoutVat)}</div>
                            <div><b>ДДС:</b> {toFloatBgn(order.vatOfTotal)}</div>
                        </>
                    }
                    <div><b>Сума за плащане:</b> {toFloatBgn(order.total)}</div>
                    {!order.hasInvoice &&
                        <HelpText>Поръчка без фактура</HelpText>
                    }
                </div>
            </Row>
            <Row className={'mt-5'}>
                <Col md={12}>
                    <BorderedPageSection>
                        <CommentsComponent item={order} apiPath={'orders'} />
                    </BorderedPageSection>
                </Col>
            </Row>
        </SingleViewLayout>
    );
};

export default OrderViewPage;
