import {Outlet, useLocation, useNavigate, useParams} from 'react-router-dom';
import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {fetchPartner, resetStateData} from '../../slices/partnerSlice.js';
import {processIdInformation} from "../../utils/functions.js";

const SinglePartnerWrapper = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const {id} = useParams();
    const {idIsInteger} = processIdInformation(id);
    const {data: partner, loading, status} = useSelector((state) => state.partner);

    useEffect(() => {
        if (!idIsInteger && id !== 'create') {
            navigate('/partners');
        }
        if (idIsInteger) {
            dispatch(fetchPartner({id: id}));
        }

        return () => {
            dispatch(resetStateData());
        }
    }, [location]);

    useEffect(() => {
        if (status === 'fetched' && !partner.id) {
            navigate('/partners');
        }
    }, [loading]);

    return <Outlet/>;
};

export default SinglePartnerWrapper;