import {Button} from "react-bootstrap";
import {useSelector} from "react-redux";
import {hasPermission} from "../../utils/permissions.js";

const ButtonCreate = ({ children, onClick, permission = null, isSmall = true, ...props }) => {

    const {user} = useSelector((state) => state.auth);
    if (!permission || hasPermission(user, permission)) {
        return <Button className={`button-create me-2 ms-2 ${isSmall ? 'small-button' : ''} `} onClick={onClick} {...props}>{children}</Button>
    } return <></>;
};

export default ButtonCreate;