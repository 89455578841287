import {useParams} from "react-router-dom";
import { useSelector} from "react-redux";
import {useEffect, useState} from "react";
import LogsPageLayout from "../../../components/Layouts/LogsPageLayout.js";
import PartnerNav from "./components/PartnerNav.js";

const PartnerLogsPage = () => {
    const {id} = useParams();
    const {data: partner, loading} = useSelector((state) => state.partner);

    const [title, setTitle] = useState('');

    useEffect(() => {
        setTitle(`Партньор № ${id} - ${partner.name}`);
    }, [partner.id]);

    const showPage = () => {
        return !loading;
    }

    return (
        <LogsPageLayout
            id={id}
            title={title}
            items={'partners'}
            navComponent={PartnerNav}
            navProps={{id: id, activeKey: 'logs'}}
            showPage={showPage()}
        />
    )
}

export default PartnerLogsPage;