import {createSlice} from '@reduxjs/toolkit';
import {fetchItem, saveItem} from "../utils/sliceFunctions.js";
import {
    changeAttributeReducer,
    resetStateDataReducer,
    setErrorsReducer,
    setStatusReducer
} from "../utils/commonReducers.js";
import {getCommonExtraReducers, getSaveCommonExtraReducer} from "../utils/commonExtraReducers.js";
import {hasPermission} from "../utils/permissions.js";

const initialState = {
    data: {
        id: null,
        repairJobStatus: {},
        repairJobOrders: [],
        car: null,
        addedOrderIds: [],
        createdAt: null,
        updatedAt: null,
        mileage: null,
        startDate: '',
        endDate: '',
        newComments: [],
        repairJobEmployeeOccupations: [],
        mustUpdateOrderStatus: false,
        isEditLocked: false
    },
    loading: false,
    errors: [],
    status: 'idle'
};

export const fetchRepairJob = fetchItem('repairJob/fetchRepairJob', '/repair-jobs')
export const saveRepairJob = saveItem('repairJob/saveRepairJob', '/repair-jobs');
export const addOrdersRepairJob = saveItem('repairJob/addOrdersRepairJob', '/repair-jobs/add-orders');

const repairJobItem = createSlice({
    name: 'repairJobItem',
    initialState: initialState,
    reducers: {
        changeAttribute: changeAttributeReducer,
        resetStateData: resetStateDataReducer(initialState),
        setStatus: setStatusReducer,
        setErrors: setErrorsReducer,
        updateRepairJobLines: (state, action) => {
            const {repairJobOrderId, repairJobLineToUpdate} = action.payload;
            state.data.repairJobOrders = state.data.repairJobOrders.map(order => {
                if (order.id === repairJobOrderId) {
                    const updatedLines = order.repairJobLines ? [...order.repairJobLines] : [];
                    const existingIndex = updatedLines.findIndex(line => line.id === repairJobLineToUpdate.id);
                    if (existingIndex !== -1) {
                        updatedLines[existingIndex] = {...updatedLines[existingIndex], ...repairJobLineToUpdate};
                    } else {
                        updatedLines.push(repairJobLineToUpdate);
                    }
                    return {...order, repairJobLines: updatedLines};
                }
                return order;
            });
        },
        updateRepairJobOrder: (state, action) => {
            const {repairJobOrder} = action.payload;
            state.data.repairJobOrders = state.data.repairJobOrders.map(order => {
                if (order.id === repairJobOrder.id) {
                    return repairJobOrder;
                }
                return order;
            });
        },
        deleteComment: (state, action) => {
            state.data.newComments = state.data.newComments.filter(
                (comment) => comment.action !== action.payload.action
            );
        },
        addComment: (state, action) => {
            const existingIndex = state.data.newComments.findIndex(
                (comment) => comment.action === action.payload.action
            );

            if (existingIndex !== -1) {
                state.data.newComments[existingIndex] = action.payload;
            } else {
                state.data.newComments.push(action.payload);
            }
        }
    },
    extraReducers: (builder) => {
        getCommonExtraReducers(builder, fetchRepairJob, saveRepairJob);
        getSaveCommonExtraReducer(builder, addOrdersRepairJob);
    }
});

export const {
    changeAttribute,
    resetStateData,
    setStatus,
    updateRepairJobLines,
    updateRepairJobOrder,
    deleteComment,
    addComment,
    setErrors
} = repairJobItem.actions;

export const getActiveOrders = (orders) => {
    return orders.filter(order => order.isActive);
}

export const getActiveCosts = (costs) => {
    return costs.filter(cost => cost.isActive);
}

export const getActiveLines = (lines) => {
    return lines.filter(line => line.isActive);
}

export const getDefaultEmployeeForArticle = (article, employeeOccupations, repairJob) => {
    const occupation = employeeOccupations.find(occupation =>
        occupation.articles.some(occArticle => occArticle.id === article.id)
    );
    if (!occupation) {
        return  null;
    }
    const repairJobOccupationSetting = repairJob.repairJobEmployeeOccupations.find(repairJobEmployeeOccupation =>
        occupation.id === repairJobEmployeeOccupation.employeeOccupationId);

    if (!repairJobOccupationSetting || !repairJobOccupationSetting.employee) {
        return null;
    }
    return occupation.employees.find(emp => emp.id === repairJobOccupationSetting.employee.id);
};

export const hasMissingEmployee = (repairJob) => {
    let has = false;
    repairJob.repairJobOrders.forEach(order => {
        order.repairJobLines.forEach(line => {
            line.childLines.forEach(childLine => {
                childLine.repairJobLineCosts.forEach(cost => {
                    if (!cost.employee && !childLine.article.settings?.defaultRepairJobEmployee) {
                        has = true;
                    }
                })
            })
        })
    })
    return has;
}

export const isRepairJobEditLock = (repairJob, user) => {
    return repairJob.isEditLocked && !hasPermission(user, 'overwrite-locks');
}

export const getRepairJobLineTotal = (repairJobLine) => {
    let total = 0;
    repairJobLine.childLines.forEach(childLine => {
        childLine.repairJobLineCosts.forEach(cost => {
            total += cost.quantity * cost.price;
        })
    });

    return total;
}

export const getRepairJobTotal = (repairJob) => {
    let total = 0;
    repairJob.repairJobOrders.forEach(order => {
        order.repairJobLines.forEach(line => {
            total += getRepairJobLineTotal(line);
        })
    });

    return total;
}

export default repairJobItem.reducer;

