import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
    saveRepairJob,
    setStatus,
    setErrors as setErrorsReducer, hasMissingEmployee, isRepairJobEditLock
} from "../../../slices/repairJobSlice.js";
import {useParams, useNavigate} from "react-router-dom";
import {processSubmit} from "../../../utils/functions.js";
import {getRepairJobSchema} from "../../../utils/schemas.js";
import RepairJobNav from "./components/RepairJobNav.js";
import SingleEditLayout from "../../../components/Layouts/SingleEditLayout.js";
import {setSuccessMessage} from "../../../slices/informationMessagesSlice.js";
import apiClient from "../../../axios.js";
import {setRepairJobStatuses} from "../../../slices/commonDataSlice.js";
import RepairJobEditConfirmModal from "./components/Edit/RepairJobEditConfirmModal.js";
import RepairJobOrdersComparedErrors from "./components/View/RepairJobOrdersComparedErrors.js";
import RepairJobForm from "./components/RepairJobForm.js";
import {fetchEmployeeOccupations} from "../../../slices/employeeOccupationListSlice.js";
import useFetchRepairJobStatuses from "../../../app/hooks/useFetchRepairJobStatuses.js";
import RepairJobLockNote from "./components/Edit/RepairJobLockNote.js";

const RepairJobEditPage = () => {
    const {id} = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useFetchRepairJobStatuses();
    const {data: repairJob, loading, errors: stateErrors, status} = useSelector((state) => state.repairJob);
    const {repairJobStatuses} = useSelector((state) => state.commonData);
    const {user} = useSelector((state) => state.auth);

    const [title, setTitle] = useState('');
    const [canSubmit, setCanSubmit] = useState(true);

    const [showRepairJobSummaryConfirmModal, setShowRepairJobSummaryConfirmModal] = useState(false);

    const {data: employeeOccupations} = useSelector((state) => state.employeeOccupationList);

    useEffect(() => {
        if (id === 'create') {
            navigate('/repair-jobs/create/orders');
        }
        if (!employeeOccupations.length) {
            dispatch(fetchEmployeeOccupations());
        }
    }, []);

    useEffect(() => {
        setCanSubmit(true);
        if (repairJob.repairJobStatus?.settings?.needsEmployee && hasMissingEmployee(repairJob)) {
            setCanSubmit(false);
            dispatch(setErrorsReducer(['За да запазиш този статус е нужно да попълниш навсякъде служител']))
        }
    }, [repairJob.repairJobStatus, repairJob]);

    useEffect(() => {
        const fetchRepairJobStatuses = async () => {
            if (!repairJobStatuses.length) {
                const repairJobStatusesResponse = await apiClient.get(
                    '/repair-job-statuses'
                );
                dispatch(setRepairJobStatuses(repairJobStatusesResponse.data.data));
            }
        }

        fetchRepairJobStatuses();
    }, []);

    useEffect(() => {
        setTitle(`Сервизна заявка № ${id}`);
    }, [id]);

    useEffect(() => {
        if (status === 'saved') {
            dispatch(setSuccessMessage(`Успешно запазена сервизна заявка - ${repairJob.id}`));
            dispatch(setStatus('idle'));
            navigate(`/repair-jobs/${repairJob.id}`);
        }
    }, [status]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        await processSubmit(null, getRepairJobSchema(repairJob), saveRepairJob, dispatch, repairJob);
    };

    const showForm = () => {
        return !loading && repairJobStatuses.length > 0 &&
            ((id === 'create' && !repairJob.id) || (parseInt(id) === repairJob.id)) && employeeOccupations.length > 0;
    }

    return (
        <SingleEditLayout
            title={title}
            showForm={showForm()}
            onSubmit={() => setShowRepairJobSummaryConfirmModal(true)}
            stateErrors={stateErrors}
            navComponent={RepairJobNav}
            navProps={{id: id, activeKey: 'edit'}}
            canSubmit={canSubmit && !isRepairJobEditLock(repairJob, user)}
        >
            <RepairJobOrdersComparedErrors repairJob={repairJob} />
            <RepairJobLockNote repairJob={repairJob} />
            <RepairJobForm
                repairJob={repairJob}
                repairJobStatuses={repairJobStatuses}
                employeeOccupations={employeeOccupations}
            />
            {showRepairJobSummaryConfirmModal &&
                <RepairJobEditConfirmModal
                    repairJob={repairJob}
                    show={showRepairJobSummaryConfirmModal}
                    onClose={() => setShowRepairJobSummaryConfirmModal(false)}
                    onSubmit={handleSubmit}
                />
            }
        </SingleEditLayout>
    );
};

export default RepairJobEditPage;
