import {Nav} from "react-bootstrap";
import React from "react";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import {hasPermission} from "../../../../utils/permissions.js";

const PartnerNav = (
    {
        id,
        activeKey
    }) => {
    const {user} = useSelector((state) => state.auth);

    return (
        <Nav variant="tabs" activeKey={activeKey} defaultActiveKey="show">
            {id !== 'create' && (
                <Nav.Item>
                    <Nav.Link as={Link} eventKey={'view'} to={`/settings/partners/${id}`}>Преглед</Nav.Link>
                </Nav.Item>
            )}
            <Nav.Item>
                <Nav.Link as={Link} eventKey={'edit'} to={`/settings/partners/${id}/edit`}>Редактирай</Nav.Link>
            </Nav.Item>
            {id !== 'create' && (
                <>
                    <Nav.Item>
                        <Nav.Link as={Link} eventKey={'orders'}
                                  to={`/settings/partners/${id}/orders`}>Поръчки</Nav.Link>
                    </Nav.Item>
                    {(hasPermission(user, 'get-logs')) && (
                        <Nav.Item>
                            <Nav.Link as={Link} eventKey={'logs'} to={`/settings/partners/${id}/logs`}>Логове</Nav.Link>
                        </Nav.Item>
                    )}
                </>
            )}
        </Nav>
    )
};

export default PartnerNav;