import apiClient from "../axios.js";
import React, {useEffect, useState} from "react";
import {Button} from "react-bootstrap";
import CommentModal from "./CommentModal.js";
import CommentsTable from "./CommentsTable.js";

const CommentsComponent = (
    {
        item,
        apiPath,
    }) => {
    const [data, setData] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const endPoint = `/${apiPath}/${item.id}/comments`;
    const [triggerFetch, setTriggerFetch] = useState(false);
    const fetchData = async () => {
        const commentsResponse = await apiClient.get(endPoint);
        setData(commentsResponse.data.data);
    }

    useEffect(() => {
        if (item.id) {
            setTriggerFetch(true);
        }
    }, [item.id]);

    useEffect(() => {
        if (triggerFetch) {
            fetchData();
        }
    }, [triggerFetch]);

    const createComment = async (comment) => {
        await apiClient.post(endPoint, {comment: comment});
        await fetchData();
        setShowModal(false);
    }

    return (
        <>
            <div className={'clearfix mb-2'}>
                <h5 className={'float-start'}>Коментари</h5>
                <Button className={'button-create float-end'} onClick={() => setShowModal(true)}>
                    Добави коментар
                </Button>
            </div>
            <div>
                <CommentsTable data={data} />
            </div>
            {showModal &&
                <CommentModal data={{
                    title: 'Нов коментар',
                    content: '',
                    action: '',
                    onSubmit: (comment) => {
                        createComment(comment);
                    }
                }} onClose={() => {
                    setShowModal(false);
                }
                } show={showModal}/>
            }
        </>
    )
};

export default CommentsComponent;