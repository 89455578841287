import RepairJobCostView from "./RepairJobCostView.js";
import {getActiveCosts} from "../../../../../slices/repairJobSlice.js";

const RepairJobChildLineView = (
    {
        childLine
    } ) => {

    return (
        <div>
            <p><b>{childLine.article.name}</b></p>
            {getActiveCosts(childLine.repairJobLineCosts).map((cost) => (
                <RepairJobCostView article={childLine.article} cost={cost} key={`repair-child-line-view-${cost.id}`}/>
            ))}
        </div>
    )
}

export default RepairJobChildLineView;