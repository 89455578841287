import React from 'react';
import { useSelector } from 'react-redux';
import {fetchEmployees, resetStateData} from '../../../slices/employeeListSlice.js';
import ListHeaderLayout from "../../../components/Layouts/ListHeaderLayout.js";
import EmployeesTable from "./components/EmployeesTable.js";
import ListPageContent from "../../../components/Layouts/ListPageContent.js";

const EmployeeListPage = () => {
    const {data: employees, loading, pagination} = useSelector((state) => state.employeeList);

    return (
        <div>
            <ListHeaderLayout
                title={'Служители'}
                createLink={'/settings/employees/create/edit'}
                createPermission={'create-employees'}
            />
            <ListPageContent
                resetStateData={resetStateData}
                fetchData={fetchEmployees}
                loading={loading}
                TableComponent={EmployeesTable}
                pagination={pagination}
                data={employees}
            />
        </div>
    );
};

export default EmployeeListPage;
