import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from "react-router-dom";
import {processIdInformation} from "../../../utils/functions.js";
import SingleFilesLayout from "../../../components/Layouts/SingleFilesLayout.js";
import RepairJobNav from "./components/RepairJobNav.js";
import {fetchFiles, resetStateData as resetFilesStateData} from '../../../slices/fileListSlice.js';

const RepairJobFilesPage = () => {
    const {id} = useParams();
    const {idIsInteger} = processIdInformation(id);
    const dispatch = useDispatch();

    const {data: repairJob, loading, errors: stateErrors} = useSelector((state) => state.repairJob);
    const {data: files, loading: filesLoading} = useSelector((state) => state.fileList);

    const [title, setTitle] = useState('');

    useEffect(() => {
        return () => {
            dispatch(resetFilesStateData());
        }
    }, [id, dispatch, idIsInteger]);


    useEffect(() => {
        setTitle(`Сервизна заявка № ${id}`);
    }, [repairJob.id]);

    const showPage = () => {
        return !loading && !filesLoading;
    }

    return (
        <SingleFilesLayout
            title={title}
            showPage={showPage()}
            stateErrors={stateErrors}
            navComponent={RepairJobNav}
            navProps={{id: id, activeKey: 'files'}}
            item = {repairJob}
            itemsPath={'repair-jobs'}
            files={files}
            onFilesUpdate={() => dispatch(fetchFiles({id: id, filesItemPath: 'repair-jobs'}))}
        />
    );
};



export default RepairJobFilesPage;
