const BorderedPageSection = (
    {
        children
    }
) => {
    return (
        <div className={'px-3 py-3 border rounded-2 border-dark border-opacity-25 mb-5'}>
            {children}
        </div>
    );
};

export default BorderedPageSection;
