import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useParams} from "react-router-dom";
import Row from "react-bootstrap/Row";
import PartnerNav from "./components/PartnerNav.js";
import SingleViewLayout from "../../../components/Layouts/SingleViewLayout.js";
import Col from "react-bootstrap/Col";
import ViewField from "../../../components/Elements/ViewField.js";

const PartnerViewPage = () => {
    const {id} = useParams();

    const {data: partner, loading} = useSelector((state) => state.partner);

    const [title, setTitle] = useState('');

    useEffect(() => {
        setTitle(`Партньор № ${id} - ${partner.name}`);
    }, [partner.id]);

    const showPage = () => {
        return !loading && partner.id;
    }

    return (
        <SingleViewLayout
            title={title}
            navComponent={PartnerNav}
            navProps={{id: id, activeKey: 'view'}}
            showPage={showPage()}
        >
            <Row>
                <Col md={3}>
                    <div className={'single-view-section-wrapper bg-warning-subtle border-5'}>
                        <h4>
                            <ViewField label={'Подизпълнител'} value={partner.isSubcontractor ? 'Да' : 'Не'}/>
                        </h4>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col md={4}>
                    <Col md={12} className={'single-view-section-wrapper'}>
                        <ViewField label={'Име'} value={partner.name}/>
                        <ViewField label={'ЕИК'} value={partner.identificationId}/>
                        <ViewField label={'ДДС номер'} value={partner.vatNumber}/>
                        <ViewField label={'МОЛ'} value={partner.molField}/>
                    </Col>
                </Col>
                <Col md={4}>
                    <Col md={12} className={'single-view-section-wrapper'}>
                        <ViewField label={'Email'} value={partner.email}/>
                        <ViewField label={'Телефон'} value={partner.phoneNumber}/>
                        <ViewField label={'Адрес'} value={partner.address}/>
                        <ViewField label={'Град'} value={partner.city}/>
                        <ViewField label={'ПК'} value={partner.zip}/>
                        <ViewField label={'Държава'} value={partner.country}/>
                    </Col>
                </Col>
                <Col md={4}>
                    <Col md={12} className={'single-view-section-wrapper'}>
                        <ViewField label={'Банка'} value={partner.bankName}/>
                        <ViewField label={'BIC'} value={partner.bic}/>
                        <ViewField label={'IBAN'} value={partner.iban}/>
                    </Col>
                </Col>
                <Col md={12}>
                    <Col md={12} className={'single-view-section-wrapper'}>
                        <h6>Допълнителна информация</h6>
                        <ViewField value={partner.additionalInformation} parseHtml={true}/>
                    </Col>
                </Col>
            </Row>
        </SingleViewLayout>
    );
};

export default PartnerViewPage;
