import React from "react";
import {useSelector} from "react-redux";
import ButtonRemove from "../../../../../components/Elements/ButtonRemove.js";
import { Button, Table} from "react-bootstrap";
import RepairJobLineEditCostRow from "./RepairJobLineEditCostRow.js";
import {hasPermission} from "../../../../../utils/permissions.js";
import {getActiveCosts, getActiveLines} from "../../../../../slices/repairJobSlice.js";

const RepairJobLineEditChildrenTable = (
    {
        repairJobLine,
        onAddCost,
        onUpdateCost,
        onDelete,
        errors
    }) => {
    const {user} = useSelector((state) => state.auth);

    const canDeleteChildLine = () => {
        return (hasPermission(user, 'delete-repair-jobs') || isNaN(repairJobLine.id)) && repairJobLine.isActive;
    }

    return (
        <Table bordered>
            <thead>
            <tr>
                <th>Позиция</th>
            </tr>
            </thead>
            <tbody>
            {getActiveLines(repairJobLine.childLines).map(childLine =>
                <tr key={`child-line-table-row-${childLine.id}`}>
                    <td>
                        <div className={'clearfix'}>
                            <Button className={'small-button button-create float-start me-5'}
                                    onClick={() => onAddCost(childLine)}>Заработка</Button>

                            <h5 className={'float-start'}>{childLine.article.name}</h5>
                            {canDeleteChildLine() &&
                                <div className={'float-end'}>
                                <ButtonRemove variant={'danger'}
                                        onClick={() => onDelete(childLine)}>Изтрий позиция</ButtonRemove>
                                </div>
                            }
                        </div>
                        <Table>
                            <thead>
                            <tr>
                                <th></th>
                                <th>Количество</th>
                                <th>Цена</th>
                                <th>Общо</th>
                                <th>Служител</th>
                                <th>Бележка</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            {getActiveCosts(childLine.repairJobLineCosts).map(cost => (

                                <tr key={`repair-job-line-cost-${cost.id}`}>
                                    <td>
                                        {errors.filter(error => error.costId === cost.id).map(error => (
                                            <p key={`repair-job-line-cost-error-${cost.id}`} className={'p-2 text-danger bg-opacity-25 bg-danger-subtle d-inline-block'}>{error.message}</p>
                                            ))}
                                    </td>
                                        <RepairJobLineEditCostRow
                                            repairJobLine={childLine}
                                            cost={cost}
                                            onUpdate={(updatedCost) => onUpdateCost(childLine, updatedCost)}
                                            error={errors.filter(error => error.costId === cost.id) ? [0].message : ''}
                                        />
                                </tr>
                            ))}
                            </tbody>
                        </Table>
                    </td>
                </tr>
            )}

            </tbody>
        </Table>
    )
};

export default RepairJobLineEditChildrenTable;