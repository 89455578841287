import {Card} from "react-bootstrap";
import React from "react";
import RepairJobSummarySingleLine from "./RepairJobSummarySingleLine.js";
import {getActiveLines, getActiveOrders} from "../../../../../slices/repairJobSlice.js";

const RepairJobSummaryLines = (
    {
        repairJob
    }) => {

    return (
        <div>
            {getActiveOrders(repairJob.repairJobOrders).map(repairJobOrder => (
                <Card key={`repair-job-summary-line-order-${repairJobOrder.id}`} className={'mb-2'}>
                    <Card.Header><h3>Поръчка № {repairJobOrder.id}</h3></Card.Header>
                    {getActiveLines(repairJobOrder.repairJobLines).map(repairJobLine => (
                        <Card.Body key={`repair-job-summary-line-${repairJobLine.id}`}>
                            <RepairJobSummarySingleLine repairJobLine={repairJobLine} />
                        </Card.Body>
                    ))}
                </Card>
            ))}
        </div>
    )
};

export default RepairJobSummaryLines;