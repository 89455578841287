import DateInput from "../../../../../components/Elements/DateInput.js";
import React from "react";

const EndDateInput = (
    {
        endDate,
        onAttributeChange,
        errors = {},
        disabled = false
    }
) => {

    return (
        <DateInput label={'Дата издаване'} dateValue={endDate} name={'endDate'}
                   onDateValueChange={onAttributeChange} errors={errors} disabled={disabled}/>

    )
}

export default EndDateInput;