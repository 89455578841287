import {Card} from "react-bootstrap";
import ErrorAlertComponent from "../ErrorAlertComponent.js";
import Button from "react-bootstrap/Button";
import React, {useEffect} from "react";
import LoadingComponent from "../LoadingComponent.js";
import {useDispatch} from "react-redux";
import {setBrowserTabTitle} from "../../slices/informationMessagesSlice.js";

const SingleEditLayout = (
    {
        title,
        showForm,
        onSubmit,
        stateErrors,
        children,
        navComponent: NavComponent,
        navProps,
        canSubmit = true
    }) => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setBrowserTabTitle(title));
    }, [title]);

    return (
        <div>
            <h2 className={!showForm ? 'blurry-text' : ''}>{title}</h2>
            {NavComponent && <NavComponent {...navProps} />}
            <ErrorAlertComponent error={stateErrors}/>
            <Card>
                {showForm ? (
                    <>
                    {canSubmit &&

                        <Card.Header>
                            <Button variant="success" type="submit" onClick={onSubmit}>
                                Запази
                            </Button>
                        </Card.Header>
                    }
                        <Card.Body>
                            {children}
                        </Card.Body>
                        {canSubmit &&
                            <Card.Footer>
                                <Button variant="success" type="submit" onClick={onSubmit}>
                                    Запази
                                </Button>
                            </Card.Footer>
                        }
                    </>
                ) : (
                    <LoadingComponent/>
                )}
            </Card>

        </div>
    )
}

export default SingleEditLayout;