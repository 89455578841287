import {Alert} from "react-bootstrap";

const NoteAlertComponent = (
    {
        children
    }
) => {
    return (
        <Alert variant="info" className="text-center d-inline-block">
            {children}
        </Alert>
    )
}

export default NoteAlertComponent;