import React, {useEffect, useState} from 'react';
import { useSelector} from 'react-redux';
import {Link, useParams} from "react-router-dom";
import {numberFormatSpaceThousands} from "../../../utils/functions.js";
import RepairJobNav from "./components/RepairJobNav.js";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {formatDateToBeautifulString} from "../../../utils/dateFunctions.js";
import SingleViewLayout from "../../../components/Layouts/SingleViewLayout.js";
import ViewField from "../../../components/Elements/ViewField.js";
import StatusViewSection from "../../../components/StatusViewSection.js";
import StandardTable from "../../../components/StandardTable.js";
import RepairJobSummaryLines from "./components/View/RepairJobSummaryLines.js";
import RepairJobOrdersComparedErrors from "./components/View/RepairJobOrdersComparedErrors.js";
import CommentsComponent from "../../../components/CommentsComponent.js";
import BorderedPageSection from "../../../components/BorderedPageSection.js";
import RepairJobTotalView from "./components/View/RepairJobTotalView.js";

const RepairJobViewPage = () => {
    const {id} = useParams();
    const {data: repairJob, loading} = useSelector((state) => state.repairJob);
    const [title, setTitle] = useState('');

    useEffect(() => {
        setTitle(`Сервизна заявка № ${id}`);
    }, [repairJob.id]);

    const showPage = () => {
        return !loading;
    }

    return (
        <SingleViewLayout
            title={title}
            showPage={showPage()}
            navComponent={RepairJobNav}
            navProps={{id: id, activeKey: 'view'}}
        >
            <RepairJobOrdersComparedErrors repairJob={repairJob} />
            <Row>
                <Col md={4}>
                    <StatusViewSection value={repairJob.repairJobStatus?.name}/>
                </Col>
                <Col md={4}>
                    <RepairJobTotalView repairJob={repairJob} />
                </Col>
                <Col md={3}>
                    <div className={'single-view-section-wrapper'}>
                        <ViewField label={'Създадена'} value={formatDateToBeautifulString(repairJob.createdAt)}/>
                    </div>
                </Col>
            </Row>
            <Row>
            </Row>
            <Row>
                <Col md={3}>
                    <div className={'single-view-section-wrapper'}>
                        <ViewField label={'Клиент'} value={<Link to={`/clients/${repairJob.client?.id}`}
                                                                 target={'_blank'}>{repairJob.client?.name}</Link>}/>
                        <ViewField label={'Кола'} value={<Link to={`/cars/${repairJob.car?.id}`}
                                                               target={'_blank'}>{repairJob.car?.searchLabel}</Link>}/>
                    </div>
                </Col>
                <Col md={3}>
                    <div className={'single-view-section-wrapper'}>
                        <ViewField label={'Пробег'}
                                   value={repairJob.mileage ? `${numberFormatSpaceThousands(repairJob.mileage)} км` : ''}/>
                        <ViewField label={'Дата приемане'}
                                   value={repairJob.startDate ? formatDateToBeautifulString(repairJob.startDate) : ''}/>
                        <ViewField label={'Дата издаване'}
                                   value={repairJob.endDate ? formatDateToBeautifulString(repairJob.endDate) : ''}/>
                    </div>

                </Col>
                <Col md={3}>
                    <div className={'single-view-section-wrapper'}>
                        <p>Длъжности:</p>
                        {repairJob.repairJobEmployeeOccupations.map(repairJobEditEmployeeOccupation => (
                            <ViewField
                                key={`repair-job-view-page-repair-job-edit-employee-occupation-${repairJobEditEmployeeOccupation.employeeOccupation.id}`}
                                label={repairJobEditEmployeeOccupation.employeeOccupation.name}
                                value={repairJobEditEmployeeOccupation.employee.name}
                            />
                        ))}
                    </div>
                </Col>
                <Col md={12}>
                    <div className={'single-view-section-wrapper'}>
                        <h2>Поръчки</h2>
                        <StandardTable headers={['№', 'Статус', 'Частен ремонт',  'Застраховател', 'Щета']}>
                            {repairJob.repairJobOrders.map(repairJobOrder => (
                                <tr key={`repair-jobs-view-page-repair-job-table-row-${repairJobOrder.id}`}>
                                    <td><Link to={`/orders/${repairJobOrder.id}`}
                                              target={'_blank'}>{repairJobOrder.id}</Link></td>
                                    <td>{repairJobOrder.orderStatus?.name}</td>
                                    <td>{repairJobOrder.isPrivate ? 'Да' : 'Не'}</td>
                                    <td>{repairJobOrder.insurer?.name}</td>
                                    <td>{repairJobOrder.insurerClaimId}</td>
                                </tr>
                            ))}
                        </StandardTable>
                    </div>
                </Col>
                <Col md={12}>
                    <div className={'single-view-section-wrapper w-100'}>
                        <h2>Заработки</h2>
                        <RepairJobSummaryLines repairJob={repairJob} />
                    </div>
                </Col>
            </Row>
            <Row className={'mt-5'}>
                <Col md={12}>
                    <BorderedPageSection>
                        <CommentsComponent item={repairJob} apiPath={'repair-jobs'} />
                    </BorderedPageSection>
                </Col>
            </Row>
        </SingleViewLayout>
    );
}

export default RepairJobViewPage;
