import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import FormCheckbox from "../../../../components/Elements/FormCheckbox.js";
import {Button} from "react-bootstrap";
import DatePicker from "../../../../components/Elements/DatePicker.js";
import {dateToYmdFormat, formatDateToBeautifulString} from "../../../../utils/dateFunctions.js";

const RepairJobTableFilters = (
    {
        onFilterChange,
        showContent,
        filterData
    }) => {
    const {repairJobStatuses} = useSelector((state) => state.commonData);

    const [filters, setFilters] = useState(filterData);
    const [showDatePicker, setShowDatePicker] = useState(false);

    useEffect(() => {
        onFilterChange(filters);
    }, [filters]);

    useEffect(() => {
        setFilters((prevFilters) => {
            const mergedFilters = {...prevFilters};

            Object.entries(filterData).forEach(([key, value]) => {
                if (value !== undefined && value !== '') {
                    mergedFilters[key] = value;
                }
            });

            return mergedFilters;
        });
    }, []);

    const handleRepairJobStatusChange = (name, isChecked) => {
        const repairJobStatusId = name.split('-')[1];

        setFilters((prevFilters) => {
            const repairJobStatusIdsString = prevFilters.repairJobStatusIds || '';
            const repairJobStatusIdsArray = repairJobStatusIdsString ? repairJobStatusIdsString.split(',') : [];

            let updatedRepairJobStatusIds;

            if (isChecked) {
                if (!repairJobStatusIdsArray.includes(repairJobStatusId)) {
                    updatedRepairJobStatusIds = [...repairJobStatusIdsArray, repairJobStatusId];
                } else {
                    updatedRepairJobStatusIds = repairJobStatusIdsArray;
                }
            } else {
                updatedRepairJobStatusIds = repairJobStatusIdsArray.filter((id) => id !== repairJobStatusId);
            }

            return {
                ...prevFilters,
                repairJobStatusIds: updatedRepairJobStatusIds.length > 0 ? updatedRepairJobStatusIds.join(',') : '',
            };
        });
    }

    const handleDatePickerSelect = (selected) => {
        setFilters((prevFilters) => {
            return {
                ...prevFilters,
                dateField: 'all',
                from: selected?.from ? dateToYmdFormat(new Date(selected.from)) : '',
                to: selected?.to ? dateToYmdFormat(new Date(selected.to)) : ''
            }
        })
    }

    return (
        <div className={'mb-3'}>
            <Row>
                <Col md={12} style={{visibility: showContent ? 'visible' : 'hidden'}}>
                    <Row className="d-inline-flex flex-wrap">
                        <Col md={"auto"}><b>Статус:</b></Col>
                        {repairJobStatuses.map((repairJobStatus) => (
                            <Col md={"auto"} key={`repair-job-status-id-${repairJobStatus.id}`}>
                                <span>{filters.orderStatusIds?.split(',').includes(repairJobStatus.id.toString()) || false}</span>
                                <FormCheckbox
                                    className={'d-inline'}
                                    name={`repairJobStatusId-${repairJobStatus.id}`}
                                    label={repairJobStatus.name}
                                    isChecked={filters.repairJobStatusIds?.split(',').includes(repairJobStatus.id.toString()) || false}
                                    onCheckboxChange={handleRepairJobStatusChange}
                                />
                            </Col>
                        ))}
                    </Row>
                </Col>
                <Col md={6} className={'mt-2'}>
                    <div>
                        <b>Дати: </b>
                        <i>
                            {filters.from && <span>от </span>}:
                            {filters.from ? formatDateToBeautifulString(filters.from) : ''}
                            {filters.to && <span> до: </span>}
                            {filters.to ? formatDateToBeautifulString(filters.to) : ''}
                        </i>
                    </div>
                    <Button onClick={() => setShowDatePicker(!showDatePicker)} className={'small-button'}
                            variant={"success"}>
                        {showDatePicker ? 'Скрий' : 'Покажи'} календар
                    </Button>
                </Col>
                {showDatePicker && (
                    <DatePicker onSelect={handleDatePickerSelect} preSelected={{to: filters.to, from: filters.from}}/>
                )}
            </Row>
        </div>
    );
};

export default RepairJobTableFilters;