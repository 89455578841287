import FormNumericInput from "../../../../../components/Elements/FormNumericInput.js";
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import FormInput from "../../../../../components/Elements/FormInput.js";
import ButtonRemove from "../../../../../components/Elements/ButtonRemove.js";
import SearchAutoCompleteComponent from "../../../../../components/SearchAutoCompleteComponent.js";
import {fetchActiveEmployees, resetStateData} from "../../../../../slices/employeeListSlice.js";
import {hasPermission} from "../../../../../utils/permissions.js";
import {toFloatBgn} from "../../../../../utils/functions.js";

const RepairJobLineEditCostRow = (
    {
        cost,
        onUpdate
    }) => {
    const {data: employees} = useSelector((state) => state.employeeList);
    const {user} = useSelector((state) => state.auth);

    const [inputsDisabled, setInputsDisabled] = useState(false);

    useEffect(() => {
        setInputsDisabled(!cost.isActive);
    }, [cost.isActive]);

    const handleUpdate = (attribute, value) => {
        const updatedCost = {
            ...cost,
            [attribute]: value
        };
        onUpdate(updatedCost);
    }

    const canDeleteCost = () => {
        return (hasPermission(user, 'delete-repair-jobs') || isNaN(cost.id)) && cost.isActive;
    }

    return (
        <>
            <td>
                <div style={{width: '80px'}} className={'d-inline-block'}>
                    <FormNumericInput
                        value={cost.quantity}
                        name={'quantity'}
                        onChange={handleUpdate}
                        disabled={inputsDisabled}/>
                </div>
            </td>
            <td>
                <div style={{width: '80px'}} className={'d-inline-block'}>
                    <FormNumericInput
                        value={cost.price}
                        name={'price'}
                        onChange={handleUpdate}
                        disabled={inputsDisabled}
                    />
                </div>
            </td>
            <td>{toFloatBgn(cost.price * cost.quantity)}</td>
            <td>
                <SearchAutoCompleteComponent
                    items={employees}
                    name={'employee'}
                    item={cost.employee}
                    resetStateData={resetStateData}
                    fetchItems={fetchActiveEmployees}
                    onItemChange={handleUpdate}
                    disabled={inputsDisabled}
                    reducer={'employeeList'}
                />
            </td>
            <td>
                <FormInput onChange={handleUpdate} name={'note'}  value={cost.note}
                           inlineLabel={true} disabled={inputsDisabled}/>
            </td>
            <td >
                {canDeleteCost() &&
                    <div className={'float-end'}>
                    <ButtonRemove onClick={() => handleUpdate('isActive', false)}>Изтрий заработка</ButtonRemove>
                    </div>
                }
            </td>
        </>
    )
};

export default RepairJobLineEditCostRow;