import React, { useEffect, useState, useRef } from "react";
import Form from "react-bootstrap/Form";

const SearchAutoCompleteStaticComponent = (
    {
        onItemChange,
        item,
        name,
        errors = {},
        itemLabel = "name",
        label,
        disabled = false,
        items,
        emptyAfterSelect = false
    }) => {
    const [query, setQuery] = useState('');
    const [inputValue, setInputValue] = useState('');
    const [filteredItems, setFilteredItems] = useState([]);
    const inputRef = useRef(null); // Ref for measuring input width
    const [focusedIndex, setFocusedIndex] = useState(-1); // Track the focused item
    const listRef = useRef(null); // Ref for the <ul> element

    useEffect(() => {
        // Only search if the query length is greater than 1
        if (query.length > 1) {
            const delayDebounceFn = setTimeout(() => {
                // Filter items where the name includes the query (case insensitive)
                const results = items.filter(item =>
                    item.name.toLowerCase().includes(query.toLowerCase())
                );
                setFilteredItems(results);
            }, 300);

            // Cleanup function to clear the timeout if query changes within the delay period
            return () => clearTimeout(delayDebounceFn);
        } else {
            // Clear the filtered items if the query is too short
            setFilteredItems([]);
        }
    }, [query, items]);

    const handleClickOutside = (event) => {
        if (listRef.current && !listRef.current.contains(event.target)) {
            setQuery("");
        }
    };

    useEffect(() => {
        // Add event listener for clicks
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Cleanup the event listener when component unmounts
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        setInputValue(item ? item[itemLabel] : '');
    }, [item, itemLabel]);

    const handleItemChange = (item) => {
        onItemChange(name, item);
        setQuery("");
        setFocusedIndex(-1); // Reset focused index
        if (emptyAfterSelect) {
            setInputValue('');
        }
    };

    // Handle keydown events for arrow and enter key navigation
    const handleKeyDown = (e) => {
        if (e.key === "ArrowDown") {
            // Move focus down, but not beyond the list
            setFocusedIndex((prevIndex) =>
                prevIndex < items.length - 1 ? prevIndex + 1 : 0
            );
        } else if (e.key === "ArrowUp") {
            // Move focus up, but not before the first item
            setFocusedIndex((prevIndex) =>
                prevIndex > 0 ? prevIndex - 1 : items.length - 1
            );
        } else if (e.key === "Enter" && focusedIndex !== -1) {
            e.preventDefault(); // Prevent form submission

            // Select the focused item when Enter is pressed
            handleItemChange(items[focusedIndex]);
            setFocusedIndex(-1); // Reset the focused index
        }
    };

    // Scroll the focused item into view
    useEffect(() => {
        if (listRef.current && focusedIndex !== -1) {
            const listItem = listRef.current.children[focusedIndex];
            if (listItem) {
                listItem.scrollIntoView({ block: "nearest" });
            }
        }
    }, [focusedIndex]);

    return (
        <div className={'position-relative'}>
            <div className={'align-items-center'}>
            { label && (
                <Form.Label className={'me-2'} >{label}</Form.Label>
            )}
            <Form.Control
                ref={inputRef}
                disabled={disabled}
                autoComplete={'off'}
                type="text"
                placeholder="Търси..."
                value={inputValue}
                isInvalid={!!errors[name]}
                onChange={(e) => {
                    const value = e.target.value;
                    setQuery(value);
                    setInputValue(value ? value : ''); // Keep input empty when manually cleared
                    setFocusedIndex(-1); // Reset focused index when typing
                }}
                onKeyDown={handleKeyDown} // Attach keydown event for navigation
                onBlur={() => {
                    // When input is cleared and blurred, reset to item[itemLabel] if an item exists
                    if (!query && item) {
                        setInputValue(item[itemLabel]);
                    }
                }}
            />
            {(query && filteredItems.length > 0) && (
                <ul className={"search-auto-complete-results w-100"} ref={listRef}>
                    {filteredItems.map((item, index) => (
                        <li
                            key={item.id}
                            onClick={() => handleItemChange(item)}
                            onMouseEnter={() => setFocusedIndex(index)} // Handle hover event
                            className={focusedIndex === index ? "focused" : ""}
                            style={{
                                backgroundColor: focusedIndex === index ? "#ddd" : "transparent",
                            }}
                        >
                            {item[itemLabel]}
                        </li>
                    ))}
                </ul>
            )}
            <Form.Control.Feedback type="invalid">
                {errors[name]}
            </Form.Control.Feedback>
        </div>
        </div>
    );
};

export default SearchAutoCompleteStaticComponent;
