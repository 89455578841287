import Col from "react-bootstrap/Col";
import React from "react";
import FormCheckbox from "../../../../../components/Elements/FormCheckbox.js";

const RepairJobLineEditArticlesCheckboxes = (
    {
        repairJobLine,
        repairJobLineArticles,
        onUpdate
    }) => {

    const articleIsAdded = (article) => {
        return repairJobLine.childLines.some(
            (childLine) =>
                childLine.article.id === article.id && childLine.isActive === true
        );
    }

    return (
        <>
        {repairJobLineArticles.map((article) => (
                <Col md={2} className={'small'} key={`repair-job-child-line-article-${article.id}`}>
                    <FormCheckbox
                        name={`repair-job-child-line-article-${article.id}`}
                        label={article.name}
                        isChecked={articleIsAdded(article)}
                        onCheckboxChange={() => onUpdate(article)}
                        disabled={articleIsAdded(article)}
                    />
                </Col>
            ))}
        </>
    )
};

export default RepairJobLineEditArticlesCheckboxes;