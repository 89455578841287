import RepairJobChildLineView from "./RepairJobChildLineView.js";
import React from "react";
import RoundedInfo from "../../../../../components/RoundedInfo.js";
import {getActiveLines, getRepairJobLineTotal} from "../../../../../slices/repairJobSlice.js";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import {toFloatBgn} from "../../../../../utils/functions.js";


const RepairJobSummarySingleLine = (
    {
        repairJobLine
    }) => {

    return (
        <>
            <RoundedInfo>
                <Row>
                    <Col md={4}>
                <h5>{repairJobLine.article?.name} - {repairJobLine.damageLevel?.name}</h5>
                    </Col>
                    <Col md={2}>
                        {toFloatBgn(getRepairJobLineTotal(repairJobLine))}
                    </Col>
                </Row>
            </RoundedInfo>
            {repairJobLine.childLines.length > 0 ? (
                <div key={`repair-job-summary-single-line-${repairJobLine.id}`} className={'border border-1 rounded-2 p-2'}>
                    {getActiveLines(repairJobLine.childLines).map(chidldLine => (
                        <RepairJobChildLineView key={`repair-job-summary-single-line-child-${chidldLine.id}`} childLine={chidldLine}/>
                    ))}
                </div>
            ) : (
                <p>Няма заработки</p>
            )}
        </>
    )
}

export default RepairJobSummarySingleLine;