import NoteAlertComponent from "../../../../../components/NoteAlertComponent.js";
import React from "react";
import {isRepairJobEditLock} from "../../../../../slices/repairJobSlice.js";
import {useSelector} from "react-redux";


const RepairJobLockNote = (
    {
        repairJob
    }
) => {
    const {user} = useSelector((state) => state.auth);

    return (
        <>
            {isRepairJobEditLock(repairJob, user) &&
                    <NoteAlertComponent>Заявката не може да бъде редактирана.</NoteAlertComponent>
            }
        </>
    )
}

export default RepairJobLockNote;