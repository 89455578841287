import {getMimeTypeFromFileName} from "../../utils/fileFunctions.js";
import {Button, Image} from "react-bootstrap";
import React, {useState} from "react";
import ConfirmationModalComponent from "../ConfirmationModalComponent.js";
import {useDispatch, useSelector} from "react-redux";
import {deleteFile} from "../../slices/fileSlice.js";
import apiClient from "../../axios.js";
import {hasPermission} from "../../utils/permissions.js";

const FileElement = (
    {
        filePath,
        fileName
    }) => {
    const {user} = useSelector((state) => state.auth);

    const dispatch = useDispatch();
    const [modalBodyText, setModalBodyText] = useState('');
    const [showModal, setShowModal] = useState(false);
    const {loading: isDeleteLoading} = useSelector((state) => state.file);

    const mimeType = getMimeTypeFromFileName(fileName);

    const showConfirmationModal = () => {
        setModalBodyText(`Сигурен ли си, че искаш да изтриеш файл ${fileName}?`);
        setShowModal(true);
    }

    const handleDeleteImage = async () => {
        await dispatch(deleteFile({path: `${filePath}/${fileName}`}));
        setShowModal(false);
    }

    const showBlob = async () => {
        try {
            const response = await apiClient.get(`${filePath}/${fileName}`, {
                headers: {
                    'Accept': mimeType,
                },
                responseType: 'blob'
            });

            if (response.status !== 200) {
                throw new Error(`Failed to fetch file: ${response.statusText}`);
            }

            const blobUrl = window.URL.createObjectURL(response.data);

            const newWindow = window.open(blobUrl);
            if (!newWindow) {
                throw new Error('Failed to open a new tab. Please allow pop-ups in your browser.');
            }

            newWindow.onbeforeunload = () => URL.revokeObjectURL(blobUrl);
        } catch (error) {
            console.error('Error displaying file from protected filePath:', error);
        }
    }
    return (
        <>
            <div>
                <div className={'cursor-pointer'} onClick={() => showBlob()}>
                    {mimeType !== 'application/pdf' ? (
                        <>
                            <Image className={'hover-50-opacity'}
                                src={`${filePath}/${fileName}`}/>
                            {hasPermission(user, 'delete-files') &&

                                <Button className={'small-button'} variant={'danger'} onClick={(e) => {
                                e.stopPropagation();
                                showConfirmationModal();
                            }}>Изтрий</Button>
                            }
                        </>

                    ) : (
                        <div className={'clearfix'}>
                        <span className={'hover-50-opacity'}>{fileName}</span>
                            {hasPermission(user, 'delete-files') &&
                            <Button className={'small-button float-end '} variant={'danger'} onClick={(e) => {
                                e.stopPropagation();
                                showConfirmationModal();
                            }}>Изтрий</Button>
                            }
                        </div>
                    )}
                </div>
            </div>
            <ConfirmationModalComponent
                loading={isDeleteLoading}
                show={showModal}
                onDecline={() => setShowModal(false)}
                bodyText={modalBodyText}
                onConfirm={handleDeleteImage}
            />
        </>
    )
};

export default FileElement;