
export const getCommonExtraReducers = (builder, fetchItem, saveItem) => {
    return builder
        .addCase(fetchItem.pending, (state) => {
            state.loading = true;
            state.status = 'fetching';
        })
        .addCase(fetchItem.fulfilled, (state, action) => {
            state.data = action.payload.data;
            state.loading = false;
            state.status = 'fetched';
        })
        .addCase(fetchItem.rejected, (state, action) => {
            state.errors = action.payload || action.error.message;
            state.loading = false;
            state.status = 'rejected';
        })
        .addCase(saveItem.pending, (state) => {
            state.loading = true;
            state.status = 'saving'
        })
        .addCase(saveItem.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload.data;
            state.status = 'saved';
            state.errors = [];
        })
        .addCase(saveItem.rejected, (state, action) => {
            state.errors = action.payload || action.error.message;
            state.loading = false;
            state.status = 'rejected';
        });
}

export const getSaveCommonExtraReducer = (builder, saveItem) => {
    return builder
        .addCase(saveItem.pending, (state) => {
            state.loading = true;
            state.status = 'saving'
        })
        .addCase(saveItem.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload.data;
            state.status = 'saved';
            state.errors = [];
        })
        .addCase(saveItem.rejected, (state, action) => {
            state.errors = action.payload || action.error.message;
            state.loading = false;
            state.status = 'rejected';
        });
}

export const getCommonExtraReducersList = (builder, fetchItems) => {
    return builder
        .addCase(fetchItems.pending, (state) => {
            state.loading = true;
        })
        .addCase(fetchItems.fulfilled, (state, action) => {
            state.data = action.payload.data;
            state.pagination.currentPage = action.payload.meta.currentPage;
            state.pagination.lastPage = action.payload.meta.lastPage;
            state.pagination.totalItems = action.payload.meta.total;
            state.loading = false;
            state.errors = [];
        })
        .addCase(fetchItems.rejected, (state, action) => {
            state.errors = action.payload || action.error.message;
            state.loading = false;
        });
}

export const getSaveCommonExtraReducersList = (builder, saveItems) => {
    return builder
        .addCase(saveItems.pending, (state) => {
            state.loading = true;
            state.status = 'saving';
        })
        .addCase(saveItems.fulfilled, (state, action) => {
            state.data = action.payload.data;
            state.pagination.currentPage = action.payload.meta.currentPage;
            state.pagination.lastPage = action.payload.meta.lastPage;
            state.pagination.totalItems = action.payload.meta.total;
            state.loading = false;
            state.errors = [];
            state.status = 'saved';
        })
        .addCase(saveItems.rejected, (state, action) => {
            state.errors = action.payload || action.error.message;
            state.loading = false;
            state.status = 'rejected';
        });
}

export const getFilesFetchExtraReducerList = (builder, fetchFiles) => {
    return builder
        .addCase(fetchFiles.pending, (state) => {
            state.loading = true;
        })
        .addCase(fetchFiles.fulfilled, (state, action) => {
            state.data = action.payload.data;
            state.loading = false;
            state.errors = [];
        })
        .addCase(fetchFiles.rejected, (state, action) => {
            state.errors = action.payload || action.error.message;
            state.loading = false;
        });
}