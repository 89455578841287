import {Button} from "react-bootstrap";
import React from "react";


const ClearFiltersButton = (
    {
        onClearFilters
    }) => {

    return (
        <Button variant="danger" className={'ms-2'} onClick={onClearFilters}>
            Изчисти филтри
        </Button>
    )
}

export default ClearFiltersButton;