import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    saveOrder,
    changeAttribute, resetStateData, setStatus, fetchOrder
} from "../../../../slices/orderSlice.js";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {processSubmit} from "../../../../utils/functions.js";
import {getOrderSchema} from "../../../../utils/schemas.js";
import {setSuccessMessage} from "../../../../slices/informationMessagesSlice.js";
import LoadingComponent from "../../../../components/LoadingComponent.js";
import ErrorAlertComponent from "../../../../components/ErrorAlertComponent.js";
import OrderFormSubcontractorComponent from "../../Single/components/OrderFormSubcontractorComponent.js";

const OrderSubcontractorTableModal = (
    {
        show,
        onClose,
        editOrder,
        onOrderSave
    }
) => {
    const dispatch = useDispatch();

    const {data: order, status, loading: orderLoading, errors: stateErrors} = useSelector((state) => state.order);
    const [errors, setErrors] = useState({});

    useEffect(() => {
        dispatch(fetchOrder({id: editOrder.id}));

        return () => {
            dispatch(resetStateData());
        }
    }, []);

    useEffect(() => {
        if (status === 'saved') {
            dispatch(setSuccessMessage(`Успешно запазена поръчка - ${order.id}`));
            dispatch(setStatus('idle'));
            onOrderSave();
            onClose();
        }
    }, [status]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        await processSubmit(setErrors, getOrderSchema(order), saveOrder, dispatch, order);
    }

    const handleAttributeChange = (attribute, value) => {
        dispatch(changeAttribute({attribute, value}));
    };


    return (
        <Modal show={show} onHide={onClose} animation={false} size={'lg'}>
            <Modal.Header closeButton>
                <Modal.Title>Поръчка № {editOrder.id} - Подизпълнител: {editOrder.subcontractor?.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {order.id ? (
                    <>
                        <ErrorAlertComponent error={stateErrors}/>

                        <h5 className={'mb-3'}>Клиент: {order.client.name}</h5>
                        <Row>
                            <Col md={12}>
                                <OrderFormSubcontractorComponent
                                    order={order}
                                    errors={errors}
                                    onChange={handleAttributeChange}
                                />
                            </Col>
                        </Row>
                    </>
                ) : (
                    <LoadingComponent className={'position-relative'}/>
                )}
            </Modal.Body>
            <Modal.Footer>
                {(order.id && !orderLoading) &&
                    <>
                        <Button variant="danger" onClick={onClose}>Откажи</Button>
                        <Button variant="success" onClick={handleSubmit}>Запази</Button>
                    </>
                }
            </Modal.Footer>
        </Modal>
    )
}

export default OrderSubcontractorTableModal;