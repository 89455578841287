import {Nav} from "react-bootstrap";
import React from "react";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import {hasPermission} from "../../../../utils/permissions.js";

const RepairJobNav = ({
                      id,
                      activeKey
                  }) => {
    const {user} = useSelector((state) => state.auth);

    return (
        <Nav variant="tabs" activeKey={activeKey} defaultActiveKey="view" className={'clearfix'}>
            {id !== 'create' && (
                <>
                    <Nav.Item>
                        <Nav.Link as={Link} eventKey={'view'} to={`/repair-jobs/${id}`}>Преглед</Nav.Link>
                    </Nav.Item>
                    {hasPermission(user, 'update-repair-jobs') &&
                        <Nav.Item>
                            <Nav.Link as={Link} eventKey={'edit'} to={`/repair-jobs/${id}/edit`}>Редактирай</Nav.Link>
                        </Nav.Item>
                    }
                </>
            )}
            {hasPermission(user, 'update-repair-jobs') &&
                <>
                    <Nav.Item>
                        <Nav.Link as={Link} eventKey={'orders'} to={`/repair-jobs/${id}/orders`}>Добави
                            поръчки</Nav.Link>
                    </Nav.Item>
                </>
            }
            {id !== 'create' && (
                <>
                    <Nav.Item>
                        <Nav.Link as={Link} eventKey={'files'} to={`/repair-jobs/${id}/files`}>Файлове</Nav.Link>
                    </Nav.Item>
                    {hasPermission(user, 'get-logs') &&
                        <Nav.Item>
                            <Nav.Link as={Link} eventKey={'logs'} to={`/repair-jobs/${id}/logs`}>Логове</Nav.Link>
                        </Nav.Item>
                    }
                </>)}
        </Nav>
    )
};

export default RepairJobNav;