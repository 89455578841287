import {Alert, AlertHeading} from "react-bootstrap";
import StandardTable from "../../../../../components/StandardTable.js";
import {formatDateToBeautifulString} from "../../../../../utils/dateFunctions.js";
import {numberFormatSpaceThousands} from "../../../../../utils/functions.js";
import EndDateInput from "../../../../Orders/Single/components/fields/EndDateInput.js";
import MileageInput from "../../../../Orders/Single/components/fields/MileageInput.js";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import ButtonUpdate from "../../../../../components/Elements/ButtonUpdate.js";
import {useEffect, useState} from "react";
import LoadingComponent from "../../../../../components/LoadingComponent.js";
import apiClient from "../../../../../axios.js";
import HelpText from "../../../../../components/Elements/HelpText.js";
import {useDispatch} from "react-redux";
import {fetchRepairJob} from "../../../../../slices/repairJobSlice.js";
import ErrorAlertComponent from "../../../../../components/ErrorAlertComponent.js";

const RepairJobOrdersComparedErrors = (
    {
        repairJob
    }) => {
    const dispatch = useDispatch();

    const [updatePayload, setUpdatePayload] = useState({
        endDate: null,
        mileage: null
    });

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    useEffect(() => {
        if (repairJob.id) {
            setUpdatePayload({
                endDate: hasDifference('endDate') ? null : repairJob.repairJobOrders[0].endDate,
                mileage: hasDifference('mileage') ? null : repairJob.repairJobOrders[0].mileage
            })
        }
    }, [repairJob]);

    const hasDifference = (field) => {
        if (!repairJob?.repairJobOrders || repairJob.repairJobOrders.length === 0) {
            return false;
        }
        const firstValue = repairJob.repairJobOrders[0][field];
        return repairJob.repairJobOrders.some(order => order[field] !== firstValue);
    };

    const saveOrdersPayload = async () => {
        setError('');
        if (!hasMissingPayload()) {
            setLoading(true);
            try {
                await apiClient.put(`/orders/update-orders-repair-job/${repairJob.id}`, updatePayload);
                dispatch(fetchRepairJob({id: repairJob.id}));
            } catch (error) {
                setError(error.response.data.message);
            }
            setLoading(false);
        }
    }

    const hasMissingPayload = () => {
        return (hasDifference('endDate') && !updatePayload.endDate) ||
            (hasDifference('mileage') && !updatePayload.mileage);
    }

    return (
        <>
            {(hasDifference('endDate') || hasDifference('mileage') || hasDifference(repairJob?.repairJobStatus)) &&
                <>
                    {error &&
                        <div>
                            <ErrorAlertComponent error={error} />
                        </div>
                    }
                    {!loading ? (
                        <Alert variant={'danger'}>
                            <AlertHeading>Има несъвеместима информация в поръчките към заявката!</AlertHeading>
                            <p>Всички поръчки трябва да имат еднакви статус, пробег и дата издаване.</p>
                            <StandardTable
                                headers={['№', 'Дата приемане', 'Дата издаване', 'Статус', 'Пробег']}
                            >
                                {repairJob.repairJobOrders.map(order => (
                                    <tr key={`repair-job-orders-compared-errors-${order.id}`}>
                                        <td>{order.id}</td>
                                        <td>{formatDateToBeautifulString(order.startDate)}</td>
                                        <td>{formatDateToBeautifulString(order.endDate)}</td>
                                        <td>{order.orderStatus.name}</td>
                                        <td>{numberFormatSpaceThousands(order.mileage)} км</td>
                                    </tr>
                                ))}
                            </StandardTable>
                            <p>Въведи следните данни за всички поръчки:</p>
                            <Row>
                                {hasDifference('endDate') &&
                                <Col md={'auto'}>

                                    <EndDateInput endDate={updatePayload.endDate} onAttributeChange={(name, value) => {
                                        setUpdatePayload((prev) => {
                                            return {
                                                ...prev,
                                                endDate: value
                                            }
                                        })
                                    }}/>
                                </Col>
                                }
                                {hasDifference('mileage') &&
                                <Col md={'auto'}>
                                    <MileageInput mileage={updatePayload.mileage} onAttributeChange={(name, value) => {
                                        setUpdatePayload((prev) => {
                                            return {
                                                ...prev,
                                                mileage: value
                                            }
                                        })
                                    }}/>
                                </Col>
                                }
                            </Row>
                            {hasMissingPayload() &&
                                <HelpText>Попълни всички полета!</HelpText>
                            }
                            <ButtonUpdate onClick={() => saveOrdersPayload()} isSmall={false} disabled={hasMissingPayload()}>Запази</ButtonUpdate>

                        </Alert>
                    ) : (
                        <LoadingComponent/>
                    )
                    }
                </>
            }
        </>
    )
};

export default RepairJobOrdersComparedErrors;