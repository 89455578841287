import ViewField from "./Elements/ViewField.js";
import React from "react";


const StatusViewSection = (
    {
        label = 'Статус',
        value,
        className,
        background = 'bg-warning-subtle'
    }) => {
        return (

            <div className={`single-view-section-wrapper ${background} border-5 ${className}`}>
                <h4>
                    <ViewField label={label} value={value}/>
                </h4>
            </div>
        )
}

export default StatusViewSection;