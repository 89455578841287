import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SearchQueryInputSubmitComponent from "../../../../../components/SearchQueryInputSubmitComponent.js";
import {Button} from "react-bootstrap";
import {useEffect, useState} from "react";
import apiClient from "../../../../../axios.js";
import FormCheckbox from "../../../../../components/Elements/FormCheckbox.js";
import StandardTable from "../../../../../components/StandardTable.js";
import {toFloatBgn} from "../../../../../utils/functions.js";
import {useDispatch, useSelector} from "react-redux";
import {changeAttribute} from "../../../../../slices/repairJobSlice.js";
import HelpText from "../../../../../components/Elements/HelpText.js";
import NoteAlertComponent from "../../../../../components/NoteAlertComponent.js";

const RepairJobOrderAdd = (
    {
        onNewOrdersAdded
    }
) => {
    const dispatch = useDispatch();
    const [searchQuery, setSearchQuery] = useState('');
    const [orders, setOrders] = useState([]);
    const [checkedOrders, setCheckedOrders] = useState([]);
    const [searchIsDisabled, setSearchIsDisabled] = useState(false);
    const [triggerAutoSearch, setTriggerAutoSearch] = useState(false);

    const {data: repairJob} = useSelector((state) => state.repairJob);
    const {data: orderStatuses} = useSelector((state) => state.orderStatusList);

    const [alreadyAddedRepairJobOrders] = useState([...repairJob.repairJobOrders]);

    useEffect(() => {
        const allOrders = [...checkedOrders, ...alreadyAddedRepairJobOrders];
        const hasInProgressOrder = allOrders.filter(order => order.orderStatus.settings.inRepairJob);
        const mustUpdateOrderStatus = hasInProgressOrder.length > 0 && hasInProgressOrder.length !== allOrders.length;

        dispatch(changeAttribute({
            attribute: 'addedOrderIds',
            value: [...alreadyAddedRepairJobOrders.map(order => order.id), ...checkedOrders.map(order => order.id)]
        }));
        dispatch(changeAttribute({
            attribute: 'mustUpdateOrderStatus',
            value: mustUpdateOrderStatus
        }));

        onNewOrdersAdded(checkedOrders.length > 0)
    }, [checkedOrders.length]);

    useEffect(() => {
        if (repairJob.car && repairJob.id ) {
            setSearchIsDisabled(true);
            const carRegistrationNumber = repairJob.car.registrationNumber;
            setSearchQuery(carRegistrationNumber);
            setTriggerAutoSearch(true);
        }
    }, [repairJob.id]);

    useEffect(() => {
        if (triggerAutoSearch) {
            handleSearchSubmit(searchQuery);
        }
    }, [triggerAutoSearch]);

    const handleSearchSubmit = async () => {
        setCheckedOrders([]);
        const carFilter = checkedOrders.length ?
            `&filters[carId]=${checkedOrders[0].car?.id}` : repairJob.car ?
                `&filters[carId]=${repairJob.car.id}` : '';
        const ordersResponse = await apiClient.get(
            `/orders/search-repair-job-orders?search=${searchQuery}${carFilter}`
        );
        const existingOrderIds = new Set(repairJob.repairJobOrders.map(order => order.id));
        const filteredOrders = await ordersResponse.data.data.filter(order => !existingOrderIds.has(order.id));
        setOrders(filteredOrders);
    }

    const handleCheckOrder = (order) => {
        setCheckedOrders((prevCheckedOrders) => {
            const isAlreadyChecked = prevCheckedOrders.some(o => o.id === order.id);

            if (isAlreadyChecked) {
                return prevCheckedOrders.filter(o => o.id !== order.id); // Remove if exists
            }
            return [...prevCheckedOrders, order]; // Add the whole order object
        });
    }

    const isOrderChecked = (order) => {
        return checkedOrders.some(o => o.id === order.id);
    }

    const isCheckDisabled = (order) => {
        if (checkedOrders.length) {
            return !checkedOrders.some(checkedOrder => checkedOrder.car.id === order.car.id);
        }
        return false;
    }

    const getAllowedOrderStatusesString = () => {
        return orderStatuses.filter(status => status.settings.canBeAddedToRepairJob).map(status => status.name).join(', ');
    }

    const getRepairJobOrderStatus = () => {
        return orderStatuses.find(status => status.settings.inRepairJob).name;
    }

    return (
        <Row>

            {repairJob.mustUpdateOrderStatus &&
                <Col md={'6'}>
                <NoteAlertComponent>В заявката има поръчки със статус <b>{getRepairJobOrderStatus()}</b> и поръчки с друг статус.<br/>
                    При запазване всички поръчки ще получат статус {getRepairJobOrderStatus()}</NoteAlertComponent>
                </Col>
            }
            <Col md={12}>
                <div className={'d-inline-block'}>
                    <SearchQueryInputSubmitComponent
                        onSubmit={handleSearchSubmit}
                        onQueryChange={(query) => setSearchQuery(query)}
                        defaultQuery={searchQuery}
                        disabled={searchIsDisabled}
                    />
                </div>
                <div className={'d-inline-block ms-2'}>
                    <Button onClick={() => handleSearchSubmit(searchQuery)} disabled={searchIsDisabled}>Търси</Button>
                </div>
                <HelpText>
                    Страницата търси за поръчки в статус <i>{getAllowedOrderStatusesString()}</i>, които не са добавени към сервизна заявка.<br/>
                </HelpText>
                {orders.length > 0 &&
                    <>
                        <h5>Избор:</h5>
                        <StandardTable headers={['', '№', 'Клиент', 'Статус', 'Номер щета', 'Стойност']}>
                            {orders.map((order) => (
                                <tr key={`select-order-row-${order.id}`}>
                                    <td>
                                        <FormCheckbox label={order.car.registrationNumber}
                                                      onCheckboxChange={() => handleCheckOrder(order)}
                                                      isChecked={isOrderChecked(order)}
                                                      name={`check-order-${order.id}`}
                                                      disabled={isCheckDisabled(order)}
                                        />
                                    </td>
                                    <td>
                                        {order.id}
                                    </td>
                                    <td>
                                        {order.client?.name}
                                    </td>
                                    <td>
                                        {order.orderStatus.name}
                                    </td>
                                    <td>
                                        {order.insurerClaimId}
                                    </td>
                                    <td>
                                        {toFloatBgn(order.total)}
                                    </td>
                                </tr>
                            ))}
                        </StandardTable>
                    </>
                }
            </Col>

            <Col md={12}>
                <h5>Поръчки в заявка:</h5>
                {alreadyAddedRepairJobOrders.map((order) => (
                    <div key={`order-in-repair-job${order.id}`}>
                        <span>№: {order.id} | {order.orderStatus.name} | Рег. номер: {order.carRegistrationNumber} | Стойност: {toFloatBgn(order.total)} | Номер щета: {order.insurerClaimId}</span>
                    </div>
                ))}
            </Col>
        </Row>
    )
};

export default RepairJobOrderAdd;