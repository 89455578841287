import {Routes, Route} from "react-router-dom";
import RepairJobListPage from "../pages/RepairJobs/List/RepairJobListPage.js";
import RepairJobEditPage from "../pages/RepairJobs/Single/RepairJobEditPage.js";
import RepairJobViewPage from "../pages/RepairJobs/Single/RepairJobViewPage.js";
import {hasPermission} from "../utils/permissions.js";
import SingleRepairJobWrapper from "../pages/RepairJobs/SingleRepairJobWrapper.js";
import RepairJobOrdersPage from "../pages/RepairJobs/Single/RepairJobOrdersPage.js";
import RepairJobFilesPage from "../pages/RepairJobs/Single/RepairJobFilesPage.js";
import RepairJobLogsPage from "../pages/RepairJobs/Single/RepairJobLogsPage.js";

const RepairJobRoutes = (
    {
        user
    }) => (
    <Routes>
        <Route index element={<RepairJobListPage />} />
        <Route
            path={':id'}
            element={<SingleRepairJobWrapper />}
        >
            <Route index element={<RepairJobViewPage />} />
            <Route path='edit' element={hasPermission(user, 'update-repair-jobs') ? <RepairJobEditPage /> : <RepairJobViewPage />}  />
            <Route path='orders' element={hasPermission(user, 'update-repair-jobs') ? <RepairJobOrdersPage /> : <RepairJobViewPage />}  />
            <Route path='files' element={<RepairJobFilesPage />} />
            <Route path='logs' element={hasPermission(user, 'get-logs') ? <RepairJobLogsPage /> : <RepairJobViewPage />}  />
        </Route>
    </Routes>
);

export default RepairJobRoutes;