import { PencilSquare} from "react-bootstrap-icons";
import TableLinkIconButton from "./TableLinkIconButton.js";
import {useSelector} from "react-redux";
import {hasPermission} from "../utils/permissions.js";

const TableLinkEditButton = (
    {
        to,
        permission = ''
    }) => {

    const {user} = useSelector((state) => state.auth);

    return (
        <>
            {(!permission || hasPermission(user, permission)) &&
                <TableLinkIconButton to={to}>
                    <PencilSquare/>
                </TableLinkIconButton>
            }
        </>
    )

}

export default TableLinkEditButton;
