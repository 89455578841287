import {Button} from "react-bootstrap";
import {useSelector} from "react-redux";
import {hasPermission} from "../../utils/permissions.js";

const ButtonRemove = ({ children, onClick, permission, isSmall = true, ...props }) => {

    const {user} = useSelector((state) => state.auth);
    if (!permission || hasPermission(user, permission)) {
        return <Button variant={'danger'} className={`button-remove me-2 ms-2 ${isSmall ? 'small-button' : ''} `}
                       onClick={onClick} {...props}>{children}</Button>
    } else {
        return <></>;
    }
};

export default ButtonRemove;