import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import React, {useEffect, useState} from "react";

const SearchQueryInputSubmitComponent = (
    {
        onSubmit,
        defaultQuery = '',
        onQueryChange = null,
        disabled = false
    }) => {
    const [query, setQuery] = useState(defaultQuery);

    useEffect(() => {
        if (onQueryChange) {
            onQueryChange(query);
        }
    }, [query]);

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit(query);
    };

    return (
        <Form className={'mt-3 mb-3'} onSubmit={handleSubmit}>
            <Row className="align-items-center">
                <Col xs="auto">
                    <Form.Label className="mr-2">Търси:</Form.Label>
                </Col>
                <Col xs="auto">
                    <Form.Control defaultValue={defaultQuery} onChange={(e) => setQuery(e.target.value)} disabled={disabled}/>
                </Col>
            </Row>
        </Form>
    );
};

export default SearchQueryInputSubmitComponent;