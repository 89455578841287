import { createSlice } from '@reduxjs/toolkit';
import {fetchItems} from "../utils/sliceFunctions.js";
import {getCommonExtraReducersList} from "../utils/commonExtraReducers.js";

const initialState = {
    data: [],
    pagination: {
        currentPage: 1,
        lastPage: 1,
        totalItems: 0
    },
    loading: false,
    error: null
}
export const fetchSubcontractors = fetchItems('subcontractors/fetchSubcontractors', '/partners/subcontractors');

const subcontractorListSlice = createSlice({
    name: 'subcontractors',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        getCommonExtraReducersList(builder, fetchSubcontractors)
    },
});

export default subcontractorListSlice.reducer;