import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FormInput from "../../../../components/Elements/FormInput.js";
import React from "react";
import PhoneNumberInput from "../../../../components/Elements/PhoneNumberInput.js";
import FormCheckbox from "../../../../components/Elements/FormCheckbox.js";
import TextEditor from "../../../../components/Elements/TextEditor.js";
import HelpText from "../../../../components/Elements/HelpText.js";

const PartnerForm = ({
    partner,
    onAttributeChange,
    errors
                      }) => {

    return (
        <Form>
            <Form.Group>
                <Row>
                    <Col md={3}>
                        <FormInput label="Име" name="name" value={partner.name}
                                   onChange={onAttributeChange} errors={errors}/>
                    </Col>
                </Row>
                <Row>
                    <Col md={2}>
                        <FormCheckbox
                            name={'isSubcontractor'}
                            label={'Подизпълнител'}
                            onCheckboxChange={onAttributeChange}
                            isChecked={partner.isSubcontractor}
                            disabled={true}
                        />
                    </Col>
                    <HelpText>Не може да се променя</HelpText>
                </Row>
                <Row className={'mt-3'}>
                    <Col md={3}>
                        <FormInput
                            label={'ЕИК'}
                            name="identificationId" value={partner.identificationId}
                            onChange={onAttributeChange} errors={errors}/>
                    </Col>
                    <Col md={3}>
                        <FormInput label="ДДС номер" name="vatNumber" value={partner.vatNumber}
                                   onChange={onAttributeChange} errors={errors}/>
                    </Col>
                    <Col md={3}>
                        <FormInput label="МОЛ" name="molField" value={partner.molField}
                                   onChange={onAttributeChange} errors={errors}/>
                    </Col>
                </Row>
                <Row>
                    <Col md={4}>
                        <FormInput label="Email" name="email" value={partner.email}
                                   onChange={onAttributeChange} errors={errors}/>
                    </Col>

                    <Col md={4}>
                        <PhoneNumberInput value={partner.phoneNumber} name={'phoneNumber'} onChange={onAttributeChange}
                                          errors={errors}/>
                    </Col>


                    <Col md={4}>
                        <FormInput label="Адрес" name="address" value={partner.address}
                                   onChange={onAttributeChange} errors={errors}/>
                    </Col>

                    <Col md={2}>
                        <FormInput label="Град" name="city" value={partner.city}
                                   onChange={onAttributeChange} errors={errors}/>
                    </Col>

                    <Col md={2}>
                        <FormInput label="Пощенски код" name="zip" value={partner.zip}
                                   onChange={onAttributeChange} errors={errors}/>
                    </Col>

                    <Col md={2}>
                        <FormInput label="Държава" name="country" value={partner.country}
                                   onChange={onAttributeChange} errors={errors}/>
                    </Col>

                </Row>
                <Row>
                    <Col md={4}>
                        <FormInput label="Банка" name="bankName" value={partner.bankName}
                                   onChange={onAttributeChange} errors={errors}/>
                    </Col>
                    <Col md={4}>
                        <FormInput label="BIC" name="bic" value={partner.bic}
                                   onChange={onAttributeChange} errors={errors}/>
                    </Col>
                    <Col md={4}>
                        <FormInput label="IBAN" name="iban" value={partner.iban}
                                          onChange={onAttributeChange} errors={errors}/>
                    </Col>
                    <Col md={12} className={'mt-3'}>
                        <TextEditor errors={errors} onChange={onAttributeChange} name={'additionalInformation'}
                                    value={partner.additionalInformation}
                                    label={'Бележка'}
                        />
                    </Col>
                </Row>
            </Form.Group>
        </Form>
    )
};

export default PartnerForm;