import {setErrorsFromValidation} from "./validation.js";

export const processIdInformation = (id) => {
    const idIsInteger = /^\d+$/.test(id);
    const isValidId = id === 'create' || idIsInteger;

    return {
        idIsInteger: idIsInteger,
        isValidId: isValidId
    };
}

export const calculateOrderTotalPrice = (orderLines) => {
    return orderLines
        .filter(item => item.isActive)
        .reduce((acc, item) => {
            if (item.orderLineType.isCost) {
                return acc;
            }
            return acc + parseFloat(item.totalPrice);
        }, 0);
};

export const toFloat = (number, decimals = 2) => {
    return parseFloat(number).toFixed(decimals);
}

export const getPriceWithoutVatFloat = (priceWithVat) => {
    return toFloat(priceWithVat - priceWithVat / 6)
}
export const toFloatBgn = (number) => {
    return parseFloat(number).toFixed(2) + ' лв.'
}

export const getVatFloatBgn = (priceWithVat) => {
    return toFloatBgn(priceWithVat / 6);
}

export const processSubmit = async (
    setErrors,
    schema,
    saveItem,
    dispatch,
    item,
) => {
    try {
        if (setErrors) {
            setErrors({});
        }
        await schema.validate(item, {abortEarly: false});
        await dispatch(saveItem({id: item.id, item: item}));
    } catch (validationErrors) {
        if (setErrors) {
            setErrors(setErrorsFromValidation(validationErrors));
        }
    }
};

export const loadInitialData = (
    dispatch,
    resetStateData,
    fetchItem,
    id,
    otherItemsFetchers = []
) => {
    const {idIsInteger} = processIdInformation(id);

    dispatch(resetStateData());
    otherItemsFetchers.forEach(fetcher => {
        dispatch(fetcher());
    });
    if (idIsInteger) {
        dispatch(fetchItem({id: id}));
    }
}

export const getHpFromKw = (kw) => {
    return Math.round(kw * 1.36);
}

export const getSummaryTitle = (name) => {
    const summaryNamesAndTitles = {
        'not_in_progress' : 'Неприети',
        'not_released': 'Неиздадени',
        'on_hold' : 'В изчакване',
        'ordered' : 'Заявени',
        'scheduled' : 'Назначени',
        'in_progress' : 'Приети',
        'released' : 'Издадени',
        'invoiced' : 'Фактурирани'
    }
    return summaryNamesAndTitles[name];
}

export const isEmptyObject = (object) => {
    return JSON.stringify(object) === "{}";
}

export const numberFormatSpaceThousands = (num) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
};