const HelpText = (
    {
        children,
        className,
        color = 'red'
    }) => {
    return (
        <span className={`help-text ${className}`} style={{color: color}}>{children}</span>
    );
};

export default HelpText;
