import {Card} from "react-bootstrap";
import React, {useEffect} from "react";
import LoadingComponent from "../LoadingComponent.js";
import {useDispatch} from "react-redux";
import {setBrowserTabTitle} from "../../slices/informationMessagesSlice.js";
import ErrorAlertComponent from "../ErrorAlertComponent.js";

const SingleViewLayout = ({
    title,
    children,
    showPage,
    navComponent : NavComponent,
    navProps,
    stateErrors = ''
                    }) => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setBrowserTabTitle(title));
    }, [title]);

    return (
        <div>
            <h2 className={!showPage ? 'blurry-text' : ''}>{title}</h2>
            {NavComponent && <NavComponent {...navProps} />}
            <ErrorAlertComponent error={stateErrors}/>
            <Card>
                {showPage ? (
                    <Card.Body>
                        {children}
                    </Card.Body>
                ) : (
                    <LoadingComponent/>
                )}

            </Card>
        </div>
    )
};

export default SingleViewLayout;