import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import React, {useEffect, useState} from "react";
import Row from "react-bootstrap/Row";
import StatusViewSection from "../../../../../components/StatusViewSection.js";
import RepairJobSummaryLines from "../View/RepairJobSummaryLines.js";
import Col from "react-bootstrap/Col";
import ViewField from "../../../../../components/Elements/ViewField.js";
import NoteAlertComponent from "../../../../../components/NoteAlertComponent.js";
import ErrorAlertComponent from "../../../../../components/ErrorAlertComponent.js";
import {useSelector} from "react-redux";

const RepairJobEditConfirmModal = (
    {
        show,
        onClose,
        onSubmit,
        repairJob
    }
) => {
    const [repairJobStatusNote, setRepairJobStatusNote] = useState('');
    const {errors: stateErrors} = useSelector((state) => state.repairJob);

    useEffect(() => {
        if (repairJob.repairJobStatus?.settings?.inProgress) {
            setRepairJobStatusNote('Всички поръчки в заявката получават статус "Приета за ремонт"');
        } else if (repairJob.repairJobStatus?.settings?.onHold) {
            setRepairJobStatusNote('Всички поръчки в заявката получават статус "В изчакване"');
        } else if (repairJob.repairJobStatus?.settings?.released) {
            setRepairJobStatusNote('Всички поръчки в заявката получават статус "Издадена"');
        }
    }, [repairJob]);

    return (
        <Modal show={show} onHide={onClose} animation={false} size={'xl'}>
            <Modal.Header><Modal.Title>Потвърди информацията</Modal.Title></Modal.Header>
            <Modal.Body>
                <Row>
                    <Col md={'auto'}>
                        <div>
                            <ErrorAlertComponent error={stateErrors}/>
                        </div>
                        <StatusViewSection value={repairJob.repairJobStatus.name}/>
                        {repairJobStatusNote &&
                            <div>
                                <NoteAlertComponent>{repairJobStatusNote}</NoteAlertComponent>
                            </div>
                        }
                    </Col>
                </Row>
                <Row>
                    <RepairJobSummaryLines repairJob={repairJob}/>
                </Row>
                <Row>
                    <Col md={12}>
                        {repairJob.repairJobEmployeeOccupations.length > 0 &&
                            <div className={'single-view-section-wrapper'}>
                                {repairJob.repairJobEmployeeOccupations.map(repairJobEditEmployeeOccupation => (
                                    <ViewField
                                        key={`repair-job-edit-confirm-modal-repair-job-employee-occupation-${repairJobEditEmployeeOccupation.employeeOccupation.id}`}
                                        label={repairJobEditEmployeeOccupation.employeeOccupation.name}
                                               value={repairJobEditEmployeeOccupation.employee.name}/>
                                ))}
                            </div>
                        }
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" onClick={onClose}>Откажи</Button>
                <Button variant="success" onClick={onSubmit}>Запази</Button>
            </Modal.Footer>
        </Modal>
    )
};

export default RepairJobEditConfirmModal;