import { Card } from "react-bootstrap";
import {Link} from "react-router-dom";
import React from "react";
import LoadingComponent from "../../../../components/LoadingComponent.js";
import StandardTable from "../../../../components/StandardTable.js";
import TableLinkIconButton from "../../../../components/TableLinkIconButton.js";
import {CartCheckFill, EyeFill, PencilSquare} from "react-bootstrap-icons";
import {useSelector} from "react-redux";
import {hasPermission} from "../../../../utils/permissions.js";

const PartnersTable = ({
    data,
    showTable
                        }) => {
    const {user} = useSelector((state) => state.auth);

    const headers = ['№', 'Име', 'Email', 'Подизпълнител', ''];

    return (
        <Card>
            {showTable ? (
                <StandardTable headers={headers}>
                    {data.map((partner) => (
                        <tr key={partner.id}>
                            <td>{partner.id}</td>
                            <td><Link to={`/settings/partners/${partner.id}`}>{partner.name}</Link></td>
                            <td>{partner.email}</td>
                            <td>{partner.isSubcontractor ? 'Да' : 'Не'}</td>
                            <td>
                                <TableLinkIconButton to={`/settings/partners/${partner.id}`}>
                                    <EyeFill/>
                                </TableLinkIconButton>
                                {hasPermission(user, 'update-partners') &&
                                    <TableLinkIconButton to={`/settings/partners/${partner.id}/edit`}>
                                        <PencilSquare/>
                                    </TableLinkIconButton>
                                }
                                {hasPermission(user, 'get-orders') &&
                                    <TableLinkIconButton to={`/settings/partners/${partner.id}/orders`}
                                                         title={'Поръчки'}><CartCheckFill/></TableLinkIconButton>
                                }
                            </td>
                        </tr>
                    ))}
                </StandardTable>
            ) : (
                <LoadingComponent/>
            )}
        </Card>
    )
};

export default PartnersTable