import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useParams} from "react-router-dom";
import Row from "react-bootstrap/Row";
import EmployeeNav from "./components/EmployeeNav.js";
import SingleViewLayout from "../../../components/Layouts/SingleViewLayout.js";
import Col from "react-bootstrap/Col";
import ViewField from "../../../components/Elements/ViewField.js";
import StatusViewSection from "../../../components/StatusViewSection.js";

const EmployeeViewPage = () => {
    const {id} = useParams();

    const {data: employee, loading} = useSelector((state) => state.employee);

    const [title, setTitle] = useState('');

    useEffect(() => {
        setTitle(`Служител № ${id} - ${employee.name}`);
    }, [employee.id]);

    const showPage = () => {
        return !loading && employee.id;
    }

    return (
        <SingleViewLayout
            title={title}
            navComponent={EmployeeNav}
            navProps={{id: id, activeKey: 'view'}}
            showPage={showPage()}
        >
            <Row>
                <Col md={12}>
                    <StatusViewSection label={'Активен'} value={employee.isActive ? 'Да' : 'Не'} />
                </Col>
            </Row>
            <Row>
                <Col md={4}>
                    <Col md={12} className={'single-view-section-wrapper'}>
                        <ViewField label={'Име'} value={employee.name}/>
                    </Col>
                    <Col md={12} className={'single-view-section-wrapper'}>
                        <ViewField
                            label={'Длъжности'}
                            value={employee.employeeOccupations?.map(occ => (
                                occ.name
                            )).join(', ')}
                        />
                    </Col>
                </Col>
            </Row>
        </SingleViewLayout>
    );
};

export default EmployeeViewPage;
