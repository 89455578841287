import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import LoadingComponent from "../../../../../components/LoadingComponent.js";
import Modal from "react-bootstrap/Modal";
import React, {useEffect, useState} from "react";
import Button from "react-bootstrap/Button";
import RepairJobChildLineView from "../View/RepairJobChildLineView.js";

const RepairJobLineDeleteModal = (
    {
        show,
        onClose,
        repairJobLineToDelete,
        repairJobOrder,
        onSubmit
    }
) => {
    const [repairJobLine, setRepairJobLine] = useState({...repairJobLineToDelete});

    useEffect(() => {
        if (!repairJobLine.isActive) {
            onClose();
            onSubmit(repairJobLine);
        }
    }, [repairJobLine.isActive]);

    const handleSubmit = () => {
        setRepairJobLine((prev) => {
            return {
                ...prev,
                isActive: false
            }
        })
    }

    const showForm = () => {
        return repairJobLine;
    }

    return (
        <Modal show={show} onHide={onClose} animation={false} size={'xl'}>
            {showForm() ? (
                <>
                    <Modal.Header closeButton>
                        <Modal.Title>Изтрий позиция - "{repairJobLine.article.name}" в поръчка № {repairJobOrder.id}?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p className={'mb-2'}>Ще бъде изтрита следната информация:</p>
                        <Row>
                            <Col md={12}>
                                {repairJobLine.childLines.map((childLine) => (
                                    <RepairJobChildLineView key={`repair-job-child-line-line-delete${childLine.id}`} childLine={childLine} />
                                ))}
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" onClick={() => onClose()}>Откажи</Button>
                        <Button variant="success" onClick={() => handleSubmit()}>Премахни</Button>
                    </Modal.Footer>
                </>
            ) : (
                <LoadingComponent/>
            )}
        </Modal>
    )
};

export default RepairJobLineDeleteModal;