import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams, useNavigate} from "react-router-dom";
import {changeAttribute, saveCar, setStatus} from "../../../slices/carSlice.js";
import {processIdInformation, processSubmit} from "../../../utils/functions.js";
import CarForm from "./components/CarForm.js";
import SingleEditLayout from "../../../components/Layouts/SingleEditLayout.js";
import CarNav from "./components/CarNav.js";
import {getCarSchema} from "../../../utils/schemas.js";
import {setSuccessMessage} from "../../../slices/informationMessagesSlice.js";

const CarEditPage = () => {
    const {id} = useParams();
    const {idIsInteger} = processIdInformation(id);

    const dispatch = useDispatch();
    const {data: car, loading, errors: stateErrors, status} = useSelector((state) => state.car)

    const navigate = useNavigate();
    const [errors, setErrors] = useState({});
    const [title, setTitle] = useState('');

    const carSchema = getCarSchema();

    useEffect(() => {
        setTitle( idIsInteger ? `Кола № ${id} - ${car.registrationNumber}` : 'Нова Кола');
    }, [car.id]);


    useEffect(() => {
        if (status === 'saved') {
            dispatch(setSuccessMessage(`Успешно запазена кола ${car.registrationNumber}`));
            dispatch(setStatus('idle'))
            navigate(`/cars/${car.id}`);
        }
    }, [status]);

    const handleAttributeChange = (attribute, value) => {
        dispatch(changeAttribute({attribute, value}));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        await processSubmit(setErrors, carSchema, saveCar, dispatch, car);
    };

    const showForm = () => {
        return !loading && ((id === 'create' && !car.id) || (parseInt(id) === car.id));
    }

    return (
        <SingleEditLayout
            title={title}
            showForm={showForm()}
            onSubmit={handleSubmit}
            navComponent={CarNav}
            navProps={{id: id, activeKey: 'edit'}}
            stateErrors={stateErrors}
        >
            <CarForm
                car={car}
                onAttributeChange={handleAttributeChange}
                errors={errors}
            />
        </SingleEditLayout>
    );
};

export default CarEditPage;
