import { createSlice} from '@reduxjs/toolkit';
import {fetchItem, saveItem} from "../utils/sliceFunctions.js";
import {changeAttributeReducer, resetStateDataReducer, setStatusReducer} from "../utils/commonReducers.js";
import {getCommonExtraReducers} from "../utils/commonExtraReducers.js";

const initialState = {
    data: {
        id: null,
        name: '',
        isActive: true,
        employeeOccupations: []
    },
    loading: false,
    errors: [],
    status: 'idle'
};

export const fetchEmployee = fetchItem('employee/fetchEmployee', '/employees')
export const saveEmployee = saveItem('employee/saveEmployee', '/employees');

const employeeItem = createSlice({
    name: 'employeeItem',
    initialState: initialState,
    reducers: {
        changeAttribute: changeAttributeReducer,
        resetStateData: resetStateDataReducer(initialState),
        setStatus: setStatusReducer
    },
    extraReducers: (builder) => {
        getCommonExtraReducers(builder, fetchEmployee, saveEmployee)
    },
});

export default employeeItem.reducer;
export const {
    changeAttribute,
    resetStateData,
    setStatus
} = employeeItem.actions;