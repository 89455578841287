import {Outlet, useLocation, useNavigate, useParams} from 'react-router-dom';
import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {fetchRepairJob, resetStateData} from '../../slices/repairJobSlice.js';
import {processIdInformation} from "../../utils/functions.js";

const SingleRepairJobWrapper = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const {id} = useParams();
    const {idIsInteger} = processIdInformation(id);
    const {data: repairJob, loading, status} = useSelector((state) => state.repairJob);

    useEffect(() => {
        if (!idIsInteger && id !== 'create') {
            navigate('/repair-jobs');
        }
        if (idIsInteger) {
            dispatch(fetchRepairJob({id: id}));
        }

        return () => {
            dispatch(resetStateData());
        }
    }, [location]);

    useEffect(() => {
        if (status === 'fetched' && !repairJob.id) {
            navigate('/repair-jobs');
        }
    }, [loading]);

    return <Outlet/>;
};

export default SingleRepairJobWrapper;