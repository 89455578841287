import {Button, Card} from "react-bootstrap";
import React, {useState} from "react";
import LoadingComponent from "../../../../components/LoadingComponent.js";
import StandardTable from "../../../../components/StandardTable.js";
import TableLinkIconButton from "../../../../components/TableLinkIconButton.js";
import { CartCheckFill, EyeFill, PencilSquare} from "react-bootstrap-icons";
import SelectInput from "../../../../components/Elements/SelectInput.js";
import {useSelector} from "react-redux";
import useFetchRepairJobStatuses from "../../../../app/hooks/useFetchRepairJobStatuses.js";
import RepairJobStatusTableModal from "./RepairJobStatusTableModal.js";
import {formatDateToBeautifulString} from "../../../../utils/dateFunctions.js";
import {isRepairJobEditLock} from "../../../../slices/repairJobSlice.js";
import RepairJobDetailsTableModal from "./RepairJobDetailsTableModal.js";

const RepairJobsTable = (
    {
        data,
        onItemSave,
        showTable = () => true
    }) => {
    const [repairJobStatusTableModalData, setRepairJobStatusTableModalData] = useState({
        newStatus: null,
        repairJob: null
    });
    const [detailsModalData, setDetailsModalData] = useState({
        showDetailsModal: false,
        repairJob: null
    });

    useFetchRepairJobStatuses();

    const {repairJobStatuses} = useSelector((state) => state.commonData);
    const { user } = useSelector((state) => state.auth);

    const headers = ['№', 'Клиент', 'Дата приемане', 'Статус', 'Дата издаване', 'Кола', '', ''];

    const handleRepairJobStatusChange = (repairJob, repairJobStatus) => {
        setRepairJobStatusTableModalData({
            newStatus: repairJobStatus,
            repairJob: repairJob
        })
    }

    return (
        <>
            <Card>
                {(showTable && repairJobStatuses.length) ? (
                    <StandardTable headers={headers}>
                        {data.map((repairJob) => (
                            <tr key={repairJob.id}>
                                <td>{repairJob.id}</td>
                                <td>{repairJob.client ? repairJob.client.name : <i>Няма поръчки</i>}</td>
                                <td>{repairJob.startDate ? formatDateToBeautifulString(repairJob.startDate) : ''}</td>
                                <td>
                                    <div className={'force-text-wrap-auto'}>
                                        <SelectInput
                                            value={repairJob.repairJobStatus.id}
                                            name={'repairJobStatus'}
                                            onChange={(name, status) => {
                                                handleRepairJobStatusChange(repairJob, status)
                                            }}
                                            selectOptions={repairJobStatuses}
                                            disabled={isRepairJobEditLock(repairJob, user)}
                                        />
                                    </div>
                                </td>
                                <td className={'bg-info-subtle'}>{repairJob.endDate ? formatDateToBeautifulString(repairJob.endDate) : ''}</td>
                                <td>{repairJob.car ? repairJob.car.searchLabel : <i>Няма поръчки</i>}</td>
                                <td>
                                    <Button className={'small-button'} onClick={() => setDetailsModalData({
                                        showDetailsModal: true,
                                        repairJob: repairJob
                                    })}>
                                        Виж детайли
                                    </Button>
                                </td>
                                <td>
                                    <TableLinkIconButton
                                        to={`/repair-jobs/${repairJob.id}`}><EyeFill/></TableLinkIconButton>
                                    <TableLinkIconButton
                                        to={`/repair-jobs/${repairJob.id}/edit`}><PencilSquare/></TableLinkIconButton>
                                    <TableLinkIconButton title={'Поръчки'}
                                                         to={`/repair-jobs/${repairJob.id}/orders`}><CartCheckFill/></TableLinkIconButton>
                                </td>
                            </tr>
                        ))}
                    </StandardTable>
                ) : (
                    <LoadingComponent/>
                )}
            </Card>
            {repairJobStatusTableModalData.repairJob &&
                <RepairJobStatusTableModal
                    show={true}
                    onClose={() => setRepairJobStatusTableModalData({repairJob: null, newStatus: null})}
                    newStatus={repairJobStatusTableModalData.newStatus}
                    editRepairJob={repairJobStatusTableModalData.repairJob}
                    onRepairJobSave={onItemSave}
                />
            }
            {detailsModalData.showDetailsModal &&
                <RepairJobDetailsTableModal listRepairJob={detailsModalData.repairJob} onClose={() => setDetailsModalData({
                    showDetailsModal: false,
                    repairJob: null
                })} show={detailsModalData.showDetailsModal} />
            }
        </>
    )
};

export default RepairJobsTable;