import FormNumericInput from "../../../../../components/Elements/FormNumericInput.js";
import React from "react";

const MileageInput = (
    {
        mileage,
        errors = {},
        onAttributeChange,
        disabled = false
    }
) => {
    return (
        <FormNumericInput name={'mileage'} label={'Пробег'} errors={errors} onChange={onAttributeChange}
                          value={mileage} disabled={disabled}
        />
    )
}

export default MileageInput;