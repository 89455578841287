import Modal from "react-bootstrap/Modal";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import { processSubmit} from "../../../../utils/functions.js";
import {changeAttribute, resetStateData, saveCar, setData} from "../../../../slices/carSlice.js";
import Button from "react-bootstrap/Button";
import {getCarSchema} from "../../../../utils/schemas.js";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FormCheckbox from "../../../../components/Elements/FormCheckbox.js";
import ErrorAlertComponent from "../../../../components/ErrorAlertComponent.js";
import CarForm from "../../../Cars/Single/components/CarForm.js";
import SelectInput from "../../../../components/Elements/SelectInput.js";

const AddCarModal = (
    {
        show,
        closeModal,
        onCarUpdate,
        clientCars,
        clientCarsLoading,
        client,
        onNewCarAdded,
        orderCar = null
    }) => {
    const {data: car, errors: stateErrors} = useSelector((state) => state.car);

    const dispatch = useDispatch();

    const [errors, setErrors] = useState({});
    const [isNewCar, setIsNewCar] = useState(false);

    const carSchema = getCarSchema();

    useEffect(() => {
        return () => {
            dispatch(resetStateData());
        }
    }, []);

    useEffect(() => {
        if (!orderCar) {
            dispatch(resetStateData())
        } else {
            dispatch(setData(orderCar))
        }
    }, [dispatch, orderCar, show]);

    useEffect(() => {
        if (isNewCar && car.id) {
            onNewCarAdded();
            handleCarUpdateAndClose(car);
        }
    }, [car, isNewCar]);

    //set client if user chooses to add new car. Use client of order
    useEffect(() => {
        if (isNewCar && !car.client) {
            handleAttributeChange('client', client)
        }
    }, [isNewCar, car])

    const handleIsNewCarToggle = () => {
        dispatch(resetStateData());
        setIsNewCar(!isNewCar);
    }

    const handleClose = () => {
        setErrors({});
        setIsNewCar(false);
        closeModal();
    };

    const handleAttributeChange = (attribute, value) => {
        dispatch(changeAttribute({attribute, value}));
    };

    const handleCarSearchChange = async (name, searchCar) => {
        await dispatch(setData(searchCar));
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (isNewCar) {
            await processSubmit(setErrors, carSchema, saveCar, dispatch, car);
        } else if (stateErrors.length === 0) {
            handleCarUpdateAndClose(car);
        }
    };

    const handleCarUpdateAndClose = (item) => {
        onCarUpdate(item);
        handleClose();
    }

    return (
        <Modal show={show} onHide={handleClose} animation={false} size="xl">
            <Modal.Header closeButton>
                <Modal.Title>Автомобил</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col md={6}>
                        <SelectInput
                            label={'Кола'}
                            name={'car'}
                            value={car?.id ? car.id : !isNewCar && orderCar?.id ? orderCar.id : ''}
                            onChange={handleCarSearchChange}
                            selectOptions={clientCars}
                            disabled={isNewCar || clientCarsLoading}
                        />
                        <div className={'mt-2'}>
                            <FormCheckbox name={'isNewCar'} onCheckboxChange={handleIsNewCarToggle}
                                          isChecked={isNewCar} label={'Нова кола'}/>
                        </div>
                    </Col>
                    <Col md={12}>
                        <ErrorAlertComponent error={stateErrors}/>

                        {isNewCar && (
                            <CarForm
                                onAttributeChange={handleAttributeChange}
                                errors={errors}
                                car={car}
                                forceClient={client}
                            />

                        )}
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" onClick={handleClose}>Откажи</Button>
                {(isNewCar || car?.id) && (
                    <Button variant="success" onClick={handleSubmit}>Запази</Button>
                )}
            </Modal.Footer>
        </Modal>
    )
};

export default AddCarModal;