import { Card } from "react-bootstrap";
import {Link} from "react-router-dom";
import React from "react";
import LoadingComponent from "../../../../components/LoadingComponent.js";
import StandardTable from "../../../../components/StandardTable.js";
import TableLinkViewButton from "../../../../components/TableLinkViewButton.js";
import TableLinkEditButton from "../../../../components/TableLinkEditButton.js";

const EmployeesTable = (
    {
        data,
        showTable
    }) => {

    const headers = ['№', 'Име', 'Активен', ''];

    return (
        <Card>
            {showTable ? (
                <StandardTable headers={headers}>
                    {data.map((employee) => (
                        <tr key={employee.id}>
                            <td>{employee.id}</td>
                            <td><Link to={`/settings/employees/${employee.id}`}>{employee.name}</Link></td>
                            <td>{employee.isActive ? 'Да' : 'Не'}</td>
                            <td>
                                <TableLinkViewButton to={`/settings/employees/${employee.id}`}/>
                                <TableLinkEditButton to={`/settings/employees/${employee.id}/edit`}
                                                     permission={'update-employees'}/>
                            </td>
                        </tr>
                    ))}
                </StandardTable>
            ) : (
                <LoadingComponent/>
            )}
        </Card>
    )
};

export default EmployeesTable