import React from 'react';
import {useSelector} from 'react-redux';
import {fetchClients} from "../../../slices/clientListSlice.js";
import ListHeaderLayout from "../../../components/Layouts/ListHeaderLayout.js";
import ClientsTable from "./components/ClientsTable.js";
import {resetStateData} from "../../../slices/clientSlice.js";
import ListPageContent from "../../../components/Layouts/ListPageContent.js";

const ClientListPage = () => {
    const {data: clients, loading, pagination} = useSelector((state) => state.clientList);

    return (
        <div>
            <ListHeaderLayout
                title={'Клиенти'}
                createLink={'/clients/create/edit'}
                createPermission={'create-clients'}
            />
            <ListPageContent
                resetStateData={resetStateData}
                fetchData={fetchClients}
                loading={loading}
                TableComponent={ClientsTable}
                pagination={pagination}
                data={clients}
            />
        </div>
    );
};

export default ClientListPage;
