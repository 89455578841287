import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams, useNavigate} from "react-router-dom";
import {processIdInformation, processSubmit} from "../../../utils/functions.js";
import PartnerForm from "./components/PartnerForm.js";
import SingleEditLayout from "../../../components/Layouts/SingleEditLayout.js";
import PartnerNav from "./components/PartnerNav.js";
import {setSuccessMessage} from "../../../slices/informationMessagesSlice.js";
import { getPartnerSchema} from "../../../utils/schemas.js";
import {changeAttribute, savePartner, setStatus} from "../../../slices/partnerSlice.js";

const PartnerEditPage = () => {
    const {id} = useParams();
    const {idIsInteger} = processIdInformation(id);

    const dispatch = useDispatch();
    const {data: partner, loading, errors: stateErrors, status} = useSelector((state) => state.partner)

    const navigate = useNavigate();
    const [errors, setErrors] = useState({});
    const [title, setTitle] = useState('');

    const partnerSchema = getPartnerSchema();

    useEffect(() => {
        setTitle( idIsInteger ? `Партньор № ${id} - ${partner.name}` : 'Нов партньор');
    }, [partner.id]);


    useEffect(() => {
        if (status === 'saved') {
            dispatch(setSuccessMessage(`Успешно запазен партньор ${partner.name}`));
            dispatch(setStatus('idle'))
            navigate(`/settings/partners/${partner.id}`);
        }
    }, [status]);

    const handleAttributeChange = (attribute, value) => {
        dispatch(changeAttribute({attribute, value}));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        await processSubmit(setErrors, partnerSchema, savePartner, dispatch, partner);
    };

    const showForm = () => {
        return !loading;
    }

    return (
        <SingleEditLayout
            title={title}
            showForm={showForm()}
            onSubmit={handleSubmit}
            navComponent={PartnerNav}
            navProps={{id: id, activeKey: 'edit'}}
            stateErrors={stateErrors}
        >
            <PartnerForm
                partner={partner}
                onAttributeChange={handleAttributeChange}
                errors={errors}
            />
        </SingleEditLayout>
    )
};

export default PartnerEditPage;
