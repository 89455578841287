import React, {useState, useEffect} from 'react';
import DatePicker from "../../components/Elements/DatePicker.js";
import {Button} from "react-bootstrap";
import {dateToYmdFormat, formatDateToBeautifulString} from "../../utils/dateFunctions.js";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {useDispatch, useSelector} from "react-redux";
import {fetchOrders, resetStateData} from "../../slices/homeListSlice.js";
import HomeOrdersCard from "./HomeOrdersCard.js";
import LoadingComponent from "../../components/LoadingComponent.js";
import {setBrowserTabTitle} from "../../slices/informationMessagesSlice.js";
import apiClient from "../../axios.js";
import HomeBox from "./components/HomeBox.js";
import {getOrderStatusByLabel} from "../../slices/orderStatusListSlice.js";
import {getSummaryTitle} from "../../utils/functions.js";

const Home = (
) => {
    const dispatch = useDispatch();

    const [isInitialLoad, setIsInitialLoad] = useState(true);
    const [homeBoxes, setHomeBoxes] = useState([]);
    const [showDatePicker, setShowDatePicker] = useState(false);
    const [dateRange, setDateRange] = useState(() => {
        const today = new Date();
        const nextWeek = new Date();
        nextWeek.setDate(today.getDate() + 7);

        return {
            from: today,
            to: nextWeek
        };
    });
    const [ordersInRange, setOrdersInRange] = useState({
        ordersStartDate: [],
        ordersEndDate: []
    });
    const [orderedOrderStatus, setOrderedOrderStatus] = useState(null);
    const [inProgressOrderStatus, setInProgressOrderStatus] = useState(null);

    const {loading: ordersLoading} = useSelector((state) => state.homeList);
    const {data: orderStatuses} = useSelector((state) => state.orderStatusList);

    useEffect(() => {
        const fetchData = async () => {
            try {
                dispatch(setBrowserTabTitle('Начало'));
                const homeBoxesResponse = await apiClient.get('/orders/home-boxes-counts');
                setHomeBoxes(homeBoxesResponse.data.data);
                setIsInitialLoad(false);
            } catch (error) {
                console.error('Error fetching home boxes counts:', error);
            }
        };

        fetchData();

        return () => {
            dispatch(resetStateData());
        };
    }, [dispatch]);

    useEffect(() => {
        if (orderStatuses.length > 0) {
            setOrderedOrderStatus(getOrderStatusByLabel(
                orderStatuses,
                'ordered'
            ));
            setInProgressOrderStatus(getOrderStatusByLabel(
                orderStatuses,
                'in_progress'
            ))
        }
    }, [orderStatuses]);

    useEffect(() => {
        if (inProgressOrderStatus && orderedOrderStatus && !showDatePicker && dateRange.from && dateRange.to) {
            loadDateRangeOrders();
        }
    }, [showDatePicker, inProgressOrderStatus, orderedOrderStatus]);

    const handleDatePickerSelect = (selected) => {
        setDateRange({
            from: selected?.from ? selected.from : null,
            to: selected?.to ? selected.to : null
        });
    };

    const loadDateRangeOrders = async () => {
        const fromFilter = dateToYmdFormat(new Date(dateRange.from));
        const toFilter = dateToYmdFormat(new Date(dateRange.to));

        const ordersStartDate = await dispatch(
            fetchOrders({
                filters: {
                    orderStatusId: orderedOrderStatus?.id,
                    dateField: "start_date",
                    from: fromFilter,
                    to: toFilter
                },
                perPage: 1000,
                sort: ['start_date', 'asc']
            })
        ).unwrap();

        const ordersEndDate = await dispatch(
            fetchOrders({
                filters: {
                    orderStatusId: inProgressOrderStatus?.id,
                    dateField: "end_date",
                    from: fromFilter,
                    to: toFilter
                },
                perPage: 1000,
                sort: ['end_date', 'asc']
            })
        ).unwrap();

        setOrdersInRange({
            ordersStartDate: ordersStartDate.data,
            ordersEndDate: ordersEndDate.data
        })
    };

    const showTable = () => {
        return !ordersLoading || isInitialLoad;
    }

    return (
        <div>
            <Row>
                <Col md={12} className={'position-relative'}>
                {homeBoxes.length > 0 ? (
                    <Row>
                        {homeBoxes.map((box) => (
                            <Col md={3} key={box.label}>
                                <HomeBox title={getSummaryTitle(box.label)} count={box.count} link={box.label} color={box.color} />
                            </Col>
                        ))}
                    </Row>
                ) : (
                    <LoadingComponent />
                )}
                </Col>
                <Col md={6}>
                    <h4 className={'mb-3'}>
                        {(dateRange.from && dateRange.to) ? (
                            <div>
                                {formatDateToBeautifulString(dateRange.from)} до {formatDateToBeautifulString(dateRange.to)}
                            </div>
                        ) : <>Избери дати</>}
                    </h4>
                </Col>
                <Col md={6} className="d-flex flex-column align-items-end">
                    <Button onClick={() => setShowDatePicker(!showDatePicker)}>
                        {showDatePicker ? 'Скрий' : 'Покажи'} календар
                    </Button>
                </Col>
                {showDatePicker && (
                    <DatePicker onSelect={handleDatePickerSelect} preSelected={dateRange}/>
                )}
                {!showDatePicker && homeBoxes.length > 0 && (
                <div className={'position-relative'}>
                    {showTable() ? (
                        <Col md={12}>
                            <HomeOrdersCard title={`За приемане - ${ordersInRange.ordersStartDate.length} поръчки`} orders={ordersInRange.ordersStartDate} onOrderSave={loadDateRangeOrders} />
                            <HomeOrdersCard title={`За издаване - ${ordersInRange.ordersEndDate.length} поръчки`} orders={ordersInRange.ordersEndDate} onOrderSave={loadDateRangeOrders} />
                        </Col>
                    ) : (
                        <LoadingComponent/>
                    )}
                </div>
                )}
            </Row>
        </div>
    );
};

export default Home;