import {Button} from "react-bootstrap";
import {useSelector} from "react-redux";
import {hasPermission} from "../../utils/permissions.js";

const ButtonUpdate = (
    {
        children,
        onClick,
        permission,
        disabled = false,
        isSmall = true, ...props
    }) => {

    const {user} = useSelector((state) => state.auth);
    if (!permission || hasPermission(user, permission)) {
        return <Button variant={'secondary'} className={`${isSmall ? 'small-button' : ''} `} disabled={disabled}
                       onClick={onClick} {...props}>{children}</Button>
    } else {
        return <></>;
    }
};

export default ButtonUpdate;