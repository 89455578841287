import { Navigate, Route, Routes } from 'react-router-dom';
import {hasPermission} from "./utils/permissions.js";
import OrderRoutes from "./routes/OrderRoutes.js";
import ArticleRoutes from "./routes/ArticleRoutes.js";
import SettingRoutes from "./routes/SettingRoutes.js";
import ClientRoutes from "./routes/ClientRoutes.js";
import CarRoutes from "./routes/CarRoutes.js";
import Home from "./pages/Home/Home.js";
import Logout from "./pages/Logout.js";
import RepairJobRoutes from "./routes/RepairJobRoutes.js";

const ProtectedRoutes = ({ user }) => {
    if (user === null) {
        return <Navigate to="/"/>;
    }

    return (
        <Routes>
            <Route path={'/logout'} element={<Logout/>}/>
            <Route path="/home" element={<Home/>}/>
            <Route path="/" element={<Home/>}/>

            {hasPermission(user, 'get-orders') && (
                <Route path="/orders/*" element={<OrderRoutes user={user}/>}/>
            )}

            {hasPermission(user, 'get-repair-jobs') && (
                <Route path="/repair-jobs/*" element={<RepairJobRoutes user={user}/>}/>
            )}

            {hasPermission(user, 'get-articles') && (
                <Route path="/articles/*" element={<ArticleRoutes user={user}/>}/>
            )}

            <Route path="/settings/*" element={<SettingRoutes user={user}/>}/>

            {hasPermission(user, 'get-cars') && (
                <Route path="/cars/*" element={<CarRoutes user={user}/>}/>
            )}

            {hasPermission(user, 'get-clients') && (
                <Route path="/clients/*" element={<ClientRoutes user={user}/>}/>
            )}

        </Routes>
    );
};

export default ProtectedRoutes;
