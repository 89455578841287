import SelectInput from "../../../../../components/Elements/SelectInput.js";
import React from "react";
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {hasPermission} from "../../../../../utils/permissions.js";


const OrderStatusSelect = (
    {
        order,
        onOrderStatusChange,
        errors = {},
        hideLabel = false,
        initialOrderStatus
    }) => {
    const {user} = useSelector((state) => state.auth);
    const {data: orderStatuses} = useSelector((state) => state.orderStatusList);
    const userCannotOverWriteLocks = !hasPermission(user, 'overwrite-locks');

    const availableStatuses = orderStatuses.filter((orderStatus) => {
        //never allow user to create cancelled order
        if (orderStatus.label === 'cancelled' && order.id === null) {
            return false;
        }
        // never allow invoiced for order that has no invoice
        if (orderStatus.label === 'invoiced' && !order.hasInvoice) {
            return false;
        }
        //if user can overwrite locks - allow all other caases
        if (!userCannotOverWriteLocks) {
            return true;
        }
        // only super admin can put invoice order paid
        if (order.hasInvoice && orderStatus.label === 'paid') {
            return false;
        }
        if (initialOrderStatus?.label === 'invoiced' && orderStatus.label !== 'invoiced') {
            return false;
        }
        //if order is released user can make it only invoiced or paid
        if (initialOrderStatus?.label === 'released' && !['released', 'invoiced', 'paid'].includes(orderStatus.label)) {
            return false
        }
        return true;
    });

    return (
        <div className={'force-text-wrap-auto'}>
            <SelectInput
                label={hideLabel ? '' : 'Статус'}
                value={order.orderStatus ? order.orderStatus.id : ''}
                name={'orderStatus'}
                errors={errors}
                onChange={onOrderStatusChange}
                selectOptions={availableStatuses}
                disabled={order.orderStatusLock !== ''}
            />
            {order.orderStatusLock === 'repair_job' &&
                <>
                    <p>Заключен статус</p>
                    <Link to={`/repair-jobs/${order.repairJobId}`} target={"_blank"}>Сервизна заявка</Link>
                </>
            }
        </div>
    )
};

export default OrderStatusSelect;