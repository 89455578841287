import {Link} from "react-router-dom";
import {hasPermission} from "../utils/permissions.js";
import {useSelector} from "react-redux";

const TableLinkIconButton = (
    {
        to,
        title,
        children,
        permission = '',
        ...props
    }) => {
    const {user} = useSelector((state) => state.auth);

    return (
        <>
            {(!permission || hasPermission(user, permission)) &&
                <Link className={'me-2 fs-4'} to={to} title={title} {...props}>{children}</Link>
            }
        </>
    )
}

export default TableLinkIconButton;
