import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Login from './pages/Login';
import {fetchUser} from "./slices/authSlice.js";
import Sidebar from "./pages/Sidebar.js";
import Header from "./pages/Header.js";
import './App.css';
import {Container} from "react-bootstrap";
import ProtectedRoutes from "./ProtectedRoutes.js";
import {fetchAppSettings} from "./slices/appSettingsSlice.js";
import {fetchOrderStatuses} from "./slices/orderStatusListSlice.js";
import LoadingComponent from "./components/LoadingComponent.js";
import {fetchArticleTypes} from "./slices/articleTypeListSlice.js";
import {fetchPaymentTypes} from "./slices/paymentTypeListSlice.js";
import {Helmet} from "react-helmet";
import {fetchContractors} from "./slices/contractorListSlice.js";
import {fetchSubcontractors} from "./slices/subcontractorListSlice.js";

const App = () => {
    const dispatch = useDispatch();
    const {user, status} = useSelector((state) => state.auth);
    const {data: appSettings, loading: appSettingsLoading} = useSelector((state) => state.appSettings);
    const isAuthenticated = localStorage.getItem('isAuthenticated') === 'true';
    const {browserTabTitle} = useSelector((state) => state.informationMessages);
    const {data: orderStatuses, loading: orderStatusesLoading} = useSelector((state) => state.orderStatusList);
    const {data: articleTypes, loading: articleTypesLoading} = useSelector((state) => state.articleTypeList);
    const {data: paymentTypes, loading: paymentTypesLoading} = useSelector((state) => state.paymentTypeList);
    const {data: contractors, loading: contractorsLoading} = useSelector((state) => state.contractorList);
    const {data: subcontractors, loading: subcontractorsLoading} = useSelector((state) => state.subcontractorList);

    useEffect(() => {
        const checkAndFetchUser = async () => {
            await dispatch(fetchUser({}));
        };

        if (status === 'idle' && user === null && isAuthenticated) {
            checkAndFetchUser();
        }
    }, [status, user, isAuthenticated]);

    useEffect(() => {
        if (user !== null && appSettings.length === 0 && !appSettingsLoading) {
            dispatch(fetchAppSettings());
        }
        if (user !== null && orderStatusesLoading === false && orderStatuses.length === 0) {
            dispatch(fetchOrderStatuses());
        }
        if (user !== null && articleTypesLoading === false && articleTypes.length === 0) {
            dispatch(fetchArticleTypes());
        }
        if (user !== null && paymentTypesLoading === false && paymentTypes.length === 0) {
            dispatch(fetchPaymentTypes())
        }
        if (user !== null && contractorsLoading === false && contractors.length === 0) {
            dispatch(fetchContractors())
        }
        if (user !== null && subcontractorsLoading === false && subcontractors.length === 0) {
            dispatch(fetchSubcontractors())
        }
    }, [user]);

    useEffect(() => {
        document.title = browserTabTitle; // Set the title dynamically
    }, [browserTabTitle]);

    const applicationLoadReady = () => {
        return user !== null && isAuthenticated && appSettings.length > 0 && orderStatuses.length > 0;
    }

    return (
        <Router>
            <div className="app">
                <Helmet>
                    <meta name="robots" content="noindex, nofollow" />
                </Helmet>
                {status === 'loading' ? (
                    <LoadingComponent className={'loading-overlay-full-page'}/>
                ) : (
                    <>
                        {user !== null ? (
                            <>
                                {applicationLoadReady() ? (
                                    <>
                                        <Sidebar user={user}/>
                                        <div className="main-content">
                                            <Header/>
                                            <Container fluid className={'p-3 ps-5'}>
                                                <ProtectedRoutes user={user}/>
                                            </Container>
                                        </div>
                                    </>
                                ) : (
                                    <LoadingComponent/>
                                )
                                }
                            </>
                        ) : (
                            <div className={'main-content w-100 ms-0'}>
                                <Header/>
                                <Routes>
                                    <Route path="/" element={<Login/>}/>
                                    {!isAuthenticated && (
                                        <Route path="*" element={<Navigate to="/" replace/>}/>
                                    )}
                                </Routes>
                            </div>
                        )}
                    </>
                )}
            </div>
        </Router>
    );
};

export default App;