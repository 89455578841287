import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import React, {useState} from "react";
import RepairJobLineEditModal from "./RepairJobLineEditModal.js";
import ButtonRemove from "../../../../../components/Elements/ButtonRemove.js";
import RepairJobLineDeleteModal from "./RepairJobLineDeleteModal.js";
import ButtonUpdate from "../../../../../components/Elements/ButtonUpdate.js";
import RoundedInfo from "../../../../../components/RoundedInfo.js";
import HelpText from "../../../../../components/Elements/HelpText.js";
import {getRepairJobLineTotal} from "../../../../../slices/repairJobSlice.js";
import {toFloatBgn} from "../../../../../utils/functions.js";

const RepairJobLineEditRow = (
    {
        repairJobOrder,
        repairJobLine,
        emptyRepairJobLine,
        onUpdate
    }) => {
    const [showRepairJobLineEditModal, setShowRepairJobLineEditModal] = useState(false);
    const [showRepairJobLineDeleteModal, setShowRepairJobDeleteModal] = useState(false);

    const lineHasCostsWithoutEmployee = () => {
        const costsWithoutEmployee = repairJobLine.childLines.filter(childLine => {
            return childLine.repairJobLineCosts.some(cost => !cost.employee && !childLine.article.settings?.defaultRepairJobEmployee)
        })
        return costsWithoutEmployee.length > 0;
    }

    return (
        <>
        <div className={'mb-3'}>
            <RoundedInfo>
                {lineHasCostsWithoutEmployee() &&
                    <Row>
                        <Col md={12}>
                            <HelpText><b style={{fontSize: '14px'}}>Има заработки без служител</b> </HelpText>
                        </Col>
                    </Row>
                }
                <Row>
                    <Col md={4}>
                        <h5>{repairJobLine.article.name}</h5>
                        <p><i>{repairJobLine.damageLevel?.name}</i></p>
                        {!repairJobLine.orderLineId &&
                            <HelpText><b style={{fontSize: '12px'}}>Ръчно добавена. Не е в поръчката.</b></HelpText>
                        }
                    </Col>
                    <Col md={1}>
                        {toFloatBgn(getRepairJobLineTotal(repairJobLine))}
                    </Col>
                    <Col md={3}>
                        <ButtonUpdate onClick={() => setShowRepairJobLineEditModal(true)}>Редактирай заработки</ButtonUpdate>
                    </Col>
                    <Col md={4}>
                        <div className={'float-end'}>
                            {repairJobLine.orderLineId === null &&
                                <ButtonRemove permission={'delete-repair-jobs'} onClick={() => setShowRepairJobDeleteModal(true)}>Изтрий позиция</ButtonRemove>
                            }
                        </div>
                    </Col>
                </Row>
            </RoundedInfo>
        </div>
            {showRepairJobLineEditModal &&
                <RepairJobLineEditModal
                    show={showRepairJobLineEditModal}
                    onClose={() => setShowRepairJobLineEditModal(false)}
                    repairJobLineToEdit={repairJobLine}
                    emptyRepairJobLine={emptyRepairJobLine}
                    repairJobOrder={repairJobOrder}
                    onSubmit={(editedRepairJobLine) => onUpdate(repairJobOrder, editedRepairJobLine)}
                />
            }
            {showRepairJobLineDeleteModal &&
                <RepairJobLineDeleteModal
                    show={showRepairJobLineDeleteModal}
                    onClose={() => setShowRepairJobDeleteModal(false)}
                    repairJobLineToDelete={repairJobLine}
                    onSubmit={(editedRepairJobLine) => onUpdate(repairJobOrder, editedRepairJobLine)}
                    repairJobOrder={repairJobOrder}
                />
            }
        </>
    )
};

export default RepairJobLineEditRow;