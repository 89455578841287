import {Routes, Route} from "react-router-dom";
import UserListPage from "../pages/Users/List/UserListPage.js";
import UserEditPage from "../pages/Users/Single/UserEditPage.js";
import {hasPermission} from "../utils/permissions.js";
import CarModelListPage from "../pages/CarModels/List/CarModelListPage.js";
import CarModelViewPage from "../pages/CarModels/Single/CarModelViewPage.js";
import RoleListPage from "../pages/Roles/List/RoleListPage.js";
import RolePage from "../pages/Roles/Single/RolePage.js";
import CarModelEditPage from "../pages/CarModels/Single/CarModelEditPage.js";
import CarManufacturerListPage from "../pages/CarManufacturers/List/CarManufacturerListPage.js";
import CarManufacturerViewPage from "../pages/CarManufacturers/Single/CarManufacturerViewPage.js";
import CarManufacturerEditPage from "../pages/CarManufacturers/Single/CarManufacturerEditPage.js";
import SupplierEditPage from "../pages/Suppliers/Single/SupplierEditPage.js";
import SupplierListPage from "../pages/Suppliers/List/SupplierListPage.js";
import PartnerViewPage from "../pages/Partners/Single/PartnerViewPage.js";
import PartnerEditPage from "../pages/Partners/Single/PartnerEditPage.js";
import PartnerListPage from "../pages/Partners/List/PartnerListPage.js";
import PartnerOrdersPage from "../pages/Partners/Single/PartnerOrdersPage.js";
import SinglePartnerWrapper from "../pages/Partners/SinglePartnerWrapper.js";
import PartnerLogsPage from "../pages/Partners/Single/PartnerLogsPage.js";
import EmployeeListPage from "../pages/Employees/List/EmployeeListPage.js";
import EmployeeViewPage from "../pages/Employees/Single/EmployeeViewPage.js";
import SingleEmployeeWrapper from "../pages/Employees/SingleEmployeeWrapper.js";
import EmployeeEditPage from "../pages/Employees/Single/EmployeeEditPage.js";

const SettingRoutes = ({
                           user
                       }) => (
    <Routes>
        <Route path='/users' element={<UserListPage/>}/>
        <Route path='/users/:id/edit'
               element={hasPermission(user, 'update-users') ? <UserEditPage/> : <UserListPage/>}/>
        <Route path='/car-models' element={<CarModelListPage/>}/>
        <Route path='/car-models/:id' element={<CarModelViewPage/>}/>
        <Route path='/car-models/:id/edit'
               element={hasPermission(user, 'update-car-models') ? <CarModelEditPage/> : <CarModelViewPage/>}/>
        <Route path='/roles' element={<RoleListPage/>}/>
        <Route path='/roles/:id/edit' element={<RolePage/>}/>
        <Route path='/car-manufacturers' element={<CarManufacturerListPage/>}/>
        <Route path='/car-manufacturers/:id' element={<CarManufacturerViewPage/>}/>
        <Route path='/car-manufacturers/:id/edit'
               element={hasPermission(user, 'update-car-models') ? <CarManufacturerEditPage/> :
                   <CarManufacturerViewPage/>}/>
        <Route path='/suppliers' element={hasPermission(user, 'manage-suppliers') ? <SupplierListPage/> : <></>}/>
        <Route path='/suppliers/:id/edit'
               element={hasPermission(user, 'manage-suppliers') ? <SupplierEditPage/> : <></>}/>
        {hasPermission(user, 'get-partners') &&
            <>
                <Route path='/partners' element={<PartnerListPage/>}/>
                <Route
                    path={'/partners/:id'}
                    element={<SinglePartnerWrapper/>}
                >
                    <Route index element={<PartnerViewPage/>}/>
                    <Route path='edit'
                           element={hasPermission(user, 'update-partners') ? <PartnerEditPage/> : <PartnerViewPage/>}/>
                    <Route path='orders'
                           element={hasPermission(user, 'get-orders') ? <PartnerOrdersPage/> : <PartnerViewPage/>}/>
                    <Route path='logs'
                           element={hasPermission(user, 'get-logs') ? <PartnerLogsPage/> : <PartnerViewPage/>}/>
                </Route>
            </>
        }
        {hasPermission(user, 'get-employees') &&
            <>
            <Route path='/employees' element={<EmployeeListPage/>}/>
                <Route
                    path={'/employees/:id'}
                    element={<SingleEmployeeWrapper/>}
                >
                    <Route index element={<EmployeeViewPage/>}/>
                    <Route path='edit'
                           element={hasPermission(user, 'update-employees') ? <EmployeeEditPage/> : <EmployeeViewPage/>}/>
                </Route>
            </>
        }
    </Routes>
);

export default SettingRoutes;