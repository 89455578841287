import FormRadio from "../../../../components/Elements/FormRadio.js";
import FormInput from "../../../../components/Elements/FormInput.js";
import DateInput from "../../../../components/Elements/DateInput.js";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SearchAutoCompleteStaticComponent from "../../../../components/SearchAutoCompleteStaticComponent.js";
import {useSelector} from "react-redux";
import FormNumericInput from "../../../../components/Elements/FormNumericInput.js";
import {useEffect, useState} from "react";
import {Card} from "react-bootstrap";
import {hasPermission} from "../../../../utils/permissions.js";

const OrderFormSubcontractorComponent = (
    {
        order,
        errors,
        onChange,
        disabled = false
    }) => {
    const {user} = useSelector((state) => state.auth);
    const {data: subcontractors} = useSelector((state) => state.subcontractorList);
    const [defaultHasInvoice, setDefaultHasInvoice] = useState(true);

    useEffect(() => {
        if (!order.hasSubcontractor && !order.hasInvoice) {
            setDefaultHasInvoice(false);
        }
    }, [order.hasInvoice]);

    const handleChange = (name, value) => {
        const subcontractorAttribute = name.replace('subcontractorOrder.', '');
        const newSubcontractorOrder = {...order.subcontractorOrder};
        newSubcontractorOrder[subcontractorAttribute] = value;

        if (subcontractorAttribute === 'hasInvoice' && value === false) {
            newSubcontractorOrder['invoiceNumber'] = null;
            newSubcontractorOrder['invoiceDate'] = null;
        }
        onChange('subcontractorOrder', newSubcontractorOrder);
    }

    return (
        <Card className={'mb-3'}>
            <Card.Body>
                <FormRadio onRadioChange={() => {
                    onChange('hasSubcontractor', !order.hasSubcontractor);
                    onChange('subcontractor', null);
                    onChange('subcontractorOrder', {
                        invoiceNumber: null,
                        invoiceAmount: null,
                        invoiceDate: null,
                        isPaid: false,
                        hasInvoice: defaultHasInvoice
                    });
                }} label={'Подизпълнител'}
                           options={[{value: true, label: 'Да'}, {value: false, label: 'Не'}]}
                           selectedValue={order.hasSubcontractor === null ? false : order.hasSubcontractor}
                           inline={true}
                           name={'hasSubcontractor'} errors={errors}
                           disabled={disabled}
                />
                <div className={`mt-2 ${order.hasSubcontractor ? 'd-block' : 'd-none'}`}>
                    <Row>
                        <Col sm={12}>
                            <SearchAutoCompleteStaticComponent name={'subcontractor'} errors={errors}
                                                               items={subcontractors} item={order.subcontractor}
                                                               onItemChange={onChange} disabled={disabled}/>
                        </Col>
                    </Row>
                    <Row className={'mt-2'}>
                        <Col sm={6}>
                            <FormRadio onRadioChange={handleChange} label={'С Фактура'} inlineLabel={true}
                                       options={[{value: true, label: 'Да'}, {value: false, label: 'Не'}]}
                                       selectedValue={order.subcontractorOrder?.hasInvoice === true} inline={true}
                                       name={'subcontractorOrder.hasInvoice'} errors={errors}
                                       disabled={disabled}
                            />
                        </Col>
                        <Col sm={6}>
                            <FormRadio onRadioChange={handleChange} label={'Платена'} inlineLabel={true}
                                       options={[{value: true, label: 'Да'}, {value: false, label: 'Не'}]}
                                       selectedValue={order.subcontractorOrder?.isPaid === true} inline={true}
                                       name={'subcontractorOrder.isPaid'} errors={errors}
                                       disabled={!hasPermission(user, 'manage-subcontractor-payments') || disabled}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={6}>
                            <FormInput label={'Номер фактура'} name={'subcontractorOrder.invoiceNumber'} errors={errors}
                                       value={order.subcontractorOrder.invoiceNumber ?? ''} onChange={handleChange} disabled={!order.subcontractorOrder.hasInvoice || disabled}/>
                        </Col>
                        <Col sm={6}>
                            <DateInput name={'subcontractorOrder.invoiceDate'} label={'Дата фактура'} errors={errors}
                                       dateValue={order.subcontractorOrder.invoiceDate ?? ''}
                                       onDateValueChange={handleChange} disabled={!order.subcontractorOrder.hasInvoice || disabled}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={6}>
                            <FormNumericInput errors={errors} value={order.subcontractorOrder.invoiceAmount ?? null}
                                              label={'За заплащане'} name={'subcontractorOrder.invoiceAmount'}
                                              onChange={handleChange} disabled={disabled}/>
                        </Col>
                    </Row>
                </div>
            </Card.Body>
        </Card>
    )
}

export default OrderFormSubcontractorComponent;