import React from 'react';
import { useSelector } from 'react-redux';
import {fetchPartners, resetStateData} from '../../../slices/partnerListSlice.js';
import ListHeaderLayout from "../../../components/Layouts/ListHeaderLayout.js";
import PartnersTable from "./components/PartnersTable.js";
import ListPageContent from "../../../components/Layouts/ListPageContent.js";

const PartnerListPage = () => {
    const {data: partners, loading, pagination} = useSelector((state) => state.partnerList);

    return (
        <div>
            <ListHeaderLayout
                title={'Партньори'}
                createLink={'/settings/partners/create/edit'}
                createPermission={'create-partners'}
            />
            <ListPageContent
                resetStateData={resetStateData}
                fetchData={fetchPartners}
                loading={loading}
                TableComponent={PartnersTable}
                pagination={pagination}
                data={partners}
            />
        </div>
    );
};

export default PartnerListPage;
