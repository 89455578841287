import { createSlice } from '@reduxjs/toolkit';
import {fetchItems} from "../utils/sliceFunctions.js";
import {getCommonExtraReducersList} from "../utils/commonExtraReducers.js";
import {resetStateDataReducer, setCurrentPageReducer} from "../utils/commonReducers.js";

const initialState = {
    data: [],
    pagination: {
        currentPage: 1,
        lastPage: 1,
        totalItems: 0
    },
    loading: false,
    error: null
}
export const fetchRepairJobs = fetchItems('repairJobs/fetchRepairJobs', '/repair-jobs');

const repairJobListSlice = createSlice({
    name: 'repairJobs',
    initialState: initialState,
    reducers: {
        setCurrentPage: setCurrentPageReducer,
        resetStateData: resetStateDataReducer(initialState)
    },
    extraReducers: (builder) => {
        getCommonExtraReducersList(builder, fetchRepairJobs)
    },
});

export const {
    setCurrentPage,
    resetStateData
} = repairJobListSlice.actions

export default repairJobListSlice.reducer;