import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import React, {useEffect, useState} from "react";
import { useSelector} from "react-redux";
import LoadingComponent from "../../../../../components/LoadingComponent.js";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SearchAutoCompleteStaticComponent from "../../../../../components/SearchAutoCompleteStaticComponent.js";
import SelectInput from "../../../../../components/Elements/SelectInput.js";
import Form from "react-bootstrap/Form";
import useFetchDamageLevelsAndCarDetails from "../../../../../app/hooks/useFetchDamageLevelsAndCarDetails.js";
import {v4 as uuidv4} from "uuid";

const RepairJobLineAddModal = (
    {
        show,
        onClose,
        repairJobOrder,
        emptyRepairJobLine,
        onSubmit
    }) => {
    const {damageLevels, carDetailArticles} = useSelector((state) => state.commonData);

    const [submitDisabled, setSubmitDisabled] = useState(true);
    const [repairJobLine, setRepairJobLine] = useState({...emptyRepairJobLine});

    useFetchDamageLevelsAndCarDetails(repairJobOrder.id);

    useEffect(() => {
        setRepairJobLine((prevState) => {
            return {
                ...prevState,
                id: uuidv4()
            }
        })
    }, []);

    useEffect(() => {
        if (repairJobLine.article && repairJobLine.damageLevel) {
            setSubmitDisabled(false);
        }
    }, [repairJobLine.article, repairJobLine.damageLevel]);

    const handleSubmit = () => {
        onSubmit(repairJobOrder, repairJobLine);
    }

    const updateArticle = (attribute, article) => {
        setRepairJobLine((prevState) => {
            return {
                ...prevState,
                article: article,
                orderLineQuantityString: `1 ${article.quantityType.abbreviation}.`,
            }
        });
    }

    const updateDamageLevel = (attribute, damageLevel) => {
        setRepairJobLine((prevState) => {
            return {
                ...prevState,
                damageLevel: damageLevel
            }
        });
    }

    const showForm = () => {
        return damageLevels.length > 0 && carDetailArticles.length > 0;
    }

    return (
        <Modal show={show} onHide={onClose} animation={false} size={'lg'}>
            {showForm() ? (
                <>
                    <Modal.Header closeButton>
                        <Modal.Title>Добави позиция към поръчка № {repairJobOrder.id}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {(damageLevels.length && carDetailArticles.length) ?
                            <Form.Group className="mb-3" controlId="formHorizontalEmail">
                                <Row>
                                    <Col md={5}>
                                        <SearchAutoCompleteStaticComponent
                                            name={'article'}
                                            onItemChange={updateArticle}
                                            items={carDetailArticles}
                                            label={'Детайл'}
                                            item={repairJobLine.article}
                                        />
                                    </Col>
                                    <Col md={5}>
                                        <SelectInput
                                            label={'Степен на увреждане'}
                                            value={repairJobLine.damageLevel?.id}
                                            name={'damageLevel'}
                                            onChange={updateDamageLevel}
                                            selectOptions={damageLevels}
                                        />
                                    </Col>
                                </Row>
                            </Form.Group>
                        :
                        <LoadingComponent />
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" onClick={onClose}>Откажи</Button>
                        <Button variant="success" onClick={handleSubmit} disabled={submitDisabled}>Запази</Button>
                    </Modal.Footer>
                </>
            ) : (
                <LoadingComponent/>
            )}
        </Modal>
    )
};

export default RepairJobLineAddModal;