import {EyeFill} from "react-bootstrap-icons";
import TableLinkIconButton from "./TableLinkIconButton.js";

const TableLinkViewButton = (
    {
        to
    }) => {

    return (
        <TableLinkIconButton to={to}>
            <EyeFill/>
        </TableLinkIconButton>
    )

}

export default TableLinkViewButton;
