import {useEffect} from "react";
import SelectInput from "../../../../../components/Elements/SelectInput.js";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {getDefaultEmployeeForArticle, updateRepairJobLines} from "../../../../../slices/repairJobSlice.js";
import {useDispatch} from "react-redux";

const RepairJobEditEmployeeOccupations = (
    {
        repairJob,
        employeeOccupations,
        onUpdate
    }) => {
    const dispatch = useDispatch();

    useEffect(() => {
        const repairJobOrders = [...repairJob.repairJobOrders];
        repairJobOrders.forEach(order => {
            order.repairJobLines.forEach(repairJobLine => {
                const childLines = repairJobLine.childLines.map(childLine => {
                    const costs = childLine.repairJobLineCosts.map(cost => {
                        if (!cost.employee) {
                            const updatedCost = {...cost};
                            updatedCost.employee = getDefaultEmployeeForArticle(childLine.article, employeeOccupations, repairJob);
                            return updatedCost;
                        } else {
                            return cost;
                        }
                    })
                    return {...childLine, repairJobLineCosts: costs}
                })
                const updatedLine =  {...repairJobLine, childLines: childLines};
                dispatch(updateRepairJobLines(         {
                    repairJobOrderId: order.id,
                    repairJobLineToUpdate: updatedLine
                }))
            });
        });
    }, [repairJob.repairJobEmployeeOccupations]);

    const getEmployeeIdForOccupation = (employeeOccupation) => {
        const relation = repairJob.repairJobEmployeeOccupations.find((relation) =>
            relation.employeeOccupation.id === employeeOccupation.id
        );
        if (relation) {
            return relation.employee.id;
        }
        return '';
    }

    const updateEmployeeOccupationRelation = (employee, employeeOccupation) => {
        const relations = [...repairJob.repairJobEmployeeOccupations];

        const relationIndex = relations.findIndex(
            (relation) => relation.employeeOccupation.id === employeeOccupation.id
        );

        if (relationIndex !== -1) {
            relations[relationIndex] = {
                ...relations[relationIndex],
                employee: employee
            };
        } else {
            relations.push({
                repairJobId: repairJob.id,
                employeeOccupation: employeeOccupation,
                employee: employee,
            });
        }
        onUpdate('repairJobEmployeeOccupations', relations);
    };

    return (
                <Row>
                    {employeeOccupations.map((employeeOccupation) => (
                        <Col md={'auto'} key={`select-input-repair-job-employee-occupation-${employeeOccupation.id}`}>
                        <SelectInput
                            label={employeeOccupation.name}
                            name={`employee-occupation-select-${employeeOccupation.id}`}
                            selectOptions={employeeOccupation.employees}
                            value={getEmployeeIdForOccupation(employeeOccupation)}
                            onChange={(name, item) => updateEmployeeOccupationRelation(item, employeeOccupation)}
                        />
                        </Col>
                    ))}
                </Row>
    )
};

export default RepairJobEditEmployeeOccupations;