import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
    changeAttribute,
    fetchArticle
} from "../../../slices/articleSlice.js";
import { useParams} from "react-router-dom";
import {processIdInformation, toFloat, toFloatBgn} from "../../../utils/functions.js";
import ArticleNav from "./components/ArticleNav.js";
import SingleEditLayout from "../../../components/Layouts/SingleEditLayout.js";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {Button, Table} from "react-bootstrap";
import {fetchArticleStocksChanges} from "../../../slices/articleStockChangeListSlice.js";
import ArticleStockChangesTable from "./components/ArticleStockChangesTable.js";
import ChangeArticleStockQuantityModal from "./components/ChangeArticleStockQuantityModal.js";
import {saveArticleStocksBatch, resetStateData as resetArticleStockListData, setStatus as setArticleStockListStatus} from "../../../slices/articleStockListSlice.js";
import {setSuccessMessage} from "../../../slices/informationMessagesSlice.js";
import {getInitialArticleStockData, saveArticleStock, setData, resetStateData as resetArticleStockData, setStatus as setArticleStockStatus} from "../../../slices/articleStockSlice.js";
import EditArticleStockModal from "./components/EditArticleStockModal.js";

const ArticleStocksPage = () => {
    const {id} = useParams();
    const {idIsInteger} = processIdInformation(id);
    const dispatch = useDispatch();

    const {data: article, loading} = useSelector((state) => state.article);
    const {
        data: articleStockChanges,
        loading: articleStockChangesLoading
    } = useSelector((state) => state.articleStockChangeList);
    const {status: articleStockListSliceStatus, errors: articleStockListSliceStateErrors} = useSelector((state) => state.articleStockList);
    const {status: articleStockSliceStatus, errors: articleStockSliceStateErrors} = useSelector((state) => state.articleStock);
    const [visibleModalWindow, setVisibleModalWindow] = useState(null);
    const [articleStockInModal, setArticleStockInModal] = useState(null);
    const [title, setTitle] = useState('');

    useEffect(() => {
            dispatch(fetchArticleStocksChanges({id: id, sort: ['id', 'desc']}));
        return () => {
            dispatch(resetArticleStockData());
            dispatch(resetArticleStockListData());
        }
    }, [id, dispatch, idIsInteger]);

    useEffect(() => {
        setTitle(`Артикул № ${id}  - ${article.name}`);
    }, [article.id]);

    useEffect(() => {
        if (
            (articleStockListSliceStatus === 'saved' || articleStockSliceStatus === 'saved')
        ) {
            dispatch(setSuccessMessage('Успешно променена наличност'));
            dispatch(setArticleStockStatus('idle'));
            dispatch(setArticleStockListStatus('idle'));
            dispatch(fetchArticleStocksChanges({id: id, sort: ['id', 'desc']}));
            dispatch(fetchArticle({id: id}));
        }
    }, [articleStockSliceStateErrors, articleStockListSliceStateErrors, articleStockListSliceStatus, articleStockSliceStatus]);

    const showForm = () => {
        return !loading && !articleStockChangesLoading;
    }

    const handleSubmit = async () => {
        await dispatch(saveArticleStocksBatch({items: article.articleStocks}));
    };

    const showEditArticleStockModal = (articleStock = null) => {
        setVisibleModalWindow('editArticleStock');
        if (articleStock === null) {
            const initialArticleStockData = getInitialArticleStockData();
            initialArticleStockData.article = article;
            dispatch(setData(initialArticleStockData));
        } else {
            dispatch(setData(articleStock))
        }
    }

    const showIncreaseArticleStockModal = (articleStock) => {
        setVisibleModalWindow('increaseArticleStock');
        setArticleStockInModal(articleStock);
    }

    const showDecreaseArticleStockModal = (articleStock) => {
        setVisibleModalWindow('decreaseArticleStock');
        setArticleStockInModal(articleStock);
    }

    const closeModalWindow = () => {
        setVisibleModalWindow(null);
        setArticleStockInModal(null);
        dispatch(setData({
            ...getInitialArticleStockData(),
            article: article, // Add your existing `article` variable here
        }));
    }

    const handleArticleStockSubmit = async (articleStock) => {
        await dispatch(saveArticleStock({id: articleStock.id, item: articleStock}));
        closeModalWindow();
    }

    const handleArticleStockQuantityUpdate = (articleStock, quantity, note) => {
        const updatedArticleStocks = article.articleStocks.map((stock) => {
                const updatedArticleStock = {...stock}
                if (stock.id === articleStock.id) {
                    updatedArticleStock.stockQuantity += parseFloat(quantity);
                    updatedArticleStock.newArticleStockChanges = [
                        ...(updatedArticleStock.newArticleStockChanges || []), // Copy existing array
                        {
                            quantity: quantity,
                            stockQuantityAfterChange: toFloat(updatedArticleStock.stockQuantity, 2),
                            note: note,
                        },
                    ];
                }
                return updatedArticleStock;
            }
        );

        dispatch(changeAttribute({attribute: 'articleStocks', value: updatedArticleStocks}));
        closeModalWindow();
    }

    return (
        <>
            <SingleEditLayout
                title={title}
                showForm={showForm()}
                onSubmit={handleSubmit}
                navComponent={ArticleNav}
                navProps={{id: id, activeKey: 'stocks'}}
                stateErrors={articleStockSliceStateErrors.length ? articleStockSliceStateErrors : articleStockListSliceStateErrors}
                canSubmit={showForm}
            >
                <Row>
                    <Col md={12}>
                        <div className={'mb-2 clearfix'}>
                            <Button className={'float-end button-create'} onClick={() => showEditArticleStockModal()}>Добави Доставчик</Button>
                        </div>
                        <Table striped bordered>
                            <thead>
                            <tr>
                                <th>Доставчик</th>
                                <th>Наличност</th>
                                <th>Покупна цена с ДДС</th>
                                <th>Редактирай</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            {article.articleStocks.map((articleStock) => (
                                <tr key={`article-stock-${articleStock.id}`}>
                                    <td>{articleStock.supplier.name}</td>
                                    <td>{articleStock.stockQuantity} {article.quantityType.abbreviation}</td>
                                    <td>{toFloatBgn(articleStock.costPrice)}</td>
                                    <td><Button variant={'success'}
                                                onClick={() => showEditArticleStockModal(articleStock)}>Редактирай</Button>
                                    </td>
                                    <td>
                                        <Button variant={'primary'}
                                                onClick={() => showIncreaseArticleStockModal(articleStock)}
                                                className={'mb-2'}>+ Добави наличност</Button>
                                        <br/>
                                        <Button variant={'danger'}
                                                onClick={() => showDecreaseArticleStockModal(articleStock)}>- Премахни
                                            наличност</Button>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </Table>

                    </Col>
                </Row>
            </SingleEditLayout>
            <ArticleStockChangesTable articleStockChanges={articleStockChanges}/>
            { (visibleModalWindow === 'increaseArticleStock' || visibleModalWindow === 'decreaseArticleStock') ? (
                <ChangeArticleStockQuantityModal
                    article={article}
                    articleStock={articleStockInModal}
                    isIncrease={visibleModalWindow === 'increaseArticleStock'}
                    onClose={closeModalWindow}
                    onUpdate={handleArticleStockQuantityUpdate}
                />
                ) : (visibleModalWindow === 'editArticleStock') ? (
                <EditArticleStockModal
                    onClose={closeModalWindow}
                    onSubmit={handleArticleStockSubmit}
                    article={article}
                />
            ) : <></>}
        </>
    );
};

export default ArticleStocksPage;
