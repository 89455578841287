import {useDispatch, useSelector} from "react-redux";
import {setRepairJobStatuses} from "../../slices/commonDataSlice.js";
import {useEffect} from "react";
import apiClient from "../../axios.js";

const useFetchRepairJobStatuses  = (
    {
        dependence = null
    } = {}
) => {
    const dispatch = useDispatch();
    const {repairJobStatuses} = useSelector((state) => state.commonData);

    useEffect(() => {
        const fetchRepairJobStatuses = async () => {
            if (!repairJobStatuses.length) {
                const repairJobStatusesResponse = await apiClient.get(
                    '/repair-job-statuses'
                );
                dispatch(setRepairJobStatuses(repairJobStatusesResponse.data.data));
            }
        }

        fetchRepairJobStatuses();
    }, [dispatch, dependence]);
};

export default useFetchRepairJobStatuses;