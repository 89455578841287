import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../slices/authSlice';
import orderListReducer from '../slices/orderListSlice.js';
import orderReducer from '../slices/orderSlice.js';
import orderStatusListReducer from '../slices/orderStatusListSlice.js';
import quantityTypeListReducer from '../slices/quantityTypeSlice.js';
import articleListReducer from '../slices/articleListSlice.js';
import articleReducer from '../slices/articleSlice.js';
import carListReducer from '../slices/carListSlice.js';
import carReducer from '../slices/carSlice.js';
import clientListReducer from '../slices/clientListSlice.js';
import clientTypeListReducer from '../slices/clientTypeListSlice.js';
import clientReducer from '../slices/clientSlice.js';
import userReducer from '../slices/userSlice.js';
import userListReducer from '../slices/userListSlice.js';
import roleListReducer from '../slices/roleListSlice.js';
import roleReducer from '../slices/roleSlice.js';
import carManufacturerListReducer from '../slices/carManufacturerListSlice.js';
import carManufacturerReducer from '../slices/carManufacturerSlice.js';
import carModelListReducer from '../slices/carModelListSlice.js';
import carModelReducer from '../slices/carModelSlice.js';
import paymentTypeListReducer from '../slices/paymentTypeListSlice.js';
import partnersListReducer from '../slices/partnerListSlice.js';
import partnerReducer from '../slices/partnerSlice.js';
import repairJobsListReducer from '../slices/repairJobListSlice.js';
import repairJobReducer from '../slices/repairJobSlice.js';
import contractorsListReducer from '../slices/contractorListSlice.js';
import subcontractorsListReducer from '../slices/subcontractorListSlice.js';
import fileReducer from '../slices/fileSlice.js';
import orderLineTypeListReducer from '../slices/orderLineTypeListSlice.js';
import appSettingsReducer from '../slices/appSettingsSlice.js'
import damageLevelListReducer from '../slices/damageLevelListSlice.js'
import articleTypeListReducer from '../slices/articleTypeListSlice.js'
import articleStockChangeListReducer from '../slices/articleStockChangeListSlice.js';
import articleStockListReducer from '../slices/articleStockListSlice.js';
import informationMessagesReducer from '../slices/informationMessagesSlice.js';
import supplierListReducer from '../slices/supplierListSlice.js';
import articleStockReducer from '../slices/articleStockSlice.js';
import supplierReducer from '../slices/supplierSlice.js';
import insurerListReducer from '../slices/insurertListSlice.js';
import homeListReducer from '../slices/homeListSlice.js';
import fileListReducer from '../slices/fileListSlice.js';
import commonDataReducer from '../slices/commonDataSlice.js';
import employeeListReducer from '../slices/employeeListSlice.js';
import employeeOccupationListReducer from '../slices/employeeOccupationListSlice.js';
import employeeReducer from '../slices/employeeSlice.js';

export default configureStore({
    reducer: {
        auth: authReducer,
        orderList: orderListReducer,
        order: orderReducer,
        orderStatusList: orderStatusListReducer,
        orderLineTypeList: orderLineTypeListReducer,
        paymentTypeList: paymentTypeListReducer,
        employeeList: employeeListReducer,
        employee: employeeReducer,
        employeeOccupationList: employeeOccupationListReducer,
        partnerList: partnersListReducer,
        partner: partnerReducer,
        contractorList: contractorsListReducer,
        subcontractorList: subcontractorsListReducer,
        quantityTypeStatusList: quantityTypeListReducer,
        articleList: articleListReducer,
        article: articleReducer,
        carList: carListReducer,
        car: carReducer,
        carManufacturerList: carManufacturerListReducer,
        carManufacturer: carManufacturerReducer,
        carModelList: carModelListReducer,
        carModel: carModelReducer,
        clientList: clientListReducer,
        client: clientReducer,
        clientTypeList: clientTypeListReducer,
        userList: userListReducer,
        user: userReducer,
        roleList: roleListReducer,
        role: roleReducer,
        file: fileReducer,
        appSettings: appSettingsReducer,
        damageLevelList: damageLevelListReducer,
        articleTypeList: articleTypeListReducer,
        articleStockChangeList: articleStockChangeListReducer,
        articleStock: articleStockReducer,
        articleStockList: articleStockListReducer,
        informationMessages: informationMessagesReducer,
        supplierList: supplierListReducer,
        supplier: supplierReducer,
        insurerList: insurerListReducer,
        homeList: homeListReducer,
        fileList: fileListReducer,
        commonData: commonDataReducer,
        repairJob: repairJobReducer,
        repairJobList: repairJobsListReducer
    },
})