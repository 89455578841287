import { createSlice } from '@reduxjs/toolkit';
import {fetchItems} from "../utils/sliceFunctions.js";
import {getCommonExtraReducersList} from "../utils/commonExtraReducers.js";
import {resetStateDataReducer, setCurrentPageReducer} from "../utils/commonReducers.js";

const initialState = {
    data: [],
    pagination: {
        currentPage: 1,
        lastPage: 1,
        totalItems: 0
    },
    loading: false,
    error: null
}
export const fetchEmployeeOccupations = fetchItems('employeeOccupations/fetchEmployeeOccupations', '/employee-occupations');

const employeeOccupationListSlice = createSlice({
    name: 'employeeOccupations',
    initialState: initialState,
    reducers: {
        setCurrentPage: setCurrentPageReducer,
        resetStateData: resetStateDataReducer(initialState)
    },
    extraReducers: (builder) => {
        getCommonExtraReducersList(builder, fetchEmployeeOccupations)
    },
});

export const {
    setCurrentPage,
    resetStateData
} = employeeOccupationListSlice.actions

export default employeeOccupationListSlice.reducer;