import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import LoadingComponent from "../../../../../components/LoadingComponent.js";
import Modal from "react-bootstrap/Modal";
import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import apiClient from "../../../../../axios.js";
import {setRepairJobChildLineArticles} from "../../../../../slices/commonDataSlice.js";
import {v4 as uuidv4} from "uuid";
import RepairJobLineEditArticlesCheckboxes from "./RepairJobLineEditArticlesCheckboxes.js";
import RepairJobLineEditChildrenTable from "./RepairJobLineEditChildrenTable.js";
import Button from "react-bootstrap/Button";
import {getActiveCosts, getActiveLines, getDefaultEmployeeForArticle} from "../../../../../slices/repairJobSlice.js";

const RepairJobLineEditModal = (
    {
        show,
        onClose,
        repairJobLineToEdit,
        emptyRepairJobLine,
        onSubmit
    }
) => {
    const dispatch = useDispatch();

    const {repairJobChildLineArticles} = useSelector((state) => state.commonData);
    const {data: employeeOccupations} = useSelector((state) => state.employeeOccupationList);
    const {data: repairJob} = useSelector((state) => state.repairJob);

    const [repairJobLine, setRepairJobLine] = useState({...repairJobLineToEdit});
    const [saveButtonDisabled, setSaveButtonDisabled] = useState(false);
    const [errors, setErrors] = useState([]);

    useEffect(() => {

        const fetchRepairJobChildLineArticles = async () => {
            if (!repairJobChildLineArticles.length) {
                const repairJobChildLineArticlesResponse = await apiClient.get(
                    '/articles/repair-job-child-line-articles'
                );
                dispatch(setRepairJobChildLineArticles(repairJobChildLineArticlesResponse.data.data));
            }
        }

        fetchRepairJobChildLineArticles();
    }, []);


    const addRepairJobChildLine = (article) => {
        const newLine = {...emptyRepairJobLine};
        newLine.article = article;
        newLine.id = uuidv4();
        newLine.repairJobLineCosts = [getNewCost(article)];
        
        setRepairJobLine((prev) => {
            return {
                ...prev,
                childLines: [...prev.childLines, newLine]
            }
        });
    }

    const deleteRepairJobChildLine = (repairJobChildLineToUpdate) => {
        setRepairJobLine((prev) => {
            const updatedChildLines = prev.childLines.map((childLine) => {
                if (childLine.id === repairJobChildLineToUpdate.id) {
                    if (Number.isInteger(childLine.id)) {
                        return {
                            ...childLine,
                            isActive: false
                        };
                    }
                    return null; // Mark for removal
                }
                return childLine;
            }).filter(childLine => childLine !== null)

            return {
                ...prev,
                childLines: updatedChildLines
            };
        });
    }

    const getNewCost = (article) => {
        return {
            id: uuidv4(),
            quantity: 0,
            price: 0,
            note: null,
            employee: getDefaultEmployeeForArticle(article, employeeOccupations, repairJob),
            isActive: true
        };
    }

    const addRepairJobLineCost = (repairJobChildLineToUpdate) => {
        const newCost = getNewCost(repairJobChildLineToUpdate.article);

        setRepairJobLine((prev) => {
            const updatedChildLines = prev.childLines.map((childLine) => {
                if (childLine.id === repairJobChildLineToUpdate.id) {
                    return {
                        ...childLine,
                        repairJobLineCosts: [...childLine.repairJobLineCosts, newCost]
                    };
                }
                return childLine;
            });
            return {
                ...prev,
                childLines: updatedChildLines
            }
        })
    }

    const updateRepairJobLineCost = (repairJobChildLineToUpdate, costToUpdate) => {
        setRepairJobLine((prev) => {
            const updatedChildLines = prev.childLines.map((childLine) => {
                if (childLine.id === repairJobChildLineToUpdate.id) {
                    return {
                        ...childLine,
                        repairJobLineCosts: childLine.repairJobLineCosts.filter((cost) => {
                            if (cost.id === costToUpdate.id) {
                                return !(costToUpdate.isActive === false && !Number.isInteger(costToUpdate.id));
                            }
                            return true;
                        }).map((cost) => cost.id === costToUpdate.id ? costToUpdate : cost)
                    };
                }
                return childLine;
            });

            return {
                ...prev,
                childLines: updatedChildLines
            };
        });
    }

    const handleSubmit = async () => {
        try {
            setSaveButtonDisabled(true);
            setErrors([]);
            validateCosts();
            onSubmit(repairJobLine);
            onClose();
            setSaveButtonDisabled(false);
        } catch (validationErrors) {
            setSaveButtonDisabled(false);
        }
    }

    const validateCosts = () => {
        let errors = [];
        getActiveLines(repairJobLine.childLines).forEach(childLine => {
            getActiveCosts(childLine.repairJobLineCosts).forEach(cost => {
                if (!cost.price || !cost.quantity) {
                    errors.push({
                        costId: cost.id,
                        message: 'Количество и Цена са задължителни стойности'
                    })
                }
            })
        })

        if (errors.length > 0) {
            setErrors(errors);
            throw new Error();
        }
    }

    const showForm = () => {
        return repairJobChildLineArticles.length > 0 && repairJobLine;
    }

    return (
        <Modal show={show} onHide={onClose} animation={false} size={'xl'}>
            {showForm() ? (
                <>
                    <Modal.Header closeButton>
                        <Modal.Title>Редактирай заработки - {repairJobLine.article.name}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row className={'mt-2'}>
                            <Col md={12}>
                                <Row>
                                    <RepairJobLineEditArticlesCheckboxes
                                        repairJobLine={repairJobLine}
                                        repairJobLineArticles={repairJobChildLineArticles}
                                        onUpdate={(article) => addRepairJobChildLine(article)}/>
                                </Row>
                            </Col>
                        </Row>
                        <Row className={'mt-2'}>
                            <Col md={12}>
                                <RepairJobLineEditChildrenTable
                                    repairJobLine={repairJobLine}
                                    onAddCost={addRepairJobLineCost}
                                    onUpdateCost={updateRepairJobLineCost}
                                    onDelete={deleteRepairJobChildLine}
                                    errors={errors}
                                />
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" onClick={() => onClose()}>Откажи</Button>
                        <Button variant="success" onClick={() => handleSubmit()} disabled={saveButtonDisabled}>Запази</Button>
                    </Modal.Footer>
                </>
            ) : (
                <LoadingComponent/>
            )}
        </Modal>
    )
};

export default RepairJobLineEditModal;