import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import LoadingComponent from "../../../../../components/LoadingComponent.js";
import Modal from "react-bootstrap/Modal";
import React, {useEffect, useState} from "react";
import Button from "react-bootstrap/Button";
import { Card} from "react-bootstrap";
import RepairJobChildLineView from "../View/RepairJobChildLineView.js";
import {getActiveLines} from "../../../../../slices/repairJobSlice.js";

const RepairJobOrderDeleteModal = (
    {
        show,
        onClose,
        repairJobOrderToDelete,
        onSubmit
    }
) => {
    const [repairJobOrder, setRepairJobOrder] = useState({...repairJobOrderToDelete});

    useEffect(() => {
        if (!repairJobOrder.isActive) {
            onClose();
            onSubmit(repairJobOrder);
        }
    }, [repairJobOrder.isActive]);

    const handleSubmit = () => {
        setRepairJobOrder((prev) => {
            return {
                ...prev,
                isActive: false
            }
        })
    }

    const showForm = () => {
        return repairJobOrder;
    }

    return (
        <Modal show={show} onHide={onClose} animation={false} size={'xl'}>
            {showForm() ? (
                <>
                    <Modal.Header closeButton>
                        <Modal.Title>Премахни поръчка № {repairJobOrder.id}?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p className={'mb-2'}>Ще бъде изтрита следната информация:</p>
                        <Row>
                            {getActiveLines(repairJobOrder.repairJobLines).map((repairJobLine) => (
                            <Col md={12} key={`repair-job-line-order-delete-${repairJobLine.id}`} className={'mb-3'}>
                                <Card>
                                    <Card.Header>
                                        <h5>{repairJobLine.article.name}</h5>
                                    </Card.Header>
                                    <Card.Body>
                                        {repairJobLine.childLines.map((childLine) => (
                                            <RepairJobChildLineView childLine={childLine} key={`repair-job-child-line-order-delete-${childLine.id}`} />
                                        ))}
                                    </Card.Body>
                                </Card>
                            </Col>
                            ))}
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" onClick={() => onClose()}>Откажи</Button>
                        <Button variant="success" onClick={() => handleSubmit()}>Премахни</Button>
                    </Modal.Footer>
                </>
            ) : (
                <LoadingComponent/>
            )}
        </Modal>
    )
};

export default RepairJobOrderDeleteModal;