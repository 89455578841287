import React from 'react';
import {  useSelector } from 'react-redux';
import {fetchCars, resetStateData} from "../../../slices/carListSlice.js";
import ListHeaderLayout from "../../../components/Layouts/ListHeaderLayout.js";
import CarsTable from "./components/CarsTable.js";
import ListPageContent from "../../../components/Layouts/ListPageContent.js";

const CarListPage = () => {
    const {data: cars, loading, pagination} = useSelector((state) => state.carList);

    return (
        <div>
            <ListHeaderLayout
                title={'Коли'}
                createLink={'/cars/create/edit'}
                createPermission={'create-cars'}
            />

            <ListPageContent
                resetStateData={resetStateData}
                fetchData={fetchCars}
                loading={loading}
                TableComponent={CarsTable}
                pagination={pagination}
                data={cars}
                FiltersComponent={null}
            />

        </div>
    );
};

export default CarListPage;
