import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FormInput from "../../../../components/Elements/FormInput.js";
import React from "react";
import FormCheckbox from "../../../../components/Elements/FormCheckbox.js";

const EmployeeForm = (
    {
        employee,
        onAttributeChange,
        errors,
        employeeOccupations
    }) => {

    const employeeHasOccupation = (employeeOccupationCheck) => {
        return employee.employeeOccupations.some(
            employeeOccupation => employeeOccupation.id === employeeOccupationCheck.id
        );
    }

    const toggleEmployeeOccupation = (name, value) => {
        const employeeOccupationId = parseInt(name.split("-")[1]);
        const employeeOccupationToToggle = employeeOccupations.find(
            employeeOccupation => employeeOccupation.id === employeeOccupationId
        );
        let tempEmployeeOccupations = [...employee.employeeOccupations];

        if (value) {
            if (!tempEmployeeOccupations.some(occ => occ.id === employeeOccupationId)) {
                tempEmployeeOccupations.push(employeeOccupationToToggle);
            }
        } else {
            tempEmployeeOccupations = tempEmployeeOccupations.filter(
                occ => occ.id !== employeeOccupationId
            );
        }

        onAttributeChange('employeeOccupations', tempEmployeeOccupations);
    }

    return (
        <Form>
            <Form.Group>
                <Row>
                    <Col md={3}>
                        <FormInput label="Име" name="name" value={employee.name}
                                   onChange={onAttributeChange} errors={errors}/>
                    </Col>
                </Row>
                <Row>
                    <Col md={3}>
                        <FormCheckbox
                            name={'isActive'}
                            label={'Активен'}
                            isChecked={employee.isActive}
                            onCheckboxChange={onAttributeChange}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <h4>Длъжности</h4>
                    </Col>
                        {employeeOccupations.map(employeeOccupation => (
                            <Col md={'auto'} key={`employee-form-employee-occupation-${employeeOccupation.id}`}>
                            <FormCheckbox
                                name={`employeeOccupationId-${employeeOccupation.id}`}
                                label={employeeOccupation.name}
                                isChecked={employeeHasOccupation(employeeOccupation)}
                                onCheckboxChange={toggleEmployeeOccupation}
                            />
                            </Col>
                        ))}
                </Row>
            </Form.Group>
        </Form>
    );
};

export default EmployeeForm;