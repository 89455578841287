import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
    addOrdersRepairJob, isRepairJobEditLock,
    saveRepairJob,
    setStatus
} from "../../../slices/repairJobSlice.js";
import {useParams, useNavigate} from "react-router-dom";
import {processIdInformation, processSubmit} from "../../../utils/functions.js";
import {getRepairJobSchema} from "../../../utils/schemas.js";
import RepairJobNav from "./components/RepairJobNav.js";
import SingleEditLayout from "../../../components/Layouts/SingleEditLayout.js";
import {setSuccessMessage} from "../../../slices/informationMessagesSlice.js";
import RepairJobOrderAdd from "./components/Edit/RepairJobOrderAdd.js";
import RepairJobLockNote from "./components/Edit/RepairJobLockNote.js";

const RepairJobOrdersPage = () => {
    const {id} = useParams();
    const {idIsInteger} = processIdInformation(id);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const {data: repairJob, loading, errors: stateErrors, status} = useSelector((state) => state.repairJob);
    const {user} = useSelector((state) => state.auth);

    const [title, setTitle] = useState('');
    const [hasNewOrdersAdded, setHasNewOrdersAdded] = useState(false);

    useEffect(() => {
        setTitle(idIsInteger ? `Сервизна заявка № ${id}` : 'Нова сервизна заявка');
    }, [id]);

    useEffect(() => {
        if (status === 'saved') {
            dispatch(setSuccessMessage(`Успешно запазена сервизна заявка - ${repairJob.id}`));
            dispatch(setStatus('idle'));
            navigate(`/repair-jobs/${repairJob.id}/edit`);
        }
    }, [status]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (idIsInteger) {
            await processSubmit(null, getRepairJobSchema(repairJob), addOrdersRepairJob, dispatch, repairJob);
        } else {
            await processSubmit(null, getRepairJobSchema(repairJob), saveRepairJob, dispatch, repairJob);
        }
    };

    const showForm = () => {
        //fix so that date is not set if user comes from editing another repairJob
        return !loading &&
            ((id === 'create' && !repairJob.id) || (parseInt(id) === repairJob.id));
    }

    return (
        <SingleEditLayout
            title={title}
            showForm={showForm()}
            onSubmit={handleSubmit}
            stateErrors={stateErrors}
            navComponent={RepairJobNav}
            navProps={{id: id, activeKey: 'orders'}}
            canSubmit={!isRepairJobEditLock(repairJob, user) && hasNewOrdersAdded}
        >
            <RepairJobLockNote repairJob={repairJob} />
            <RepairJobOrderAdd onNewOrdersAdded={(newOrdersAdded) => setHasNewOrdersAdded(newOrdersAdded)} />
        </SingleEditLayout>
    );
};

export default RepairJobOrdersPage;
