import {Nav} from "react-bootstrap";
import React from "react";
import {Link} from "react-router-dom";
import {hasPermission} from "../../../../utils/permissions.js";
import {useSelector} from "react-redux";

const OrderNav = ({
    id,
    activeKey
                           }) => {
    const { user } = useSelector((state) => state.auth);

    return (
        <Nav variant="tabs" activeKey={activeKey} defaultActiveKey="view" className={'clearfix'}>
            {id !== 'create' && (

                <Nav.Item>
                <Nav.Link as={Link} eventKey={'view'} to={`/orders/${id}`}>Преглед</Nav.Link>
            </Nav.Item>
            )}

            <Nav.Item>
                <Nav.Link as={Link} eventKey={'edit'} to={`/orders/${id}/edit`}>Редактирай</Nav.Link>
            </Nav.Item>
            {id !== 'create' && (

                <Nav.Item>
                <Nav.Link as={Link} eventKey={'files'} to={`/orders/${id}/files`}>Файлове</Nav.Link>
            </Nav.Item>
            )}
            {(id !== 'create' && (hasPermission(user, 'get-logs'))) && (
                <Nav.Item>
                    <Nav.Link as={Link} eventKey={'logs'} to={`/orders/${id}/logs`}>Логове</Nav.Link>
                </Nav.Item>
            )}
        </Nav>
    )
}

export default OrderNav;