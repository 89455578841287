import DateInput from "../../../../../components/Elements/DateInput.js";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FormInput from "../../../../../components/Elements/FormInput.js";
import React from "react";
import PaymentDueDateInput from "./PaymentDueDateInput.js";
import FormRadio from "../../../../../components/Elements/FormRadio.js";
import HelpText from "../../../../../components/Elements/HelpText.js";

const InvoiceData = (
    {
        order,
        errors,
        onAttributeChange,
        disabled = false,
    }
) => {

    const handleHasInvoiceChange = (name, value) => {
        onAttributeChange('invoiceDate', '');
        onAttributeChange('paymentDueDate', '');
        onAttributeChange('invoiceNumber', '')
        onAttributeChange(name, value);
    }

    return (
        <>
            <Row>
                <FormRadio label={'С фактура'} errors={errors} onRadioChange={handleHasInvoiceChange}
                           name={'hasInvoice'} options={[{value: true, label: 'Да'}, {value: false, label: 'Не'}]}
                           selectedValue={order.hasInvoice !== null ? order.hasInvoice : ''} inline={true}
                           disabled={order.orderStatus?.settings.needsInvoiceData || disabled}
                />
                {order.orderStatus?.settings.needsInvoiceData &&
                    <HelpText>Фактурирана или платена поръчка!</HelpText>
                }
            </Row>
            <Row>
                <Col md={6}>
                    <DateInput label={'Дата фактура'} dateValue={order.invoiceDate} name={'invoiceDate'}
                               onDateValueChange={onAttributeChange} errors={errors} disabled={!order.hasInvoice || disabled}/>
                </Col>
                <Col md={6}>
                    <PaymentDueDateInput paymentDueDate={order.paymentDueDate} errors={errors}
                                         onAttributeChange={onAttributeChange} disabled={!order.hasInvoice || disabled}/>
                </Col>
                <Col md={6}>
                    <FormInput errors={errors} onChange={onAttributeChange} name={'invoiceNumber'}
                               value={order.invoiceNumber}
                               label={'Номер фактура'}
                               disabled={!order.hasInvoice || disabled}
                    />
                </Col>
            </Row>
        </>
    )
};

export default InvoiceData;