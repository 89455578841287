import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams, useNavigate} from "react-router-dom";
import {changeAttribute, setStatus, saveEmployee} from "../../../slices/employeeSlice.js";
import {processIdInformation, processSubmit} from "../../../utils/functions.js";
import EmployeeForm from "./components/EmployeeForm.js";
import {getValidationSchema} from "../../../utils/validation.js";
import SingleEditLayout from "../../../components/Layouts/SingleEditLayout.js";
import EmployeeNav from "./components/EmployeeNav.js";
import {setSuccessMessage} from "../../../slices/informationMessagesSlice.js";
import {fetchEmployeeOccupations} from "../../../slices/employeeOccupationListSlice.js";

const EmployeeEditPage = () => {
    const {id} = useParams();
    const {idIsInteger, isValidId} = processIdInformation(id);

    const dispatch = useDispatch();
    const {data: employee, loading, errors: stateErrors, status} = useSelector((state) => state.employee);
    const navigate = useNavigate();
    const [errors, setErrors] = useState({});
    const [title, setTitle] = useState('');

    const {data: employeeOccupations} = useSelector((state) => state.employeeOccupationList);

    useEffect(() => {
        dispatch(fetchEmployeeOccupations({skip: ['articles', 'employees']}));
    }, []);

    useEffect(() => {
        setTitle( idIsInteger ? `Служител № ${id} - ${employee.name}` : 'Нов служител');
    }, [employee.id]);

    const employeeSchema = getValidationSchema({
        name: ['required', ['max', 50], ['min', 2]]
    })

    useEffect(() => {
        if ((status === 'saved' && !stateErrors.length) || !isValidId) {
            dispatch(setSuccessMessage(`Успешно запазен служител ${employee.name}`));
            dispatch(setStatus('idle'));
            dispatch(fetchEmployeeOccupations());
            navigate(`/settings/employees/${employee.id}`);
        }
    }, [navigate, stateErrors, status, dispatch, isValidId]);

    const handleAttributeChange = (attribute, value) => {
        dispatch(changeAttribute({attribute, value}));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        await processSubmit(setErrors, employeeSchema, saveEmployee, dispatch, employee);
    };

    const showForm = () => {
        return !loading && employeeOccupations.length > 0;
    }

    return (
        <SingleEditLayout
            title={title}
            showForm={showForm()}
            onSubmit={handleSubmit}
            stateErrors={stateErrors}
            navComponent={EmployeeNav}
            navProps={{id: id, activeKey: 'edit'}}
        >
            <EmployeeForm
                employee={employee}
                errors={errors}
                onAttributeChange={handleAttributeChange}
                employeeOccupations={employeeOccupations}
            />

        </SingleEditLayout>);
};

export default EmployeeEditPage;
