import { createSlice } from '@reduxjs/toolkit';
import {fetchItems} from "../utils/sliceFunctions.js";
import {getCommonExtraReducersList} from "../utils/commonExtraReducers.js";
import {resetStateDataReducer, setCurrentPageReducer} from "../utils/commonReducers.js";

const initialState = {
    data: [],
    pagination: {
        currentPage: 1,
        lastPage: 1,
        totalItems: 0
    },
    loading: false,
    error: null
}
export const fetchEmployees = fetchItems('employees/fetchEmployees', '/employees');
export const fetchActiveEmployees = fetchItems('employees/fetchActiveEmployees', '/employees/active')
;
const employeeListSlice = createSlice({
    name: 'employees',
    initialState: initialState,
    reducers: {
        setCurrentPage: setCurrentPageReducer,
        resetStateData: resetStateDataReducer(initialState)
    },
    extraReducers: (builder) => {
        getCommonExtraReducersList(builder, fetchEmployees)
        getCommonExtraReducersList(builder, fetchActiveEmployees)
    },
});

export const {
    setCurrentPage,
    resetStateData
} = employeeListSlice.actions

export default employeeListSlice.reducer;