import React from 'react';
import { Form } from 'react-bootstrap';

const FormRadio = (
    {
        options,
        selectedValue,
        onRadioChange,
        name,
        label,
        errors = {},
        inline = false,
        disabled = false,
        inlineLabel = false
    }) => {

    const handleRadioChange = ({target: {value}}) => {
        if (value === 'true' || value === 'false') {
            onRadioChange(name, JSON.parse(value));
        } else {
            onRadioChange(name, parseInt(value));
        }
    };

    return (
        <Form.Group>
            <div className={inlineLabel ? 'd-inline me-2' : ''}>
                {label && <Form.Label>{label}</Form.Label>}
            </div>
            <div className={inlineLabel ? 'd-inline' : ''}>
                {options.map((option) => (
                    <Form.Check
                        key={option.value}
                        id={`radio-${name}-${option.value}`}
                        name={name}
                        type="radio"
                        label={option.label}
                        value={option.value}
                        checked={selectedValue === option.value}
                        onChange={handleRadioChange}
                        inline={inline} // Add inline support
                        disabled={disabled}
                    />
                ))}
            </div>
            <Form.Control
                type="hidden"
                isInvalid={!!errors[name]}
                value={selectedValue}
            />
            <Form.Control.Feedback type="invalid">
                {errors[name]}
            </Form.Control.Feedback>
        </Form.Group>
    );
};

export default FormRadio;
