import Modal from "react-bootstrap/Modal";
import React, {useEffect, useState} from "react";
import apiClient from "../../../../axios.js";
import CommentsTable from "../../../../components/CommentsTable.js";
import LoadingComponent from "../../../../components/LoadingComponent.js";
import RepairJobSummaryLines from "../../Single/components/View/RepairJobSummaryLines.js";
import BorderedPageSection from "../../../../components/BorderedPageSection.js";
import RepairJobTotalView from "../../Single/components/View/RepairJobTotalView.js";

const RepairJobDetailsTableModal = (
    {
        show,
        onClose,
        listRepairJob
    }
) => {
    const [comments, setComments] = useState([]);
    const [commentsFetched, setCommentsFetched] = useState(false);
    const [repairJob, setRepairJob] = useState(null);
    const commentsEndPoint = `/repair-jobs/${listRepairJob.id}/comments?perPage=10`;
    const repairJobEndPoint = `/repair-jobs/${listRepairJob.id}`;

    useEffect(() => {
        const fetchRepairJob = async () => {
            const repairJobResponse = await apiClient.get(repairJobEndPoint);
            setRepairJob(repairJobResponse.data.data);
        }

        fetchRepairJob();
    }, [listRepairJob]);

    const fetchComments = async () => {
        const commentsResponse = await apiClient.get(commentsEndPoint);
        setComments(commentsResponse.data.data);
        setCommentsFetched(true);
    }

    useEffect(() => {
        if (listRepairJob.id) {
            fetchComments();
        }
    }, [listRepairJob.id]);

    return (
        <Modal show={show} onHide={onClose} animation={false} size={'xl'}>
            <Modal.Header closeButton>
                <Modal.Title>Заявка № {listRepairJob.id} - {listRepairJob.client?.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                    {repairJob ?
                        <>
                            <BorderedPageSection>
                                <RepairJobTotalView repairJob={repairJob} />
                            </BorderedPageSection>
                            <RepairJobSummaryLines repairJob={repairJob} />
                        </>
                        :
                        <LoadingComponent />
                    }

                <div>
                    <b>Коментари (последни 10):</b>
                    {commentsFetched ?
                        <CommentsTable data={comments}/>
                        :
                        <LoadingComponent/>
                    }
                </div>
            </Modal.Body>
            <Modal.Footer>
            </Modal.Footer>
        </Modal>
    )
};

export default RepairJobDetailsTableModal;