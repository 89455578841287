import ButtonLink from "../Elements/ButtonLink.js";
import React, {useEffect} from "react";
import SearchQueryInputAutoComponent from "../SearchQueryInputAutoComponent.js";
import {useDispatch, useSelector} from "react-redux";
import {setBrowserTabTitle} from "../../slices/informationMessagesSlice.js";
import {hasPermission} from "../../utils/permissions.js";

const ListHeaderLayout = (
    {
        title,
        createLink = '',
        onQueryChange = null,
        query = '',
        createPermission = ''
    }) => {
    const {user} = useSelector((state) => state.auth);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setBrowserTabTitle(title));
    }, [title]);

    return (
        <>
            <h2>{title}</h2>
            {createLink && (!createPermission || hasPermission(user, createPermission)) &&
                <div className="d-flex justify-content-end">
                    <ButtonLink to={createLink} isCreate={true}>Добави</ButtonLink>
                </div>
            }
            {onQueryChange &&
                <div>
                    <SearchQueryInputAutoComponent query={query} onQueryChange={onQueryChange}/>
                </div>
            }
        </>
    )
}

export default ListHeaderLayout;