import { createSlice } from '@reduxjs/toolkit';
import {fetchItems} from "../utils/sliceFunctions.js";
import {getCommonExtraReducersList} from "../utils/commonExtraReducers.js";
import {resetStateDataReducer, setCurrentPageReducer} from "../utils/commonReducers.js";

const initialState = {
    data: [],
    pagination: {
        currentPage: 1,
        lastPage: 1,
        totalItems: 0
    },
    loading: false,
    errors: []
};

export const fetchOrders = fetchItems('orders/fetchOrders', '/orders');

export const fetchArticleOrders = fetchItems(
    'orders/fetchArticleOrders', '/orders/article/[ID_PLACEHOLDER]'
);

export const fetchClientOrders = fetchItems(
    'orders/fetchClientOrders', '/orders/client/[ID_PLACEHOLDER]'
);

export const fetchSubcontractorOrders = fetchItems(
    'orders/fetchSubcontractorOrders', '/orders/subcontractor/[ID_PLACEHOLDER]'
);

export const fetchContractorOrders = fetchItems(
    'orders/fetchContractorOrders', '/orders/contractor/[ID_PLACEHOLDER]'
);
export const fetchCarOrders = fetchItems(
    'orders/fetchCarOrders', '/orders/car/[ID_PLACEHOLDER]'
);


const orderListSlice = createSlice({
    name: 'orders',
    initialState: initialState,
    reducers: {
        setCurrentPage: setCurrentPageReducer,
        resetStateData: resetStateDataReducer(initialState),
    },
    extraReducers: (builder) => {
        getCommonExtraReducersList(builder, fetchOrders)
        getCommonExtraReducersList(builder, fetchArticleOrders)
        getCommonExtraReducersList(builder, fetchClientOrders)
        getCommonExtraReducersList(builder, fetchCarOrders)
        getCommonExtraReducersList(builder, fetchSubcontractorOrders)
        getCommonExtraReducersList(builder, fetchContractorOrders)
    },
});

export const {
    setCurrentPage,
    resetStateData
} = orderListSlice.actions;


export default orderListSlice.reducer;