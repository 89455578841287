import StatusViewSection from "../../../../../components/StatusViewSection.js";
import {toFloatBgn} from "../../../../../utils/functions.js";
import {getRepairJobTotal} from "../../../../../slices/repairJobSlice.js";
import React from "react";

const RepairJobTotalView = ({
    repairJob
}) => {
    return (
        <StatusViewSection label={'Обща стойност'} value={toFloatBgn(getRepairJobTotal(repairJob))} background={'bg-light'} />

    )
}

export default RepairJobTotalView;