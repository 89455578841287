import React from 'react';
import { useSelector } from 'react-redux';
import {fetchRepairJobs, resetStateData} from "../../../slices/repairJobListSlice.js";
import RepairJobsTable from "./components/RepairJobsTable.js";
import ListHeaderLayout from "../../../components/Layouts/ListHeaderLayout.js";
import RepairJobTableFilters from "./components/RepairJobTableFilters.js";
import ListPageContent from "../../../components/Layouts/ListPageContent.js";

const RepairJobListPage = () => {
    const {data: repairJobs, loading, pagination} = useSelector((state) => state.repairJobList);

    return (
        <div>
            <ListHeaderLayout
                title={'Сервизни заявки'}
                createLink={'/repair-jobs/create/orders'}
                createPermission={'create-repair-jobs'}
            />
            <ListPageContent
                resetStateData={resetStateData}
                fetchData={fetchRepairJobs}
                loading={loading}
                FiltersComponent={RepairJobTableFilters}
                TableComponent={RepairJobsTable}
                pagination={pagination}
                data={repairJobs}
            />
        </div>
    );
};

export default RepairJobListPage;
