import {Nav} from "react-bootstrap";
import React from "react";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import {hasPermission} from "../../../../utils/permissions.js";

const EmployeeNav = (
    {
        id,
        activeKey
    }) => {
    const {user} = useSelector((state) => state.auth);

    return (
        <Nav variant="tabs" activeKey={activeKey} defaultActiveKey="show">
            {id !== 'create' && (
                <Nav.Item>
                    <Nav.Link as={Link} eventKey={'view'} to={`/settings/employees/${id}`}>Преглед</Nav.Link>
                </Nav.Item>
            )}
            {hasPermission(user, 'update-employees') &&
                <Nav.Item>
                    <Nav.Link as={Link} eventKey={'edit'} to={`/settings/employees/${id}/edit`}>Редактирай</Nav.Link>
                </Nav.Item>
            }
        </Nav>
    )
};

export default EmployeeNav;