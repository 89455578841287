import { createSlice } from '@reduxjs/toolkit';
import {resetStateDataReducer} from "../utils/commonReducers.js";

const initialState = {
    damageLevels: [],
    carDetailArticles: [],
    paintArticles: [],
    repairJobChildLineArticles: [],
    repairJobStatuses: []
};

const commonDataSlice = createSlice({
    name: 'commonData',
    initialState: initialState,
    reducers: {
        setDamageLevels: (state, action) => {
            state.damageLevels = action.payload;
        },
        setCarDetailArticles: (state, action) => {
            state.carDetailArticles = action.payload;
        },
        setPaintArticles: (state, action) => {
            state.paintArticles = action.payload;
        },
        setRepairJobChildLineArticles: (state, action) => {
            state.repairJobChildLineArticles = action.payload;
        },
        setRepairJobStatuses: (state, action ) => {
            state.repairJobStatuses = action.payload;
        },
        resetStateData: resetStateDataReducer(initialState),
    },
});

export const {
    setDamageLevels,
    setCarDetailArticles,
    setPaintArticles,
    setRepairJobChildLineArticles,
    setRepairJobStatuses,
    resetStateData
} = commonDataSlice.actions;

export default commonDataSlice.reducer;